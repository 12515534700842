import Api from './api';

const api = new Api('/services')

export const fetchServices = async (ownerId) => {
    const data = await api.get(`/${ownerId}`)
    return data
}

export const getAvailableDatesForService = async (ownerId, serviceId, time) => {
    const data = await api.get('/service/dates', {
        params: {
            ownerId,
            serviceId,
            timezone: new Date().getTimezoneOffset(),
            time: time,
        }
    })
    return data
}

export const getServiceIntervals = async (day, serviceId, ownerId, timezone = new Date().getTimezoneOffset()) => {
    const data = await api.get('/service/interval', {
        params: {
            service: serviceId,
            id: ownerId,
            date: day,
            timezone: timezone,
        }
    })
    return data
}