import React from 'react'

const ProfilerWizardBackButton = ({ onClick, text, type = 'button' }) => {
  return (
    <button
      className={'back-btn'}
      onClick={onClick}
      id={"envite-event-client-click-back"}
      type={type}
    >
      {text ?? 'Back'}
    </button>
  )
}

export default ProfilerWizardBackButton
