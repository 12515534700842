const copyText = (text) => {
	function selectElementText(element) {
		let range;
		if (document.selection) {
			range = document.body.createTextRange();
			range.moveToElementText(element);
			range.select();
		} else if (window.getSelection) {
			range = document.createRange();
			range.selectNode(element);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
		}
	}

	var element = document.createElement("DIV");
	element.textContent = text;
	document.body.appendChild(element);
	selectElementText(element);
	document.execCommand("copy");
	element.remove();
};

export default copyText;
