import React from "react";
import "../../styles/profile/Rating.css";

const NewRating = ({ stars = 0, maxStars = 5, enabled = false, setRating, rating }) =>  {
    return <ul className={`rating ${enabled ? 'enabled' : ''}`} id={"envite-event-client-star-rate"}>
        <li className={`rating-star ${0 < rating ? 'star-filled' : 'star-empty'}`} onClick={() => { enabled && setRating(maxStars - 0) }}/>
        <li className={`rating-star ${1 < rating ? 'star-filled' : 'star-empty'}`} onClick={() => { enabled && setRating(maxStars - 1) }}/>
        <li className={`rating-star ${2 < rating ? 'star-filled' : 'star-empty'}`} onClick={() => { enabled && setRating(maxStars - 2) }}/>
        <li className={`rating-star ${3 < rating ? 'star-filled' : 'star-empty'}`} onClick={() => { enabled && setRating(maxStars - 3) }}/>
        <li className={`rating-star ${4 < rating ? 'star-filled' : 'star-empty'}`} onClick={() => { enabled && setRating(maxStars - 4) }}/>
    </ul>
}

export default NewRating;
