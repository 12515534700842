import Api from './api'

const api = new Api('/mailer')

export const sendEmailToMerchant = async (data) => {
  let res = await api.post('/newmessageformerchant', data)
  return res;
}

export const sendPasswordReset = async (email, tenantId, url) => {
  let res = await api.post(`/resetpasswordlink/${email}?tenantId=${tenantId}&url=${url}`)
  return res;
}