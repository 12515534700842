import React, { useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import TermsAndConditionsPopup from "./TermsAndConditionsPopup";
import PrivacyPolicyPopup from "./PrivacyPolicyPopup";
import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import Visibility from "../../assets/svg-icons/Visibility";
import VisibilityOff from "../../assets/svg-icons/VisibilityOff";
import ArrowLeft from "../../assets/svg-icons/ArrowLeft";
import CloseIcon from "../../assets/svg-icons/CloseIcon";
import * as authService from "services/authService";
import { eventManager } from "utils";
import ProviderSignin from "./ProviderSignin";
import { formatPassword } from "helpers/password";

export default function SignUpPopup({ setVisible, setLoginVisible, ownerId, }) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSignInWithGoogle = async () => {
    try {
      setLoading(true);
      await authService.signInWithGoogle();
      setLoading(false);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail("Sign in failed");
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      setLoading(true);
      await authService.signInWithFacebook();
      setLoading(false);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail(err.message);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      e.preventDefault();
      setLoading(true);
      await authService.signUp(email, password, fullName.trim(), ownerId);
      setLoading(false);
      setVisible(false);
    } catch (err) {
      setErrors(err.response.data ? err.response.data.errors : {});
      setLoading(false);
      const { data } = err.response;
      if (data.statusCode === 400) {
        setErrors({
          email: [data.message]
        })
      }
    }
  };

  const handleEmailChange = (e) => {
    if (errors && errors.email) {
      setErrors(prev => (
        {
          ...prev,
          email: null,
        }
      ))
    }
    setEmail(e.target.value.trim())
  }


  const handleNameChange = (e) => {
    if (errors && errors.fullName) {
      setErrors(prev => (
        {
          ...prev,
          fullName: null,
        }
      ))
    }
    setFullName(e.target.value)
  }

  const handlePasswordChange = (e) => {
    if (errors && errors.password) {
      setErrors(prev => (
        {
          ...prev,
          password: null,
        }
      ))
    }
    setPassword( formatPassword(e.target.value) )
  }


  return (
    <div className={"main-container"}>
      <div className={"main-container-wrapper"}>
        <div
          className={"sign-up__live--wrapper"}
        >
          <div
            className={[`sign-up__live--header ${showRules ? 'sign-up_terms' : ""}`].join(" ")}
            style={showRules ? { marginBottom: "19px" } : {}}
          >
            <EnviteLogo />
            {showRules && (
              <span
                style={{
                  marginLeft: "6px",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 1000,
                  left: 0,
                }}
                onClick={() => {
                  setShowTerms(false);
                  setShowRules(false);
                }}
              >
                <ArrowLeft fillColor={"#6b6f76"} />
              </span>
            )}
            <span className={`sign-up__live--header-text`}>
              {showRules
                ? showTerms
                  ? ""
                  : ""
                : "Sign Up"}
            </span>
            {!showRules && (
              <span
                className="close-icon"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <CloseIcon />
              </span>
            )}
          </div>
          {showRules ? (
            showTerms ? (
              <TermsAndConditionsPopup />
            ) : (
                <PrivacyPolicyPopup />
              )
          ) : (
              <form method="post" action="/" onSubmit={onSubmit}>
                <div className="sign-up__input--wrapper">
                  <TextField
                    className={`outlined-dense sign-up-live`}
                    placeholder="Enter your full name"
                    error={errors && errors.fullName}
                    helperText={errors && errors.fullName && (errors.fullName[1] || errors.fullName[0])}
                    InputProps={{
                      style: {
                        boderColor: "red",
                        fontSize: "12px",
                        color: "#c4c4c4",
                      },
                    }}
                    fullWidth
                    onChange={handleNameChange}
                    defaultValue={fullName}
                    margin="dense"
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    className={`outlined-dense sign-up-live`}
                    placeholder="Enter your email"
                    error={errors && errors.email}
                    helperText={
                      errors && errors.email && (errors.email[1] || errors.email[0])
                    }
                    InputProps={{
                      style: {
                        boderColor: "red",
                        fontSize: "12px",
                        color: "#c4c4c4",
                        marginTop: 12,
                      },
                    }}
                    fullWidth
                    onChange={handleEmailChange}
                    defaultValue={email}
                    margin="dense"
                    variant="outlined"
                    type="text"
                  />
                </div>
                <div
                  className={[
                    "sign-up__input--wrapper",
                    "password-is-" + (showPassword ? "visible" : "invisible"),
                  ].join(" ")}
                  style={{ marginTop: 16 }}
                >
                  <TextField
                    className={`password-input outlined-dense sign-up__password sign-up-live`}
                    placeholder="Enter your password"
                    variant="outlined"
                    error={errors && errors.password}
                    helperText={errors && errors.password && errors.password[0]}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    onChange={handlePasswordChange}
                    value={password}
                    margin="dense"
                    inputProps={{
                      maxLength: 35,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault}
                          >
                            {showPassword ? (
                              <Visibility fillColor={"#dadbdd"} />
                            ) : (
                                <VisibilityOff fillColor={"#dadbdd"} />
                              )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className={"text"} style={{ marginBottom: 15 }}>
                    <span>By creating an account, you agree with the </span>
                    <span
                      className={"span underline"}
                      onClick={() => {
                        setShowTerms(true);
                        setShowRules(true);
                      }}
                      id={"envite-event-client-tems"}
                    >
                      Terms of Service
                  </span>
                    <span> and </span>
                    <span
                      className={"span underline"}
                      onClick={() => setShowRules(true)}
                    >
                      Privacy Policy
                  </span>
                  </p>
                  <Button className={"btn-sign-up"} type="submit">
                    {" "}
                    {loading ? (
                      <CircularProgress
                        classes={{ root: "loader-wrapper" }}
                        size={25}
                        id={"envite-event-client-privacy"}
                      />
                    ) : (
                        "Sign Up"
                      )}
                  </Button>
                  <div className={"sign-up-with"}>
                    <span>or</span>
                  </div>
                  <ProviderSignin handleSignInWithGoogle={handleSignInWithGoogle} handleSignInWithFacebook={handleSignInWithFacebook} type="signUp" />
                  <p className={"text account-text"}>
                    Already have an account?{" "}
                    <button
                      onClick={() => {
                        setVisible(false);
                        setLoginVisible(true);
                      }}
                    >
                      Login
                  </button>
                  </p>
                </div>
              </form>
            )}
        </div>
      </div>
    </div>
  );
}
