import Api from './api'

const api = new Api('/payments')

export const generatePfToken = async () => {
  const { data } = await api.post('/pf-token')
  return data
}

export const getPaymentMethods = async () => {
  const { data } = await api.get('/payment-methods')
  return data
}
