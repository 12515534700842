import React from 'react'

const ProfilerWizardSummary = ({ onClick }) => {

  return (
    <p
      className="widget-summary-another-meeting"
      onClick={onClick}
    >
      Schedule another meeting
    </p>
  )
}

export default ProfilerWizardSummary
