import React from 'react'
import './IframeLoading.scss'


const PublicChatLoading = ({ publicRoom }) => {

    return (
        <div className={`loading_wrapper ${publicRoom ? 'loading_wrapper--lobby' : ''}`}>
            <div className="envite-loader__wrapper">
                <div className="envite-loader_yellow" />
                <div className="envite-loader_blue" />
                <div className="envite-loader_red" />
            </div>
        </div>
    );
}

export default PublicChatLoading
