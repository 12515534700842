import React, { useCallback, useContext, useEffect, useState } from 'react'
import "styles/global/CancelNextMessage.css";
import { directions } from './ProfilerWizard';

import ProfilerWizardContext from './ProfilerWizardContext'
import ProfilerWizardBackButton from './ProfilerWizardController/ProfilerWizardBackButton'
import ProfilerWizardNextButton from './ProfilerWizardController/ProfilerWizardNextButton'

const ProfilerWizardStep = ({
  renderButton,
  render,
  nextDisabled,
  form,
  nextText,
  beforeNext,
  beforeBack,
  skip,
  hideButton,
  backButtonText,
  allowNext,
  availableServiceFunc,
  error,
  setResetSteps,
  setVisible,
  firstStep,
}) => {
  const [isBlocked, setIsBlocked] = useState(false)
  const [nextLoading, setNextLoading] = useState(false)
  const skippedRef = React.useRef()
  const {
    goNext,
    goBack,
    handleChangeState,
    wizardState,
    goToStart,
    handleResetState,
    goTo,
    direction,
    activeIndex,
    goToEnd,
  } = useContext(ProfilerWizardContext);

  const handleGoNext = async () => {
    if (beforeNext) {
      try {
        setNextLoading(true)
        await beforeNext({ wizardState, goToEnd, form, activeIndex })
        setNextLoading(false)
      } catch (error) {
        setNextLoading(false)
        throw error
      }
    }
    goNext()
    //getServiceIntervals && getServiceIntervals(wizardState.selectedDate, wizardState.selectedGig)
    availableServiceFunc && availableServiceFunc(wizardState.selectedDate)
    //getAvailableDatesForService && getAvailableDatesForService(wizardState.selectedGig)
  }

  const closeModal = useCallback(() => {
    setVisible(false);
    setResetSteps(true);
  }, [setVisible, setResetSteps])

  const handleGoBack = async () => {
    if (firstStep) {
      closeModal()
      return
    }
    if (beforeBack) await beforeBack()
    goBack()
    // availableServiceFunc && availableServiceFunc(wizardState.selectedDate)
  }

  const handleReset = () => {
    goToStart()
    handleResetState()
  }

  React.useEffect(() => {
    if (
      skip &&
      skip({ wizardState })
    ) {
      if (skippedRef.current === activeIndex) return
      skippedRef.current = activeIndex

      if (direction === directions.back) {
        goBack()
        skippedRef.current = null
      } else {
        goNext()
        skippedRef.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  useEffect(() => {
    if (allowNext) {
      const isAllowed = allowNext({ wizardState })
      setIsBlocked(!isAllowed)
    }
  }, [wizardState, allowNext])


  return (
    <>
      {render({ handleGoNext, handleGoBack, handleChangeState, wizardState, handleReset, goTo, goNext, nextLoading })}
      {error &&
        <p className='profile-wizard-next-error'>
          {error.message}
        </p>
      }
      {!renderButton && (
        <div className='cancel-next-message' style={{justifyContent: hideButton ? "flex-end" : ""}}>
          {!hideButton && <ProfilerWizardBackButton
              onClick={handleGoBack}
              text={backButtonText}
          />}
          <ProfilerWizardNextButton
            onClick={handleGoNext}
            loading={nextLoading}
            disabled={(nextDisabled || isBlocked)}
            form={form}
            text={nextText ?? 'Next'}
          />
        </div>
      )}
      {renderButton && renderButton({ handleGoNext, handleGoBack, handleReset })}
    </>
  )
}

export default ProfilerWizardStep
