import axios from 'axios';
import EnviteStorage from '../services/storage'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use((config) => {
    let token = EnviteStorage.get('jwtToken') && EnviteStorage.get('jwtToken');
    if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`
    }
    instance.defaults.headers.common['Accept'] = 'application/json';
    return config
}, (error) => Promise.reject(error))

export default instance;
