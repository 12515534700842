import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from "../../../utils/axios";
import FileDownload from 'js-file-download'
import RoundedLeftArrowSVG from "../../../assets/svg-icons/RoundedLeftArrowSVG";
import RoundedRightArrowSVG from "../../../assets/svg-icons/RoundedRightArrowSVG";
import './style.css';
import VideoPreviewSlideshow from './VideoPreviewSlideshow';
import GalleryDownloadIconSVG from "../../../assets/svg-icons/GalleryDownloadIconSVG";

const Slideshow = ({ data, activeImageIndex, closeSlideshow }) => {
    const [active, setActive] = useState(activeImageIndex);
    const [activeFileIndex, setActiveFileIndex] = useState(data.indexOf(data[active]));
    const slideshow = useRef();

    useEffect(() => {
        setActiveFileIndex(data.indexOf(data[active]))
        const eventHandler = (event) => {
            event.keyCode === 27 && closeSlideshow();
            event.keyCode === 39 && active < (data.length - 1) && goNext();
            event.keyCode === 37 && active > 0 && goPrevious();
        };

        document.addEventListener("keydown", eventHandler, false);

        return () => {
            document.removeEventListener("keydown", eventHandler, false);
        };
    }, [active, closeSlideshow, data.length]);

    const activeMedia = () => {
        let activeFile = data[active];

        switch (activeFile.type.split("/")[0]) {
            case 'image': return <img src={activeFile.url} alt='gallery' />
            case 'video': return <video controls src={`${activeFile.url}#t=0.1`} />
            case 'default': return <div></div>
            default:
                return;
        }
    }
    const downloadSingleFile = useCallback((publicUrl, name) => {
        axios.get(publicUrl, {
            responseType: 'blob',
        }).then(res => {
            FileDownload(res.data, name);
        });

    }, [])
    const goNext = () => {
        setActive(active => ++active);
    }

    const goPrevious = () => {
        setActive(active => --active);
    }
    return (
        <div className='chat-slideshow__wrapper swing-in-top-fwd' ref={slideshow}>
            <div className='chat-slideshow__header'>
                <button className='chat-slideshow__close' onClick={closeSlideshow}>Close</button>
                <span className={"chat-slideshow--count"}>{activeFileIndex + 1}/{data?.length}</span>
                <span
                    className={"chat-slideshow--download"}
                    onClick={() => downloadSingleFile(data[activeFileIndex].url, "Gallery image.jpg")}
                >
                    <GalleryDownloadIconSVG/>
                </span>
            </div>

            <div className='chat-slideshow__main'>
                <RoundedLeftArrowSVG disabled={active === 0} onClick={goPrevious} />
                {<div className='chat-slideshow__main--content' >{activeMedia()} </div>}
                <RoundedRightArrowSVG disabled={active === data.length - 1} onClick={goNext} />
            </div>

            <div className='chat-slideshow__footer'>
                <div className='chat-slideshow__footer--previews'>
                    {data.map((file, i) => {
                        switch (file.type.split("/")[0]) {
                            case 'image': return <img alt='slideshow content' src={file.url} key={i} className={i === active ? 'active' : ''} onClick={() => setActive(i)} />
                            case 'video': return <VideoPreviewSlideshow active={active} i={i} key={i} file={file} setActive={setActive} />
                            case 'default': return <></>
                            default: return;
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default Slideshow;
