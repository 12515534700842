import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import Visibility from "../../assets/svg-icons/Visibility";
import VisibilityOff from "../../assets/svg-icons/VisibilityOff";
import CloseIcon from "../../assets/svg-icons/CloseIcon";
import * as authService from "services/authService";
import { eventManager } from "utils";
import ProviderSignin from "./ProviderSignin";
import { formatPassword } from "helpers/password";

export default function LoginPopup({
  setVisible,
  setSignUpVisible,
  setPasswordResetVisible,
  forceLogin,
  invitation = null
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorCode, setErrorCode] = useState("");

  useEffect(() => {
    invitation?.email && setEmail(invitation.email)
  }, [invitation?.email])

  const handleSignInWithGoogle = async () => {
    try {
      setLoading(true);
      await authService.signInWithGoogle();
      setLoading(false);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail("Sign in failed");
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      setLoading(true);
      await authService.signInWithFacebook();
      setLoading(false);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail(err.message);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrors("");
    setErrorCode("");
    try {
      setLoading(true);
      await authService.signIn(email, password);
      setLoading(false);
      setVisible(false);
    } catch (err) {
      console.log("err: ", err);
      setErrors(err.message || "");
      setErrorCode(err.code || "");
      setLoading(false);
    }
  };

  return (
    <div className={"main-container"}>
      <div className={"main-container-wrapper"}>
        <div className={"sign-in__live--wrapper"}>
          <div className="sign-in__live--header">
            <EnviteLogo />
            <span className="sign-in__live--header-text"> Log in </span>
            {!invitation && <span
              className="close-icon"
              onClick={() => {
                setVisible(false);
              }}
              id={"envite-event-client-click-modal-x"}
            >
              {!forceLogin && <CloseIcon />}
            </span>}
          </div>
          <form onSubmit={handleSignIn}>
            <div className="sign-up__input--wrapper">
              <TextField
                disabled={!!invitation?.email}
                className="outlined-dense sign-up-live"
                placeholder="Enter your email"
                InputProps={{
                  style: {
                    boderColor: "red",
                    fontSize: "12px",
                    color: "#c4c4c4",
                    background: invitation ? 'rgba(245, 245, 245, 1)' : '#fff',
                  },
                }}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
                margin="dense"
                variant="outlined"
                type="text"
                value={email}
              />
              {errors && errorCode !== "auth/wrong-password" && (
                <span className="sign-up__error">{errors}</span>
              )}
            </div>
            <div
              className={[
                "sign-up__input--wrapper",
                "password-is-" + (showPassword ? "visible" : "invisible"),
              ].join(" ")}
              style={{ marginTop: 16 }}
            >
              <TextField
                className={`password-input outlined-dense sign-up__password sign-up-live`}
                label="Enter your password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                onChange={(e) => { setPassword( formatPassword(e.target.value)) }}
                defaultValue={password}
                value={password}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault}
                        style={{
                          marginTop: showPassword ? 3 : 0,
                        }}
                      >
                        {showPassword ? (
                          <Visibility fillColor={"#dadbdd"} />
                        ) : (
                          <VisibilityOff fillColor={"#dadbdd"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors && errorCode === "auth/wrong-password" && (
                <span className="sign-up__error">{errors}</span>
              )}
              <p className={"text"} style={{ fontSize: "11px" }}>
                <button
                  className={"span"}
                  type={"button"}
                  onClick={(e) => {
                    e.preventDefault();
                    setPasswordResetVisible && setPasswordResetVisible(true);
                  }}
                >
                  Forgot your password?
                </button>
              </p>
              <Button className={"btn-log-in"} disabled={loading} type={"submit"} id={"envite-event-client-login"} >
                {loading ? <CircularProgress size={25} classes={{ root: 'loader-wrapper' }} /> : "Log in"}
              </Button>
              <div className={"log-in-with"}>
                <span>or</span>
              </div>
              <ProviderSignin handleSignInWithGoogle={handleSignInWithGoogle} handleSignInWithFacebook={handleSignInWithFacebook} type="signIn" />
              {setSignUpVisible && !forceLogin && <p className={"text account-text"}>
                Don't have an account?
                <button
                  id={'envite-event-client-signup'}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(false);
                    setSignUpVisible(true);
                  }}
                >
                  Sign up
                </button>
              </p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
