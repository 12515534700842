import React, { useState, useCallback, useEffect } from 'react';

import OfflineSVG from 'assets/svg-icons/OfflineSVG';
import OnlineSVG from 'assets/svg-icons/OnlineSVG';
import Popover from '@material-ui/core/Popover';

const ServiceItem = ({ service, isSelected, chosenService = false, serviceChangeHandler = () => { } }) => {
    const { id, color, duration, price, name, description, type } = service;

    const [showMore, setShowMore] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isFirstRun = React.useRef(true);

    useEffect(() => {
        let timeout;

        if (isFirstRun.current) { // skipping state change on first render
            isFirstRun.current = false;
            return;
        } else {
            if (expanded) {
                setShowMore(!showMore);
            } else {                // updating state after animation is finished
                timeout = setTimeout(() => {
                    setShowMore(!showMore);
                }, [400])
            };
        };

        return () => clearTimeout(timeout)
    }, [expanded])

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onShowMoreClick = e => {
        e.stopPropagation();
        setExpanded(prevState => !prevState)
    };

    const open = Boolean(anchorEl);
    const printServiceDescription = useCallback((description, name) => {
        let descMaxLength = 75;

        if (name.length > 25) {
            descMaxLength = 45;
        }

        return (
            <>
                {!showMore && description.length > descMaxLength ? description.slice(0, descMaxLength) + '... ' : description + ' '}
                {description.length > descMaxLength && (
                    <span className={`show-more ${showMore ? 'opened' : ''}`} onClick={onShowMoreClick}>{showMore ? 'Less' : 'More'}</span>
                )}
            </>
        )
    }, [showMore]);

    return (
        <div className={`single-service ${isSelected ? 'selected' : ''} ${chosenService ? 'chosen-service' : ''}`} key={id} onClick={() => serviceChangeHandler(id)}>
            <div className={"details"}>
                <div className={"duration"} style={{ backgroundColor: color }}>
                    <span>{duration}</span>
                    <span>Min</span>
                </div>
                <div className={"service__price--icon"}>
                    <div className={"service-icon"} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                        {type === 'offline' ? <OfflineSVG /> : <OnlineSVG />}
                    </div>
                    <div className={"price"}> {parseInt(price) ? '$' + price : 'Free'} </div>
                </div>
                <div>
                    <Popover
                        id="mouse-over-popover"
                        className="service-popover"
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: -50, horizontal: 10, }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <div className="popover-content">
                            {type === 'offline' ? 'Offline' : 'Online'} Meeting
                        </div>
                        <div className="popover-arrow">
                            <div className="popover-content-arrow-border" />
                            <div className="popover-content-arrow" />
                        </div>
                    </Popover>
                </div>
            </div>
            <div className={"info"}>
                <div className={"service-title"}> {name} </div>
                {!chosenService && <div className={`service-description ${expanded ? 'expanded' : ''}`}>
                    {printServiceDescription(description, name)}
                </div>}
            </div>

            {!chosenService && <div className={"service-circle"} >
                <div className={"service-checked"} id="envite-event-client-check-service">
                    <div className={"checkmark"} />
                </div>
            </div>
            }

        </div>
    );
}

export default ServiceItem;
