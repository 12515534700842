import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import CloseIcon from 'assets/svg-icons/CloseIcon';
import KeyIcon from 'assets/svg-icons/KeyIcon';
import PasswordIcon from 'assets/svg-icons/PasswordIcon';
import PasswordShowIcon from 'assets/svg-icons/PasswordShowIcon';


import { app as fbApp } from '../../configs/fbConfig'

import '../../styles/modals/passwordChange.scss'
import { formatPassword } from 'helpers/password';

const PasswordChange = (props) => {
  const [status, setStatus] = useState('valid');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [code] = useState(new URLSearchParams(window.location.search).getAll('oobCode')[0] || '')
  const [tenantId] = useState(new URLSearchParams(window.location.search).getAll('tenantId')[0])
  
  const submitDisabled = useMemo(() => !password || repeatPassword !== password || status === 'invalid', [password, repeatPassword, status]);

  useEffect(() => {
    checkCodeValidity()
  }, []);

  const checkCodeValidity = () => {
    fbApp.auth().tenantId = tenantId;
    fbApp.auth().verifyPasswordResetCode(code)
      .then(function () {
        setStatus('valid');
      })
      .catch(function () {
        setStatus('invalid');
      });
  }

  const onPasswordChangeClick = () => {
    fbApp.auth().confirmPasswordReset(code, password)
      .then(function () { // Success
        setPasswordChanged(true);
        setPasswordError(false)
        props.history.push(`/profile/${props.match.params.serviceName}?login=1`)
      })
      .catch(function (error) { // Invalid code
        setPasswordError(error.message)
      })
  }

  const onCloseClick = () => {
    props.history.push(`/profile/${props.match.params.serviceName}`)
  }

  return <>
    <div className="background-blur"></div>
    <div className='booking-popup-wrapper password-change__wrapper' >
      <div className='booking-popup'>
        <div className='booking-header'>
          <div className='title-wrapper'>
            <div className="key-icon"> <KeyIcon /> </div>
            <h1 className='title'>Change Password</h1>
          </div>
          <span className='close-icon' onClick={onCloseClick}> <CloseIcon /> </span>
        </div>
        <div className='booking-content'>
          {passwordChanged ?
            <p className='password-change__valid'>Password has been changed. You can now log in with new password</p>
            : status !== 'valid' && <p className='password-change__invalid'> Password change session is malformed, expired, or has already been used.</p>
          }
          <label for="password"> New password  </label>
          <TextField
            id="password"
            error={!!passwordError}
            helperText={passwordError && passwordError}
            onChange={(e) => setPassword( formatPassword(e.target.value))}
            variant="outlined"
            value={password}
            required
            disabled={passwordChanged || status === 'invalid'}
            className={"widget-registration-input"}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" onClick={() => setShowPassword(prev => !prev)}>
                  {showPassword ? <PasswordIcon /> : <PasswordShowIcon />}
                </InputAdornment>
              ),
            }}
          />

          <label for="repeat-password"> Confirm password  </label>
          <TextField
            id="repeat-password"
            onChange={(e) => setRepeatPassword( formatPassword(e.target.value))}
            variant="outlined"
            value={repeatPassword}
            required
            disabled={passwordChanged || status === 'invalid'}
            className={"widget-registration-input"}
            type={showRepeatPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" onClick={() => setShowRepeatPassword(prev => !prev)}>
                  {showRepeatPassword ? <PasswordIcon /> : <PasswordShowIcon />}
                </InputAdornment>
              ),
            }}
          />

          <button className='password-change__button' onClick={onPasswordChangeClick} disabled={passwordChanged || submitDisabled}>{passwordChanged ? 'Changed' : 'Confirm'}</button>

        </div>
      </div>
    </div>
  </>
}

export default withRouter(PasswordChange)