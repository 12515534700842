import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import EnviteStorage from '../../../services/storage'
import { mailerService } from "services";

import "../widget.css";
import LeftArrow from "assets/svg-icons/LeftArrow";

const WidgetForgotPassword = ({ toggleForgotPass }) => {
  const [passSent, setPassSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const tenantId = EnviteStorage.get("tenantId");
    const continueUrl = `${window.location.origin}${window.location.pathname}`;

    const res = await mailerService.sendPasswordReset(
      email,
      tenantId,
      continueUrl
    );

    if (res.data) {
      setLoading(false);

      if (res.data.errorInfo.code === "auth/invalid-email") {
        setErrors({ email: "Please enter valid email" });
      } else if (res.data.errorInfo.message.includes("EMAIL_NOT_FOUND")) {
        setErrors({ email: "Email not found" });
      } else if (
        res.data.errorInfo.message
          .toString()
          .includes("RESET_PASSWORD_EXCEED_LIMIT")
      ) {
        setErrors({ email: "Limit exceeded" });
      }
    } else {
      setLoading(false);
      setPassSent(true);
      setErrors({});
    }
  };

  return (
    <form onSubmit={handleSubmit} id='booking-forgot-password'>
      <div className="widget-signup-inner-bottom">
        <div className="widget-registration-texfield-container forgot-password__container">
          <div className="widget-forgot-headline" onClick={toggleForgotPass}>
            {" "}
            <LeftArrow fillColor="rgba(0, 0, 0, 0.56)" /> Forgot your password?
          </div>
          <div className="widget-forget-subtitle">
            {!passSent
              ? "Don’t worry! Just fill in your email and we’ll send you a link to reset your password."
              : "Your Password reset link was sent to your mail address"}
          </div>
          <TextField
            id="email"
            placeholder="Enter your email"
            error={errors.email && true}
            helperText={errors.email && errors.email}
            defaultValue=""
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            className={"widget-registration-input"}
          />
        </div>
      </div>
      <div className="cancel-next-message forgot-pass-bck-nxt-parent">
        <div className="forgot-pass-bck-nxt cancel-next-message">
          <button className="back-btn" type="button" onClick={toggleForgotPass}>
            Back
          </button>
          <button className="next-btn" type="submit">
            {!loading && (passSent ? "Resend" : "Reset password")}
            {loading && <CircularProgress />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default WidgetForgotPassword;
