import React from "react";

export default function EnviteLogoMobile() {
    return (
        <svg width="110" height="31" viewBox="0 0 110 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6993 14.7443C27.4885 14.7443 28.9125 15.3285 29.9715 16.497C31.0486 17.6472 31.5872 19.2355 31.5872 21.2621V30.1898H26.9316V21.892C26.9316 20.8696 26.6669 20.0754 26.1375 19.5094C25.608 18.9434 24.896 18.6604 24.0014 18.6604C23.1068 18.6604 22.3948 18.9434 21.8653 19.5094C21.3359 20.0754 21.0711 20.8696 21.0711 21.892V30.1898H16.3882V14.9086H21.0711V16.9352C21.5458 16.2596 22.1848 15.7302 22.9881 15.3468C23.7914 14.9451 24.6952 14.7443 25.6993 14.7443Z" fill="#190E2F"/>
            <path d="M41.4413 25.8081L44.755 14.9086H49.7392L44.3168 30.1898H38.5384L33.1161 14.9086H38.1277L41.4413 25.8081Z" fill="#190E2F"/>
            <path d="M53.7916 13.3202C52.97 13.3202 52.2945 13.0829 51.7651 12.6082C51.2539 12.1153 50.9983 11.5128 50.9983 10.8008C50.9983 10.0705 51.2539 9.468 51.7651 8.99332C52.2945 8.50038 52.97 8.25391 53.7916 8.25391C54.5949 8.25391 55.2522 8.50038 55.7634 8.99332C56.2928 9.468 56.5575 10.0705 56.5575 10.8008C56.5575 11.5128 56.2928 12.1153 55.7634 12.6082C55.2522 13.0829 54.5949 13.3202 53.7916 13.3202ZM56.1194 14.9086V30.1898H51.4364V14.9086H56.1194Z" fill="#190E2F"/>
            <path d="M67.7561 26.2189V30.1898H65.3736C63.6757 30.1898 62.352 29.779 61.4027 28.9574C60.4533 28.1176 59.9786 26.7575 59.9786 24.877V18.7974H58.1164V14.9086H59.9786V11.1842H64.6616V14.9086H67.7287V18.7974H64.6616V24.9318C64.6616 25.3882 64.7711 25.7168 64.9902 25.9176C65.2093 26.1185 65.5744 26.2189 66.0856 26.2189H67.7561Z" fill="#190E2F"/>
            <path d="M84.4956 22.3027C84.4956 22.7409 84.4682 23.1973 84.4134 23.672H73.8152C73.8882 24.6214 74.1895 25.3517 74.7189 25.8629C75.2666 26.3558 75.933 26.6023 76.7181 26.6023C77.8865 26.6023 78.699 26.1093 79.1554 25.1235H84.1396C83.884 26.1276 83.4184 27.0313 82.7429 27.8346C82.0857 28.6379 81.255 29.2678 80.2508 29.7242C79.2467 30.1807 78.1239 30.4089 76.8824 30.4089C75.3853 30.4089 74.0525 30.0894 72.8841 29.4504C71.7156 28.8114 70.8028 27.8985 70.1455 26.7118C69.4883 25.5251 69.1596 24.1376 69.1596 22.5492C69.1596 20.9608 69.4791 19.5733 70.1181 18.3866C70.7754 17.1999 71.6882 16.287 72.8567 15.648C74.0251 15.009 75.367 14.6895 76.8824 14.6895C78.3612 14.6895 79.6757 14.9999 80.8259 15.6206C81.9761 16.2414 82.8707 17.1269 83.5097 18.277C84.167 19.4272 84.4956 20.7691 84.4956 22.3027ZM79.7031 21.0704C79.7031 20.2671 79.4292 19.6281 78.8815 19.1534C78.3338 18.6787 77.6492 18.4414 76.8276 18.4414C76.0426 18.4414 75.3762 18.6696 74.8285 19.126C74.299 19.5824 73.9704 20.2306 73.8426 21.0704H79.7031Z" fill="#190E2F"/>
            <rect y="11.0195" width="13.3505" height="4.45016" fill="#F5B118"/>
            <rect y="25.7383" width="13.3505" height="4.45016" fill="#FF707A"/>
            <rect y="18.207" width="10.2696" height="4.45017" fill="#00AEDC"/>
        </svg>
    );
}
