import { Box } from '@material-ui/core';
import defaultServiceImage from 'assets/images/logo.png';
import classNames from 'classnames';
import 'styles/components/_service-card.scss'
import { isEmpty } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import ServiceCardContent from './ServiceCardContent';

function ServiceCard({
    service,
    isSelected,
    onClick,
    isNew = false
}) {
    return (
        <Box
            display={'flex'}
            className={
                classNames(
                    "service-card-width-image",
                    { 'is-new': isNew },
                    { 'selected': isSelected }
                )
            }
            onClick={onClick}
        >
            {isNew && (
                <div className={classNames('badge-new', { 'is-mobile': isMobile })}>
                    New!
                </div>
            )}
            <Box display={'flex'}>
                <img src={!isEmpty(service.cover) ? service.cover : defaultServiceImage} width='89' height='180' />
            </Box>
            <ServiceCardContent service={service} />
        </Box>
    )
}

export default ServiceCard