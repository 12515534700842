import * as React from "react";

function DefaultProfileSVG(props) {
	return (
		<svg id="default-profile" xmlns="http://www.w3.org/2000/svg" width={116} height={116} fill="none" viewBox="0 0 116 116" {...props}>
			<circle cx={57.991} cy={57.624} r={57.6} fill="url(#prefix__paint0_radial)" />
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M58.497 61.587c9.21 0 16.746-7.552 16.746-16.782s-7.536-16.78-16.746-16.78c-9.211 0-16.747 7.55-16.747 16.78s7.536 16.782 16.747 16.782zM90.323 84.67c-.438-1.916-.965-3.75-1.579-5.416a44.669 44.669 0 00-2.807-6.166c-5.176-9.332-14.212-12.915-22.546-8.832-3.246 1.583-6.755 1.583-9.913 0-8.334-4-17.458-.5-22.634 8.749a44.551 44.551 0 00-2.807 6.166 51.192 51.192 0 00-1.58 5.5c-.175.583 0 1.25.44 1.75.438.5 1.052.75 1.666.75h59.655c.702 0 1.316-.25 1.667-.75.438-.5.614-1.167.438-1.75z"
				clipRule="evenodd"
			/>
			<defs>
				<radialGradient
					id="prefix__paint0_radial"
					cx={0}
					cy={0}
					r={1}
					gradientTransform="rotate(90 .183 57.808) scale(156.803)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D8E0E5" stopOpacity={0.84} />
					<stop offset={1} stopColor="#D8E0E5" />
				</radialGradient>
			</defs>
		</svg>
	);
}

export default DefaultProfileSVG;
