class IframeCommuncationService {
  constructor() {
    const seed = (Date.now()).toString()
    const token = btoa(seed)
    this.token = token
  }

  init(iframeId) {
    if (!iframeId) {
      if (!window.parent) {
        throw new Error('this website does not have parent document')
      }

      this.instance = window.parent
    } else {
      const iframe = document.getElementById(iframeId)
      if (!iframe) {
        throw new Error('Iframe not found')
      }

      this.instance = iframe.contentWindow
    }
  }
  
  sendMessage({ type, data }) {
    return this.instance
      .postMessage({ type, msg: data, token: this.token }, '*')
  }

  onMessage(cb) {
    return this.instance.addEventListener('message', (event) => {
      const { data: { data, type, token } } = event

      if(token !== this.token) return

      cb({ data, type })
    })
  }

}

export default IframeCommuncationService
