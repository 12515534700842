export const USER_BILLING = [
    [
        {
            step: 1,
            name: "service info",
            status: 'active'
        },
        {
            step: 'last',
            name: "billing",
            status: 'upcoming'
        }
    ],
    [
        {
            step: 1,
            name: "service info",
            status: 'done'
        },
        {
            step: 'last',
            name: "billing",
            status: 'active'
        }
    ]
]

export const SIGNUP_NO_BILLING = [
    [
        {
            step: 1,
            name: "Account set up",
            status: 'active'
        },
        {
            step: 'last',
            name: "service info",
            status: 'upcoming'
        }
    ],
    [
        {
            step: 1,
            name: "Account set up",
            status: 'done'
        },
        {
            step: 'last',
            name: "service info",
            status: 'active'
        }
    ]
]

export const SIGNUP_BILLING = [
    [
        {
            step: 1,
            name: "Account set up",
            status: 'active'
        },
        {
            step: 2,
            name: "service info",
            status: 'upcoming'
        },
        {
            step: 'last',
            name: "Billing",
            status: 'upcoming'
        }
    ],
    [
        {
            step: 1,
            name: "Account set up",
            status: 'done'
        },
        {
            step: 2,
            name: "service info",
            status: 'active'
        },
        {
            step: 'last',
            name: "Billing",
            status: 'upcoming'
        }
    ],
    [
        {
            step: 1,
            name: "Account set up",
            status: 'done'
        },
        {
            step: 2,
            name: "service info",
            status: 'done'
        },
        {
            step: 'last',
            name: "Billing",
            status: 'active'
        }
    ],
]
