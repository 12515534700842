import React from "react";
import "../../styles/profile/Review.css";
import Rating from "./Rating";

const Review = ({ review }) => {
	const topPart = (
		<div className="top-part">
					<span className="author">
						{review.name}
					</span>
			<Rating stars={review.star}/>
		</div>
	);
	return (
		<div className={'review'}>
			<div className={"top-part-wrapper"}>
				<div className="image-container">
					<img src={review.image || 'https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-image-default-avatar-photo-placeholder-profile-image-eps-file-easy-to-edit-124557892.jpg'} alt={review.name} />
				</div>
				{topPart}
			</div>
			<div className="content">
				{topPart}
				<p>{review.review}</p>
			</div>
		</div>
	);
}

export default Review;
