import Storage from './Storage'

const determinePrefix = () => {
  const url = window.location.href.split('/')

  return `${url[4]}__`;
}

const EnviteStorage = new Storage({
  prefix: determinePrefix()
})

export default EnviteStorage