import React, { useState } from "react";
// import { connect } from 'react-redux';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import validator from "validator";
import EnviteStorage from '../../services/storage'
// import { hideMessage } from 'actions/Auth';
// import { Owner } from '../model/owner';

import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import MailIcon from "../../assets/svg-icons/emailIcon";
import CloseIcon from "../../assets/svg-icons/CloseIcon";
import { mailerService } from "services";

const placeholderStyle = {
  fontSize: "13px",
  color: "#c4c4c4",
  height: "46px",
};

export default function PasswordResetPopup({
  //visible,
  setVisible,
  setLoginVisible,
}) {
  const [email, setEmail] = useState("");
  const [sendingMailLoader, setSendingMailLoader] = useState(false);
  const [passwordIsSent, setPasswordIsSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState(null);

  const isValidMail = (value) => {
    const validate = validator.isEmail(email);
    if (!validate) {
      setEmailError("Email is not correct");
    } else {
      setEmailError(null);
    }
  };

  const ResetPassSend = (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      // if email isn't valid
      setEmailError("Email is not correct");
    } else {
      // if email is valid
      setEmailError(null);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setSendingMailLoader(true);
    const tenantId = EnviteStorage.get("tenantId");
    const continueUrl = `${window.location.origin}${window.location.pathname}`;
    const res = await mailerService.sendPasswordReset(
      email,
      tenantId,
      continueUrl
    );
    if (res.data) {
      setSendingMailLoader(false);
      if (res.data.errorInfo.code === "auth/invalid-email") {
        setErrors({ email: "Please enter valid email" });
      } else if (res.data.errorInfo.message.includes("EMAIL_NOT_FOUND")) {
        setErrors({ email: "Email not found" });
      } else if (
        res.data.errorInfo.message
          .toString()
          .includes("RESET_PASSWORD_EXCEED_LIMIT")
      ) {
        setErrors({ email: "Limit exceeded" });
      }
    } else {
      setSendingMailLoader(false);
      setPasswordIsSent(true);
      //   setPassSent(true)
      setErrors({});
    }
  };

  return (
    <div className={"main-container"}>
      <div className={"main-container-wrapper"}>
        <div
          className={["sign-in__live--wrapper", "reset-password-wrapper"].join(
            " "
          )}
        >
          <div className={["sign-in__live--header"].join(" ")}>
            <EnviteLogo />
            <span className={"sign-in__live--header-text"}>Reset Password</span>
            <button
              className={"close-icon"}
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
                setLoginVisible && setLoginVisible(true);
              }}
            >
              <CloseIcon fillColor={"#9b9ea3"} />
            </button>
          </div>
          {!passwordIsSent && <p className={"text"}>
            Enter your email address below and we'll send  you a link to reset your password
          </p>}
          <form onSubmit={ResetPassSend}>
            <div className="sign-up__input--wrapper">
              <TextField
                onBlur={(e) => isValidMail(e.target.value)}
                classes={{ root: emailError && "input-error" }}
                className={`outlined-dense sign-up-live ${passwordIsSent ? 'sign-up-live__passsend' : ""}`}
                placeholder="E-mail"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <MailIcon class="text-field-icon-left signup-live__mail" />
                    </InputAdornment>
                  ),
                  style: {
                    ...placeholderStyle,
                    boderColor: "red",
                    paddingLeft: '20px'
                  },
                }}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
                defaultValue={email}
                margin="dense"
                variant="outlined"
                type="text"
              />
              {emailError && (
                <span
                  className="sign-up__error"
                  style={{
                    position: "relative",
                    top: "-8px",
                  }}
                >
                  {emailError}.{" "}
                </span>
              )}
              {errors && (
                <span
                  className="sign-up__error"
                  style={{ position: "relative", top: "-8px" }}
                >
                  {errors && errors.email}
                </span>
              )}
            </div>
            {passwordIsSent && <p className={"reset-pass-send"}>Your Password reset link was sent to your mail address</p>}
            <Button
              className={"btn-log-in btn-reset"}
              disabled={sendingMailLoader}
              type="submit"
            >
              {sendingMailLoader && (
                <div className="loader-view">
                  <CircularProgress size={25} />
                </div>
              )}
              {!passwordIsSent ? "Reset Password" : "Resend"}
            </Button>

          </form>
        </div>
      </div>
    </div>
  );
}
