
import Profile from './components/profile/Profile';
import ProfilePlaceholder from './components/profile/ProfilePlaceholder';
import MainPage from './components/mainPage/MainPage';
import PasswordChange from './components/modals/PasswordChange';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Notification from 'components/global/Notification';

import { AuthProvider } from "./context/AuthProvider";
import ConfirmMeeting from 'components/confirmMeeting/ConfirmMeeting';
import { useEffect } from 'react';
import { createAnalyticsEvent } from 'services/event';
import mixpanelApp from 'services/mixpanel';
import PrivateRoute from 'components/global/PrivateRoute';


function App() {

  useEffect(() => {
    const handleMessage = e => {
      const data = e.data
      if (data.action === 'create_analytics_event') {
        mixpanelApp.track(data.eventName, data.eventPayload);
        createAnalyticsEvent(data.eventName, data.eventPayload)
        return
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path='/' exact component={MainPage} />
          <Route path='/profile/:serviceName' exact component={Profile} />
          <Route path='/profile/:serviceName/meet/public' component={ProfilePlaceholder} />
          <PrivateRoute path='/profile/:serviceName/meet/:meetingId?' component={ProfilePlaceholder} />
          <Route path='/profile/:serviceName/confirm-meeting' component={ConfirmMeeting} />
          <Route path='/profile/:serviceName/passwordchange' >
            <div className="password-change-popup">
              <Profile />
              <PasswordChange />
            </div>
          </Route>
        </Switch>
      </Router>
      <Notification />
    </AuthProvider>
  );
}

export default App;
