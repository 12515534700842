import Api from './api'

const api = new Api('/bookings')

export const createBooking = async ({
  serviceId,
  serviceName,
  scheduledAt,
  duration,
  userId,
  ownerId,
  created_at,
  updated_at,
  status,
  paymentMethod,
  timezone = new Date().getTimezoneOffset()
}) => {
  const { data } = await api.post('/', {
    serviceId,
    serviceName,
    scheduledAt,
    duration,
    userId,
    ownerId,
    created_at,
    updated_at,
    status,
    paymentInfo: paymentMethod,
    timezone: timezone
  })
  return data
}

export const getBookingTimes = async (serviceId, ownerId, day) => {
  const data = await api.get(`/booking`, {
    params: {
      service: serviceId,
      id: ownerId,
      date: day,
      timezone: new Date().getTimezoneOffset(),
    }
  })

  return data
}

export const getBookingById = async (bookingId, ownerId) => {
  const { data } = await api.get(`/booking/${bookingId}`, {
    params: {
      ownerId
    }
  })

  return data
}

export const confirmFreeBooking = async (bookingId, ownerId) => {
  const { data } = await api.post(`/booking/confirm/free`, {
    bookingId,
    ownerId
  })

  return data
}

export const confirmPaidBooking = async (bookingId, ownerId, paymentInfo) => {
  const { data } = await api.post(`/booking/confirm/paid`, {
    bookingId,
    ownerId,
    paymentInfo
  })

  return data
}

