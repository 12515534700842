import React from "react";
import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import "../../styles/ConfirmMeeting/successModal.scss";
import SuccessIcon from "assets/svg-icons/SuccessIcon";

export default function SuccessModal({ pageUrl }) {
  return (
    <div className={"invitation-success__wrapper"}>
      <div className="invitation-success__Header">
        <EnviteLogo />
      </div>
      <div className="success-modal--content">
        <SuccessIcon />
        <h1 className="success-modal--header">Congratulations!</h1>
        <p className="success-modal--desc">You’ve submitted your account!</p>
        <a href={`/profile/${pageUrl}`}>Go to Service Provider Page</a>
      </div>
    </div>
  );
}
