import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './configs/fbConfig'
import './services/mixpanel'
import 'services/sentry'

ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById('root'),
)
