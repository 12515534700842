import React from 'react';

const GalleryDownloadIconSVG = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12C19.3682 11.9996 19.2376 12.0253 19.1157 12.0756C18.9939 12.1259 18.8832 12.1997 18.79 12.293C18.6062 12.4827 18.5023 12.7358 18.5 13V17C18.4977 17.2641 18.3938 17.5173 18.21 17.707C18.1168 17.8002 18.0061 17.8741 17.8843 17.9244C17.7624 17.9746 17.6318 18.0004 17.5 18H3.5C3.36818 18.0004 3.23757 17.9746 3.11572 17.9244C2.99387 17.8741 2.88323 17.8002 2.79004 17.707C2.60621 17.5173 2.50233 17.2641 2.5 17V13C2.49767 12.7358 2.39379 12.4827 2.20996 12.293C2.02128 12.1054 1.76607 12.0001 1.5 12.0001C1.23393 12.0001 0.978718 12.1054 0.790039 12.293C0.606205 12.4827 0.502333 12.7358 0.5 13V17C0.5 17.7956 0.816053 18.5587 1.37866 19.1213C1.94127 19.6839 2.70435 20 3.5 20H17.5C18.2956 20 19.0587 19.6839 19.6213 19.1213C20.1839 18.5587 20.5 17.7956 20.5 17V13C20.4977 12.7358 20.3938 12.4827 20.21 12.293C20.1168 12.1997 20.0061 12.1259 19.8843 12.0756C19.7624 12.0253 19.6318 11.9996 19.5 12ZM9.79004 13.71C9.88652 13.7992 9.99826 13.8704 10.12 13.92C10.2397 13.9727 10.3692 13.9999 10.5 13.9999C10.6308 13.9999 10.7603 13.9727 10.88 13.92C11.0017 13.8704 11.1135 13.7992 11.21 13.71L15.21 9.70993C15.3983 9.52162 15.5042 9.26624 15.5042 8.99993C15.5042 8.73363 15.3983 8.47824 15.21 8.28994C15.0217 8.10163 14.7663 7.99584 14.5 7.99584C14.2337 7.99584 13.9783 8.10163 13.79 8.28994L11.5 10.5899V0.999884C11.4977 0.735728 11.3938 0.482586 11.21 0.292879C11.0213 0.105284 10.7661 0 10.5 0C10.2339 0 9.97872 0.105284 9.79004 0.292879C9.60621 0.482586 9.50233 0.735728 9.5 0.999884V10.5899L7.20996 8.28994C7.117 8.19428 7.00446 8.11989 6.88 8.07192C6.7596 8.02175 6.63044 7.99593 6.5 7.99593C6.36956 7.99593 6.2404 8.02175 6.12 8.07192C5.99554 8.11989 5.883 8.19428 5.79004 8.28994C5.69688 8.38382 5.62216 8.49441 5.56995 8.61593C5.47668 8.86343 5.47668 9.13643 5.56995 9.38394C5.62216 9.50546 5.69688 9.61604 5.79004 9.70993L9.79004 13.71Z" fill="white"/>
        </svg>
    );
};

export default GalleryDownloadIconSVG;
