import React from 'react'

import './widgetCreditCardStyles.css'

import VisaIcon from 'assets/icons/visa.png'
import MasterCardIcon from 'assets/icons/mastercard.png'
import AmericanExpressIcon from 'assets/icons/americanexpress.png'
import OtherCardIcon from 'assets/icons/othercard.png'

const WidgetCreditCard = ({
  cardType,
  cardLastFourDigits,
  active,
  onClick
}) => {

  const cardIcon = React.useMemo(() => {
    if (cardType === 'VISA') return VisaIcon
    if (cardType === 'MASTERCARD') return MasterCardIcon
    if (cardType === 'AMEX') return AmericanExpressIcon
    return OtherCardIcon
  }, [cardType])

  return (
    <div
      onClick={onClick}
      className={
        `widget-credit-card-container
        ${active && 'widget-credit-card-container-active'}
        `
      }
    >
      <img src={cardIcon} className='widget-credit-card-image' alt='' />
      <p className='widget-credit-card-type'>
        {cardType}
      </p>
      <p className='widget-credit-card-last-digits'>
        ({cardLastFourDigits})
      </p>
    </div>
  )
}

export default WidgetCreditCard
