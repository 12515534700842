import React from 'react';

const DropDownSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="3" y1="6" x2="21" y2="6" stroke="#908A9A" strokeWidth="2" strokeLinecap="round"/>
            <line x1="3" y1="18" x2="21" y2="18" stroke="#908A9A" strokeWidth="2" strokeLinecap="round"/>
            <line x1="8" y1="12" x2="21" y2="12" stroke="#908A9A" strokeWidth="2" strokeLinecap="round"/>
        </svg>

    );
};

export default DropDownSVG;
