import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { authService } from "services";
import { fetchUserToken } from "services/authService";
import { app as firebase } from "../configs/fbConfig";
import EnviteStorage from '../services/storage'

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  const [initialLoad, setInitialLoad] = useState(false)

  const handleAuthStateChange = useCallback(async (user) => {
    setInitialLoad(true)
    if (user && user.tenantId.includes('admin')) return;
    if (user && user.tenantId === EnviteStorage.get('tenantId')) {
      setUser(user)

      const token = await user.getIdToken(true)
      EnviteStorage.set('idToken', token)
    } else {
      setUser(null)
      setProfile(null)
      // EnviteStorage.remove('idToken')
    }
  }, [])

  const handleIdTokenChange = useCallback(async (user) => {
    if (user && user.tenantId.includes('admin')) return;
    if (user && user.tenantId === EnviteStorage.get('tenantId')) {
      setUser(user)
      const token = await user.getIdToken()
      EnviteStorage.set('idToken', token)
      await fetchUserToken(user, true);
      if (EnviteStorage.get('accessToken')) {
        const { data: userProfile } = await authService.getProfile()
        setProfile(userProfile)
      }

    } else {
      EnviteStorage.remove('idToken')
    }
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(handleAuthStateChange);
    firebase.auth().onIdTokenChanged(handleIdTokenChange);
  }, [handleAuthStateChange, handleIdTokenChange]);

  useEffect(() => {
    if (user && EnviteStorage.get('accessToken')) {
      authService.updateLoginDate()
        .then(() => { })
        .catch(() => { })
    }
    if (user && EnviteStorage.get('accessToken')) {
      authService.generateCustomToken()
        .then(accessToken => EnviteStorage.set('accessToken', accessToken))
        .catch(() => { })
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ user, profile, setProfile, initialLoad }}>{children}</AuthContext.Provider>
  );
};
