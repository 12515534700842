import React from 'react'

export default function WidgetCopySVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g stroke="#0F3C5C" strokeLinecap="round" strokeLinejoin="round" opacity=".5">
        <rect width="7.5" height="7.5" x="5.5" y="5.5" rx="1.6" />
        <path d="M2.73 8.5h-.576C1.517 8.5 1 7.983 1 7.346V2.154C1 1.517 1.517 1 2.154 1h5.192C7.983 1 8.5 1.517 8.5 2.154v.577" />
      </g>
    </svg>
  )
}
