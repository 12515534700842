import React from 'react';

const LogInDropDownSVG = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "12px"}}>
            <path d="M10.1215 8.36925L12.6522 10.8883L2.10039 10.8883C1.80858 10.8883 1.5288 11.0041 1.32246 11.2104C1.11612 11.4167 1.00011 11.6965 1.00011 11.9883C1.00011 12.28 1.11612 12.5599 1.32246 12.7661C1.5288 12.9724 1.80858 13.0883 2.10039 13.0883L12.6522 13.0883L10.1215 15.6073C10.0162 15.71 9.93379 15.8337 9.87943 15.9703C9.82135 16.102 9.79132 16.2443 9.79132 16.3883C9.79132 16.5322 9.82135 16.6746 9.87943 16.8063C9.93535 16.9421 10.0176 17.0654 10.1215 17.1692C10.2253 17.2731 10.3488 17.3554 10.4846 17.4113C10.6164 17.4694 10.7586 17.4993 10.9026 17.4993C11.0466 17.4993 11.1889 17.4694 11.3206 17.4113C11.4573 17.357 11.5811 17.2745 11.6838 17.1692L16.0849 12.7692C16.1821 12.6623 16.2602 12.5396 16.3159 12.4063C16.4259 12.1385 16.4259 11.8381 16.3159 11.5703C16.2602 11.437 16.1821 11.3142 16.0849 11.2073L11.6838 6.80734C11.5811 6.70211 11.4573 6.61955 11.3206 6.56524C11.0513 6.46263 10.7539 6.46263 10.4846 6.56524C10.3488 6.62114 10.2253 6.7035 10.1215 6.80734C10.0176 6.91118 9.93535 7.03449 9.87943 7.17029C9.77659 7.43947 9.77659 7.73712 9.87943 8.0063C9.93379 8.14291 10.0162 8.26654 10.1215 8.36925Z" fill="#918B9B"/>
            <path d="M14.1978 2.10005C14.1963 2.24491 14.2239 2.38863 14.2786 2.52276C14.3334 2.65688 14.4142 2.7787 14.5166 2.88114C14.6191 2.98358 14.7411 3.06459 14.8752 3.11935C15.0094 3.17411 15.1532 3.20152 15.298 3.20005L19.6992 3.20005C19.8441 3.19858 19.9878 3.22599 20.122 3.28075C20.2561 3.33551 20.3781 3.41652 20.4806 3.51896C20.5831 3.6214 20.6638 3.74322 20.7186 3.87734C20.7734 4.01146 20.8009 4.15518 20.7994 4.30005L20.7994 19.7C20.8009 19.8449 20.7734 19.9886 20.7186 20.1227C20.6638 20.2568 20.583 20.3787 20.4806 20.4811C20.3781 20.5835 20.2561 20.6645 20.122 20.7193C19.9878 20.7741 19.8441 20.8015 19.6992 20.8H15.298C15.0062 20.8 14.7265 20.9158 14.5201 21.1221C14.3138 21.3284 14.1978 21.6083 14.1978 21.9C14.1978 22.1917 14.3138 22.4716 14.5201 22.6779C14.7265 22.8842 15.0062 23 15.298 23H19.6992C20.5746 23 21.4142 22.6523 22.0332 22.0335C22.6523 21.4146 23 20.5752 23 19.7L23 4.30005C23 3.42484 22.6523 2.58545 22.0332 1.96658C21.4142 1.34771 20.5746 1.00005 19.6992 1.00005L15.2981 1.00005C15.1532 0.998582 15.0094 1.026 14.8752 1.08076C14.7411 1.13551 14.6191 1.21652 14.5166 1.31896C14.4142 1.4214 14.3334 1.54322 14.2786 1.67735C14.2239 1.81147 14.1963 1.95519 14.1978 2.10005Z" fill="#918B9B"/>
        </svg>
    );
};

export default LogInDropDownSVG;
