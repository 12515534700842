
import { Box, Typography } from '@material-ui/core'
import ClockSVG from 'assets/svg-icons/ClockSVG'
import NewUserSVG from 'assets/svg-icons/NewUserSVG'
import UserGroupSVG from 'assets/svg-icons/UserGroupSVG'
import React from 'react'

function ServiceCardContent({ service }) {
    return (
        <div className='service-card-content'>
            <Typography component='div' className='service-card-header'>{service.name}</Typography>
            <Typography component='div' className='service-card-description'>{service.description}</Typography>
            <Box display={'flex'} justifyContent={'space-between'} className='service-footer'>
                <Typography component='div' className='service-detail'>
                    {<>
                        $ {service.price !== 0 ? service.price : 'Free'}
                    </>}
                </Typography>
                <div style={{ borderRight: '1px solid #D8D8D8' }} />
                <Typography component='div' className="service-detail" style={{ gap: '3px' }}>
                    <ClockSVG size={14} />
                    {`${service.duration} minutes`}
                </Typography>
                <div style={{ borderRight: '1px solid #D8D8D8' }} />
                <Typography component='div' className="service-detail" style={{ gap: '3px' }}>
                    {service.members === 1 && <>
                        <NewUserSVG size={14} />
                        {'private'}
                    </>}
                    {service.members > 1 && <>
                        <UserGroupSVG size={20} />
                        {`2-${service.members}`}
                    </>}
                </Typography>
            </Box>
        </div>
    )
}

export default ServiceCardContent