import React, { useContext, useEffect, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";

import PhoneInput from 'react-phone-input-2'
import { AuthContext } from "context/AuthProvider";
import { authService } from "services";
import { ipLookUp } from "helpers/locateCountry";

import ProfilerWizardBackButton from "./ProfilerWizard/ProfilerWizardController/ProfilerWizardBackButton";
import ProfilerWizardNextButton from "./ProfilerWizard/ProfilerWizardController/ProfilerWizardNextButton";

import "../../styles/profile/AboutInfoForm.css";
import 'react-phone-input-2/lib/material.css'
import AboutInfoDatePicker from "./AboutInfoDatePicker";


export default function AboutInfoForm({ onInput, fields, handleGoNext, handleGoBack, goTo, currentUser, nextLoading }) {
	const [fieldValues, setFieldValues] = useState({})
	const { profile, setProfile } = useContext(AuthContext)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [userCountryCode, setUserCountryCode] = useState('il');

	const registerFields = useMemo(() => fields.filter(({ name }) => name !== 'email' && name !== 'Full name'), [fields])

	useEffect(() => {
		if (!profile) return

		const fieldValues = registerFields.reduce((acc, { name }) => {
			if (profile[name]) return { ...acc, [name]: profile[name] }
			if (profile.fields && profile.fields[name]) return { ...acc, [name]: profile.fields[name] }
			return acc
		}, { email: profile.email, fullName: profile.fullName })

		setFieldValues(fieldValues)
	}, [profile, registerFields, setFieldValues])

	useEffect(async () => {
		const userCountry = await ipLookUp();
		if (!userCountry) return
    	setUserCountryCode(userCountry.country_code.toLowerCase());
	}, [])

	const onInputChange = e => {
		setFieldValues({
			...fieldValues,
			[e.target.name]: e.target.value
		});
		onInput(fieldValues)
	}

	const phoneNumberChange = number => {
		setFieldValues({
			...fieldValues,
			'Phone Number': number
		});
		
		onInput(fieldValues)
	}

	const handleDateChange = date => {
		setFieldValues({
			...fieldValues,
			'Date of Birth': date
		});
		
		onInput(fieldValues)
	}
	
	const handleSubmit = async (e) => {
		try {
			e.preventDefault()
			setLoading(true)
			const fieldError = {}

			for (const field of registerFields) {
				const fieldName = field.name
				if (fieldName === 'Date of Birth' && field.checked) {
					if (fieldValues[fieldName] == 'Invalid Date' || new Date(fieldValues[fieldName]) > new Date()) {
						fieldError[fieldName] = `${fieldName} is invalid!`
					}
				}

				if (field.checked && !fieldValues[fieldName]) {
					fieldError[fieldName] = `${fieldName} is required!`
				}
			}
			const isError = !!(Object.keys(fieldError).length)
			setErrors(fieldError)

			if (isError) {
				setLoading(false);
				return
			}
			const { data: newProfile } = await authService.updateProfile(
				currentUser.email,
				currentUser.name || currentUser.displayName,
				fieldValues,
				true,
				false
			)
			setProfile(newProfile)
			setLoading(false)
			handleGoNext()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<div className="about-info-form">
			<h3 className="info">Add more info about yourself</h3>
			<form onSubmit={handleSubmit} className='about-info-fields__wrapper'>
				<div className={"inputs-wrapper"}>
					{registerFields.map((field, i) => {
						if (field.type === 'Phone Number') {
							return <PhoneInput
								defaultErrorMessage='Phone Number is required'
								isValid={!errors[field.name]}
								key={i}
								country={userCountryCode}
								value={fieldValues[field.name] || ""}
								onChange={phoneNumberChange}
								name={field.name}
								inputClass='about-info__phone--input'
								containerClass={`about-info__phone--container ${errors[field.name] ? 'about-info__phone--container_invalid' : ''}`}
							/>
						} else if (field.type === 'Date of Birth') {
							return <AboutInfoDatePicker fieldValues={fieldValues} fieldname={field.name} key={i} error={!!errors[field.name]} handleDateChange={handleDateChange}/>
						} else return <TextField
							error={!!errors[field.name]}
							key={i}
							id={field.name}
							value={fieldValues[field.name] || ""}
							size="small"
							name={field.name}
							placeholder={field.name}
							variant="outlined"
							onChange={onInputChange}
							inputProps={{ maxLength: 35 }}
						/>
					})}
				</div>
				<div className='cancel-next-message'>
					<ProfilerWizardBackButton
						onClick={() => goTo(1)}
						text='Back'
					/>
					<ProfilerWizardNextButton
						loading={loading || nextLoading}
						type='submit'
						text='Next'
					/>
				</div>
			</form>
		</div>
	);
}
