import { app as firebase } from '../configs/fbConfig'
import { firebase as defFirebase } from '../configs/fbConfig'
import Api from './api'
import EnviteStorage from '../services/storage'

import widgetCommunicationService from './widgetCommunicationService'
import mixpanelApp from './mixpanel'

const googleProvider = new defFirebase.auth.GoogleAuthProvider()
  .addScope('email')
  .addScope('profile')
const facebookProvider = new defFirebase.auth.FacebookAuthProvider()
  .addScope('email')
  .addScope('public_profile')

const api = new Api('/auth')

export const fetchUserToken = async (user, t = false) => {
  if (!t) {
    EnviteStorage.remove('idToken')
    EnviteStorage.remove('accessToken')
  }

  let targetObj = t ? user : user.user;

  const token = await targetObj.getIdToken()
  EnviteStorage.set('idToken', token)

  const accessToken = await generateCustomToken()
  EnviteStorage.set('accessToken', accessToken)

  await targetObj.getIdToken(true)
}

export const generateCustomToken = async () => {
  const { data } = await api.post('/widget/token')
  const { accessToken } = data

  try {
    widgetCommunicationService.init('envite-iframe')
    widgetCommunicationService.sendMessage({ type: 'access-token', data: accessToken })
  } catch (error) {
  }

  return accessToken
}

export const signIn = async (email, password) => {
  const user = await firebase.auth().signInWithEmailAndPassword(email, password)

  await fetchUserToken(user)

  return user
}

export const resetPassword = (email) => {
  return firebase.auth().sendPasswordResetEmail(email)
}

export const signInWithGoogle = async () => {
  const tenantId = EnviteStorage.get('tenantId')
  const user = await firebase.auth().signInWithPopup(googleProvider)

  await setCustomClaims(user.user, tenantId)

  await fetchUserToken(user)

  return user
}

export const signInWithFacebook = async () => {
  const tenantId = EnviteStorage.get('tenantId')
  try {
    const user = await firebase.auth().signInWithPopup(facebookProvider)

    if (!user.user?.email) {
      await firebase.auth().signOut();
      throw new Error('EMPTY_EMAIL') 
    }
    await setCustomClaims(user.user, tenantId)
  
    await fetchUserToken(user)
  
    return user
    
  } catch(e) {
    const msg = e.message  === 'EMPTY_EMAIL' ? 
      'Your Facebook account need to have an Email associated with it. Please add a an Email address to your Facebook and try again'
      : 'Sign in failed'
    throw new Error(msg)
  }
  
}

export const signUp = async (email, password, fullName, ownerId,) => {
  const tenantId = EnviteStorage.get('tenantId')
  const data = await api.post('/signup', {
    email,
    password,
    fullName,
    ownerId,
    tenantId,
    timezone: new Date().getTimezoneOffset()
  })

  const { user } = await firebase.auth().signInWithEmailAndPassword(email, password)
  mixpanelApp.track('successful_registrations', { id: user.uid, email: user.email, name: user.displayName })
  await setCustomClaims(user, tenantId)
  await fetchUserToken({ user })

  return data
}

export const getProfile = async () => {
  const data = await api.get('/user')
  return data
}

export const setCustomClaims = async (user) => {
  const tenantId = EnviteStorage.get('tenantId')
  const idTokenResult = await user.getIdTokenResult()

  if (idTokenResult?.claims?.roles?.length) return

  const idToken = await user.getIdToken()

  const data = await api.post('/widget/set-custom-claims', {
    ownerId: EnviteStorage.get('ownerId'),
    idToken,
    tenantId,
    timezone: new Date().getTimezoneOffset()
  })

  await user.getIdToken(true)

  return data
}

export const updateProfile = async (email, fullName, fields, regFieldsProceeded, hasBooking) => {
  const data = await api.put('/user', {
    email,
    fullName,
    fields: {
      email,
      fullName,
      ...fields,
    },
    regFieldsProceeded,
    hasBooking
  })
  return data
}

export const updateLoginDate = () => {
  return api.post('/login-date')
}
