import React from 'react';

const SentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r="40" fill="#00AEDC" opacity=".1"/>
        <circle cx="40" cy="40" r="24" fill="#00AEDC"/>
        <path fill="#fff" d="M38.85 41.789l-9.759 9.758c-.401.414-1.039-.227-.627-.627l9.758-9.759c.084-.081.196-.126.313-.126.116.001.228.048.31.13.083.083.13.195.13.311.001.117-.044.23-.126.313zm1.454-1.455l2.645 8.474c.086.262.251.49.472.656.221.164.488.257.764.264.275.007.546-.07.776-.224.23-.152.406-.372.506-.63l7.46-20.079c.063-.167.09-.345.082-.523-.008-.178-.052-.352-.13-.513L40.304 40.334zm11.948-13.202c-.16-.078-.335-.122-.513-.13-.178-.009-.356.02-.523.082l-20.08 7.46c-.257.099-.478.275-.632.505-.153.23-.231.501-.224.777.007.276.1.543.265.765.166.22.396.385.658.47l8.474 2.646 12.575-12.575zM40.18 46.224l-6.653 6.654c-.402.413-1.04-.227-.627-.628l6.653-6.653c.083-.081.196-.127.312-.126.117 0 .229.048.311.13.083.082.13.194.13.31 0 .117-.044.23-.126.313zm-5.766-5.766l-6.653 6.653c-.402.414-1.04-.226-.628-.627l6.654-6.653c.083-.082.195-.127.312-.126.117 0 .228.047.31.13.083.082.13.194.13.31.002.117-.044.23-.125.313z"/>
    </svg>
)

export default SentIcon;
