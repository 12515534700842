import React from 'react'

export default function VideoPreviewSlideshow({ file, active, setActive, i }) {
  return (
    <div className={`slideshow-video-wrapper ${i === active ? 'active' : ''}`}>
      <video src={`${file.url}#t=0.1`} className={i === active ? 'active' : ''} onClick={() => setActive(i)} />
      <button className="play-button" />
    </div>
  )
}
