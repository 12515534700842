import React, { useEffect, useMemo, useState } from "react";
import { app as firebase } from "../../configs/fbConfig";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import Visibility from "../../assets/svg-icons/Visibility";
import VisibilityOff from "../../assets/svg-icons/VisibilityOff";
import * as authService from "services/authService";
import "../../styles/ConfirmMeeting/singUpInvite.scss";
import PorgressTexts from './PorgressTexts'
import TermsAndConditionsPopup from "../profile/TermsAndConditionsPopup";
import PrivacyPolicyPopup from "../profile/PrivacyPolicyPopup";
import ArrowLeft from "assets/svg-icons/ArrowLeft";

export default function SingUpInvite({ email, userName, ownerId, setNotLoggedIn, progTexts }) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showTerms, setShowTerms] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [fullName, setFullname] = useState(userName)

  const showPasswordDontMatchError = useMemo(
    () => repeatPasswordTouched && repeatPassword && repeatPassword !== password,
    [repeatPasswordTouched, repeatPassword, password]
  );

  const submitDisabled = useMemo(() => !password || repeatPassword !== password, [password, repeatPassword]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrors("");
    try {
      setLoading(true);
      const user = await authService.signUp(email, password, fullName, ownerId);
      user && setNotLoggedIn(false)
      setLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setErrors(err.response.data ? err.response.data.errors : {});
      setLoading(false);
    }
  };

  useEffect(() => {
    firebase.auth().signOut()
    return () => progTexts.moveToNextStep()
  }, [])

  return (
    <div className="singup-invitaion-info__wrapper">
      <div>
        <EnviteLogo />
      </div>
      {showRules && (
        <span
          onClick={() => {
            setShowTerms(false);
            setShowRules(false);
          }}
        >
          <ArrowLeft fillColor={"#6b6f76"} /> Go back
        </span>
      )}
      {showRules ? (
        showTerms ? (
          <TermsAndConditionsPopup />
        ) : (
          <PrivacyPolicyPopup />
        )
      ) : (
        <>
          <PorgressTexts textsArr={progTexts.textsArr} />
          <p>
            Fill remaining fields for setup
          </p>
          <div className="selected-email">
            {email}
          </div>
          <form onSubmit={handleSignIn}>
            <div
              className={[
                "sign-up__input--wrapper",
                "password-is-" + (showPassword ? "visible" : "invisible"),
              ].join(" ")}
              style={{ marginTop: 16 }}
            >
              <TextField
                className={`outlined-dense sign-up-live`}
                label="Full name"
                InputProps={{
                  style: {
                    boderColor: "red",
                    fontSize: "12px",
                    color: "#c4c4c4",
                  },
                }}
                fullWidth
                value={fullName}
                onChange={(e) => setFullname(e.target.value)}
                margin="dense"
                variant="outlined"
                type="text"
              />
              <TextField
                className={`password-input outlined-dense sign-up__password sign-up-live`}
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={password}
                margin="dense"
                inputProps={{ minLength: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault}
                        style={{
                          marginTop: showPassword ? 3 : 0,
                        }}
                      >
                        {showPassword ? (
                          <Visibility fillColor={"#dadbdd"} />
                        ) : (
                          <VisibilityOff fillColor={"#dadbdd"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={`password-input outlined-dense sign-up__password sign-up-live`}
                label="Repeat password"
                error={showPasswordDontMatchError}
                helperText={showPasswordDontMatchError && "*Passwords do not match"}
                variant="outlined"
                type={showRepeatPassword ? "text" : "password"}
                fullWidth
                onChange={(e) => setRepeatPassword(e.target.value)}
                onBlur={() => setRepeatPasswordTouched(true)}
                defaultValue={repeatPassword}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                        onMouseDown={(e) => e.preventDefault}
                        style={{
                          marginTop: showRepeatPassword ? 3 : 0,
                        }}
                      >
                        {showRepeatPassword ? (
                          <Visibility fillColor={"#dadbdd"} />
                        ) : (
                          <VisibilityOff fillColor={"#dadbdd"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className="singup-invie-policy">
                By creating an account, you agree with the <span onClick={() => {
                  setShowTerms(true);
                  setShowRules(true);
                }}> Terms of Service </span> and <span onClick={() => setShowRules(true)}> Privacy Policy </span>
              </p>
              <Button
                className={`btn-log-in ${submitDisabled ? "btn-log-in--disabled" : ""
                  }`}
                disabled={submitDisabled || loading}
                type={"submit"}
              >
                {loading ? (
                  <CircularProgress
                    size={25}
                    classes={{ root: "loader-wrapper" }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </>)}
    </div>
  );
}
