import React from 'react'

import './widgetPaymentInputStyles.css'

const WidgetPaymentInput = ({ bluesnapName, icon, error }) => {
  return (
    <div className={`widget-payment-input-root widget-payment-input-root__${bluesnapName}`}>
      <div className='widget-payment-input-container'>
        <div className='widget-payment-input' data-bluesnap={bluesnapName}></div>
        {icon}
      </div>
      {error && (
        <p className='widget-payment-input-error'>
          {error}
        </p>
      )}
    </div>
  )
}

export default WidgetPaymentInput
