import React from 'react';

const DoneSvg = () => {
    return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.408691" width="16" height="16" rx="8" fill="#00AEDC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8536 5.14693C13.0488 5.3422 13.0488 5.65878 12.8536 5.85404L6.85355 11.854C6.65829 12.0493 6.34171 12.0493 6.14645 11.854L3.14645 8.85404C2.95118 8.65878 2.95118 8.3422 3.14645 8.14693C3.34171 7.95167 3.65829 7.95167 3.85355 8.14693L6.5 10.7934L12.1464 5.14693C12.3417 4.95167 12.6583 4.95167 12.8536 5.14693Z" fill="white" />
    </svg>
};

export default DoneSvg;
