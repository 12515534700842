import React from 'react'

export default function PasswordIcon({ isActive, onClick }) {
  return (
    <svg className={["text-field-icon-right", "password-eye", isActive ? 'active' : null].join(' ')} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="21" fill="none" viewBox="0 0 18 16">
      <path fillRule="evenodd" stroke="#29A7FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1.667 8S4.334 2.667 9 2.667C13.667 2.667 16.334 8 16.334 8S13.667 13.333 9 13.333C4.334 13.333 1.667 8 1.667 8z" clipRule="evenodd" />
      <path fillRule="evenodd" stroke="#29A7FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 10c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" clipRule="evenodd" />
    </svg>
  )
}
