import React, { useState, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import UserAvatar from "react-avatar";
import LogoutIcon from "../../assets/svg-icons/LogoutIcon";


export default function UserActions({ owner, signOut, currentUser }) {
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const headerMenuRef = useRef(null);
  const avatarRef = useRef(null);
  const avatarArrowRef = useRef(null);

  const handleAvatarClick = () => {
    setShowHeaderMenu(!showHeaderMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ( (avatarRef && avatarRef.current.contains(event.target)) ) {
        return;
      }
      if (
        headerMenuRef.current &&
        !headerMenuRef.current.contains(event.target)
      ) {
        setShowHeaderMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerMenuRef, avatarRef, avatarArrowRef]);

  return (
    <div className={"nav-panel"}>
      {/* <div className={"notifications"} onClick={() => {}}>
        <NotificationIcon />
        <span className={"unread"}></span>
      </div> */}
      <div className={"my-avatar-container"}>
        <div className={"my-avatar"} ref={avatarRef}>
          {currentUser.photoURL ? (
            <Avatar
              alt="..."
              src={currentUser.photoURL}
              className="user-avatar"
              onClick={handleAvatarClick}
            />
          ) : (
            <UserAvatar
              className="user-avatar user-name-avatar"
              name={currentUser.displayName}
              onClick={handleAvatarClick}
            />
          )}
        </div>
        {showHeaderMenu && (
          <div className={"envite-dropdown-menu"} ref={headerMenuRef}>
            <ul>
              <li
                onClick={() => {
                  setShowHeaderMenu(false);
                  signOut();
                }}
              >
                <span className={"icon"}>
                  <LogoutIcon />
                </span>
                <span className={"label"}>Log out</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
