import React from 'react';
import { CircularProgress } from '@material-ui/core';

const ProfilerWizardNextButton = ({ disabled, onClick, text, form, type = 'button', loading }) => {

  if (form) {
    return (
      <button
        className={'next-btn'}
        form={form}
        type='submit'
        disabled={disabled || loading}
      >
        {!loading && (text ?? 'Next')}
        {loading && <CircularProgress />}
      </button>
    )
  }

  return (
    <button
      className={'next-btn'}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {!loading && (text ?? 'Next')}
      {loading && <CircularProgress size={25} />}
    </button>
  )
}

export default ProfilerWizardNextButton
