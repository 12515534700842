import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import EnviteLogo from "../../assets/svg-icons/EnviteLogo";
import DefaultProfileSVG from "../../assets/svg-icons/DefaultProfileSVG";
import ServiceItem from '../profile/ServiceItem'
import { confirmFreeBooking, confirmPaidBooking } from '../../services/bookingService'
import '../../styles/ConfirmMeeting/invitationInfo.scss'
import { CircularProgress } from '@material-ui/core';
import PorgressTexts from './PorgressTexts'
import WidgetNewCard from '../widget/flow/WidgetPayment/WidgetNewCard'
import { usePayment } from 'hooks'

const InvitationInfo = ({ user, owner, booking, setShowSuccessModal, setBooking, progTexts, history }) => {
    const [loading, setLoading] = useState(false)
    const [payStep, setPayStep] = useState(false)
    const meetingIsEnded = useMemo(() => new Date().getTime() > booking.scheduledAt + booking?.service?.duration * 60 * 1000, [booking])
    const meetingIsNear = useMemo(() => (new Date().getTime() < booking.scheduledAt + booking?.service?.duration * 60 * 1000) && (new Date().getTime() > booking.scheduledAt - 10 * 60 * 1000), [booking])

    const [fullName, setFullname] = React.useState('')
    const {
        submitPaymentInfo,
        shouldSubmit,
        pfToken,
        initPaymentController,
        errors,
        cardType,
        loading: paymentLoading
    } = usePayment()

    useEffect(() => {
        if (booking?.confirmedBy?.includes(user?.uid)) {
            if (meetingIsNear) history.push(`meet/${booking.id}`)
            else setShowSuccessModal(true)
        }
    }, [booking?.confirmedBy])

    const handleConfrirm = async () => {
        setLoading(true)
        if (booking?.service?.price === 0) {
            const confirmedBooking = await confirmFreeBooking(booking.id, owner.id)
            setBooking(confirmedBooking)
        } else {
            setPayStep(true)
            progTexts.moveToNextStep()
        }
        setLoading(false)
    }

    const handlePayment = async () => {
        if (!shouldSubmit) return
        setLoading(true)
        try {
            const data = await submitPaymentInfo()
            if (!(data?.statusCode === '1')) return

            const confirmedBooking = await confirmPaidBooking(booking.id, owner.id, {
                pfToken,
                storeCard: false,
                cardHolderInfo: {
                    firstName: fullName,
                    lastName: '',
                    email: user.email
                }
            })
            setBooking(confirmedBooking)
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoading(false)
        }
    }

    const infoContent = useMemo(() => (
        <>
            <p>
                {owner.firstname || owner.name} <span>invited you to </span>
                {booking?.service?.name}
            </p>
            <DefaultProfileSVG className="invitation-default-svg" />

            {booking?.service && <ServiceItem
                service={booking.service}
                totalServices={1}
                chosenService
            />}

            <div className={`selected-date ${meetingIsEnded ? 'ended' : ''}`}>
                <span className={"date"}>{moment(booking.scheduledAt).format("dddd, MMMM D, YYYY")}</span>
                <span className={"time"}>{moment(booking.scheduledAt).format(owner.timeFormat || 'HH:mm')} - {moment(booking.scheduledAt).add(booking.duration, 'minutes').format(owner.timeFormat || 'HH:mm')}</span>
            </div>

            <p className="info-text">Link to this meeting will be sent
                to your email</p>
            <button disabled={meetingIsEnded} className="invitation-approve-btn" onClick={handleConfrirm}>
                {loading ? <CircularProgress size={25} classes={{ root: 'loader-wrapper' }} /> : (booking?.service?.price ? "Continue to billing" : 'Confirm invitation')}
            </button>
        </>
    ), [booking, owner, loading, meetingIsEnded])

    const paidService = useMemo(() => (
        <>
            <div className={`invitation-pay__wrapper ${paymentLoading && 'invitation-payment-loading'}`}>
                {paymentLoading && (
                    <div className="payment-loader">
                        <CircularProgress />
                    </div>
                )}
                <WidgetNewCard
                    errors={errors}
                    paymentError={""}
                    fullName={fullName}
                    setFullname={setFullname}
                    cardType={cardType}
                    initPaymentController={initPaymentController}
                />
                <p>The security of data transmission through the Internet channel is ensured by the TLS protocol </p>
                <p>The data is protected according to the international standard PCI DSS</p>
            </div>
            <button className="invitation-approve-btn" onClick={handlePayment} >
                {loading ? <CircularProgress size={25} classes={{ root: 'loader-wrapper' }} /> : 'Submit'}
            </button>
        </>
    ), [fullName, cardType, loading, user, paymentLoading, shouldSubmit])

    const meetingEndedTexts = useMemo(() => (
        meetingIsEnded && <div className="meeting-already-ended-text">
            This meeting has already ended
        </div>
    ), [meetingIsEnded])

    const meetingCanceledText = useMemo(() => (
        booking?.status === 'canceled' ? <div className="meeting-already-ended-text">
            This meeting is canceled
        </div> : null
    ), [booking])

    return <div className="invitaion-info__wrapper">
        <div>
            <EnviteLogo />
        </div>

        <PorgressTexts textsArr={progTexts.textsArr} />
        {meetingEndedTexts}
        {meetingCanceledText}

        {payStep ? paidService : infoContent}

    </div>;
};

export default InvitationInfo;
