import React from 'react'

function UserGroupSVG() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_129_4733)">
                <path d="M11.042 13H14.5837V11.5C14.5836 11.0324 14.446 10.5764 14.1899 10.1955C13.9338 9.81451 13.572 9.52749 13.1548 9.37431C12.7375 9.22113 12.2856 9.20941 11.8617 9.34076C11.4379 9.47212 11.0632 9.74003 10.7898 10.1072M11.042 13L10.7898 10.1072M11.042 13H3.95866M11.042 13V11.5C11.042 11.008 10.9527 10.5377 10.7898 10.1072M10.7898 10.1072C10.5268 9.41122 10.0728 8.8146 9.48649 8.39434C8.90016 7.97408 8.20836 7.74947 7.50033 7.74947C6.79229 7.74947 6.10049 7.97408 5.51416 8.39434C4.92783 8.8146 4.47387 9.41122 4.21083 10.1072M3.95866 13H0.416992V11.5C0.417024 11.0324 0.554647 10.5764 0.810727 10.1955C1.06681 9.81451 1.42862 9.52749 1.84588 9.37431C2.26313 9.22113 2.71509 9.20941 3.13893 9.34076C3.56277 9.47212 3.93743 9.74003 4.21083 10.1072M3.95866 13L4.21083 10.1072M3.95866 13V11.5C3.95866 11.008 4.04791 10.5377 4.21083 10.1072M9.62533 3.25C9.62533 3.84674 9.40144 4.41903 9.00293 4.84099C8.60441 5.26295 8.06391 5.5 7.50033 5.5C6.93674 5.5 6.39624 5.26295 5.99772 4.84099C5.59921 4.41903 5.37533 3.84674 5.37533 3.25C5.37533 2.65326 5.59921 2.08097 5.99772 1.65901C6.39624 1.23705 6.93674 1 7.50033 1C8.06391 1 8.60441 1.23705 9.00293 1.65901C9.40144 2.08097 9.62533 2.65326 9.62533 3.25ZM13.8753 5.5C13.8753 5.89782 13.7261 6.27935 13.4604 6.56066C13.1947 6.84196 12.8344 7 12.4587 7C12.0829 7 11.7226 6.84196 11.4569 6.56066C11.1912 6.27935 11.042 5.89782 11.042 5.5C11.042 5.10217 11.1912 4.72064 11.4569 4.43934C11.7226 4.15803 12.0829 4 12.4587 4C12.8344 4 13.1947 4.15803 13.4604 4.43934C13.7261 4.72064 13.8753 5.10217 13.8753 5.5ZM3.95866 5.5C3.95866 5.89782 3.8094 6.27935 3.54373 6.56066C3.27805 6.84196 2.91772 7 2.54199 7C2.16627 7 1.80593 6.84196 1.54026 6.56066C1.27458 6.27935 1.12533 5.89782 1.12533 5.5C1.12533 5.10217 1.27458 4.72064 1.54026 4.43934C1.80593 4.15803 2.16627 4 2.54199 4C2.91772 4 3.27805 4.15803 3.54373 4.43934C3.8094 4.72064 3.95866 5.10217 3.95866 5.5Z" stroke="#828282" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_129_4733">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default UserGroupSVG