import React from 'react'

import WidgetSignUp from 'components/widget/flow/WidgetSignUp'

const SignUp = ({ owner, wizardState, handleGoNext, handleGoBack, nextLoading }) => {
  return (
    <>
      <WidgetSignUp
        handleGoNext={handleGoNext}
        ownerId={owner.id}
        nextLoading={nextLoading}
        tenantId={owner.tenantId}
        handleGoBack={handleGoBack}
        setShouldShowNavigation={(val) => console.log(!val)}
      />
    </>
  )
}

export default SignUp
