import React, { useCallback, useEffect, useContext, useState } from 'react'
import CloseIcon from '../../assets/svg-icons/CloseIcon'
import ProfileFlowStack from './ProfileFlowStack'
import ServiceItem from './ServiceItem'
import moment from 'moment'
import mixpanelApp from '../../services/mixpanel'
import CompleteSVG from 'assets/svg-icons/CompleteSVG'
import DefaultProfileNewSVG from 'assets/svg-icons/DefaultProfileNewSVG'
import { AuthContext } from "context/AuthProvider";
import Modal from '@material-ui/core/Modal';

const wizardSteps = ['choose_date', 'auth', 'profile_info', 'payment', 'summary']

const BookingPopup = ({
    bookingPopup,
    owner,
    services,
    unavailableDays,
    getBookingTimes,
    availableWeekDays,
    exceptionDates,
    fullyBooked,
    getServiceIntervals,
    loader,
    selectedService,
    visible,
    setVisible,
    getAvailableDatesForService
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [resetSteps, setResetSteps] = useState(false);
    const { user } = useContext(AuthContext)

    const printSelectedService = useCallback(() => {
        return selectedService && (
            <ServiceItem
                service={selectedService}
                totalServices={1}
                chosenService
            />
        )
    }, [selectedService]);

    useEffect(() => {
        if (!selectedService?.id || !visible) {
            return
        }
        mixpanelApp.track('page_visit', {
            page: `booking_step.${wizardSteps?.[activeIndex] ?? 'unknown'}`,
            service_id: selectedService?.id,
            service_name: selectedService?.name,
        })
    }, [selectedService, visible, activeIndex])

    useEffect(() => {
        return () => {
            setSelectedTime('');
        }
    }, [visible])

    return (
        <Modal
            className={"booking-popup-wrapper"}
            open={visible}
            ref={bookingPopup}
            disableBackdropClick
        >
            <div className={"booking-popup"}>
                <div className={`booking-header ${activeIndex === 4 && 'booking-info-header'}`}>
                    <div className={"title-wrapper"}>
                        {activeIndex !== 4 && <span className={"title"}>Book service</span>}
                        <span className={"close-icon"} onClick={() => {
                            setVisible(false);
                            setResetSteps(true);
                        }}>
                            <CloseIcon />
                        </span>
                    </div>
                </div>
                <div className={`booking-content active-step-${activeIndex} ${activeIndex === 4 ? 'summary-step' : ''}`}>
                    {(activeIndex === 0 || (user && user.email)) && (
                        <div className={"selected-data"}>
                            {activeIndex === 4 && ( // Summary Step
                                <>
                                    <div className="booking-content-confirm">
                                        <CompleteSVG />
                                        <h3>Confirmed</h3>
                                    </div>
                                    <p className="booking-content-owner">You are scheduled with <span className={"booking-content-owner--name"}>{owner.name}</span></p>
                                    <div className={"image-wrapper booking-content-image-wrapper"}>
                                        {owner.profile ? (
                                            <img className="profile-picture" src={owner.profile} alt="user" />
                                        ) : (
                                                <DefaultProfileNewSVG />
                                            )}
                                    </div>
                                </>
                            )}
                            <div className={"selected-service"}>
                                {printSelectedService()}
                            </div>
                            <div className={"selected-date"}>
                                {selectedDate && (
                                    <span className={"date"}>{moment(selectedDate).format(`${activeIndex === 4 ? 'dddd' : 'ddd'}, MMMM D, YYYY`)}</span>
                                )}
                                {selectedTime && (
                                    <span className={"time"}>{selectedTime.label} - {moment(selectedTime.label, owner.timeFormat || 'HH:mm').add(selectedService.duration, 'minutes').format(owner.timeFormat || 'HH:mm')}</span>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={`profile-flow-stack custom-scroll`}>
                        <ProfileFlowStack
                            user={user}
                            owner={owner}
                            services={services}
                            unavailableDays={unavailableDays}
                            getBookingTimes={getBookingTimes}
                            availableWeekDays={availableWeekDays}
                            exceptionDates={exceptionDates}
                            fullyBooked={fullyBooked}
                            getServiceIntervals={getServiceIntervals}
                            loader={loader}
                            selectedService={selectedService}
                            setActiveIndex={setActiveIndex}
                            setSelectedDate={setSelectedDate}
                            setSelectedTime={setSelectedTime}
                            selectedTime={selectedTime}
                            resetSteps={resetSteps}
                            setResetSteps={setResetSteps}
                            setVisible={setVisible}
                            getAvailableDatesForService={getAvailableDatesForService}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BookingPopup;
