import React from 'react';
import '../../styles/ConfirmMeeting/progresTexts.scss'
import DoneSvg from '../../assets/svg-icons/DoneSvg'

const PorgressTexts = ({ textsArr }) => {
    return <div className="progress-texts__wrapper">
        {textsArr?.map((item) => (
            <div className={`${item.status} progress-texts-step`} key={item.step}>
                <DoneSvg />
                <span > {item.name} &nbsp;{item.step !== 'last' && '-'}</span>
            </div>
        ))}
    </div>;
};

export default PorgressTexts;
