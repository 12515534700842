import React from 'react'

import './widgetChooseCardStyles.css'

import WidgetCreditCard from './WidgetCreditCard'

const WidgetChooseCards = ({ handleAddCard, handleChangeState, active, setActive, paymentMethods }) => {
  const handleAddNewCard = () => {
    handleChangeState({ selectedCard: null })
    handleAddCard()
  }

  const toggleCard = (index) => {
    setActive(index)
    handleChangeState({ selectedCard: paymentMethods[index].creditCard })
  }

  return (
    <div className='widget-choose-card-container'>
      {paymentMethods && paymentMethods.map(({ creditCard: { cardType, cardLastFourDigits } }, i) => (
        <WidgetCreditCard
          active={active === i}
          key={cardLastFourDigits}
          cardType={cardType}
          onClick={() => toggleCard(i)}
          cardLastFourDigits={cardLastFourDigits}
        />
      ))}
      <button className='widget-choose-card-add-button' onClick={handleAddNewCard}>
        Add New Card
      </button>
    </div>
  )
}

export default WidgetChooseCards
