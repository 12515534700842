import Profile from 'components/profile/Profile';
import ProfilePlaceholder from 'components/profile/ProfilePlaceholder';
import { AuthContext } from 'context/AuthProvider';
import React, { useContext } from 'react';
import { Route } from 'react-router';

export default function PrivateRoute({component: Component, ...rest}) {
    const { user, initialLoad } = useContext(AuthContext)  

    if (!initialLoad) return <ProfilePlaceholder />
    
    return (
        <Route {...rest} render={props => (
          user ? <Component {...props} /> : <Profile {...props} forceLogin />
        )} />
    );
};