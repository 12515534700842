import React from 'react'

function NewUserSVG({ size = 20, fillColor = '#828282' }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.66675 17.5C3.66675 17.6326 3.71943 17.7598 3.81319 17.8536C3.90696 17.9473 4.03414 18 4.16675 18H15.8334C16.1096 18 16.3334 17.7761 16.3334 17.5C16.3334 15.8203 15.6662 14.2094 14.4784 13.0217C13.2907 11.8339 11.6798 11.1667 10.0001 11.1667C8.32038 11.1667 6.70947 11.8339 5.52174 13.0217C4.33401 14.2094 3.66675 15.8203 3.66675 17.5ZM12.7107 8.54391C13.4295 7.82502 13.8334 6.85 13.8334 5.83333C13.8334 4.81667 13.4295 3.84165 12.7107 3.12276C11.9918 2.40387 11.0167 2 10.0001 2C8.98342 2 8.0084 2.40387 7.28951 3.12276C6.57062 3.84165 6.16675 4.81667 6.16675 5.83333C6.16675 6.85 6.57062 7.82502 7.28951 8.54391C8.0084 9.2628 8.98342 9.66667 10.0001 9.66667C11.0167 9.66667 11.9918 9.2628 12.7107 8.54391Z"
                stroke={fillColor}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default NewUserSVG