
import React from 'react'

const ErrorIconSVG = () => {
    return (
        <svg width='16' height='16' fill="none" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334z" stroke="#FF707A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 5.333V8" stroke="#FF707A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="8" cy="10.667" fill="#FF707A" r=".667"/>
        </svg>

    )
}


export default ErrorIconSVG