import React from 'react'

function ClockSVG({ size = 20, fillColor = '#828282' }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4375 9.5V9.76777L10.6604 9.91617L13.9904 12.1336C13.9907 12.1338 13.991 12.134 13.9912 12.1341C14.1926 12.2702 14.238 12.5292 14.0952 12.7207L14.0792 12.7421L14.0656 12.7652C13.9702 12.9267 13.7322 12.9965 13.5293 12.8452L13.5188 12.8374L13.5078 12.8301L9.75782 10.3301L9.73805 10.3169L9.71713 10.3056C9.64908 10.2691 9.5625 10.1671 9.5625 9.96484V4.6875C9.5625 4.44411 9.75661 4.25 10 4.25C10.2434 4.25 10.4375 4.44411 10.4375 4.6875V9.5ZM10 0.5C15.2473 0.5 19.5 4.75271 19.5 10C19.5 15.2473 15.2473 19.5 10 19.5C4.75271 19.5 0.5 15.2473 0.5 10C0.5 4.75271 4.75271 0.5 10 0.5ZM1.375 10C1.375 14.7644 5.23558 18.625 10 18.625C14.7644 18.625 18.625 14.7644 18.625 10C18.625 5.23558 14.7644 1.375 10 1.375C5.23558 1.375 1.375 5.23558 1.375 10Z"
                fill={fillColor}
                stroke={fillColor}
            />
        </svg>
    )
}

export default ClockSVG