import React from 'react'
import TextField from '@material-ui/core/TextField'
import MCIcon from 'assets/svg-icons/MCIcon'
import VisaIcon from 'assets/svg-icons/VisaIcon'
import AmexIcon from 'assets/svg-icons/AmexIcon'
import { cardLocales } from 'utils/cardErrorLocales'

import WidgetPaymentError from '../WidgetPaymentError'
import WidgetPaymentInput from '../WidgetPaymentInput'

import './widgetNewCardStyles.css'

const WidgetNewCard = ({
  shouldSave,
  setShouldSave,
  initPaymentController,
  paymentError,
  errors,
  setFullname,
  fullName,
  cardType,
}) => {
  React.useEffect(() => {
    initPaymentController()
  }, [initPaymentController])

  return (
    <div className='widget-flow__wrapper'>
      <div className='widget-flow-about-inner'>
        <div className='Add-Payment-Details'>Add Credit card details to complete payment</div>
        <div className='widget-payment-fields-column'>
          <WidgetPaymentInput
            bluesnapName='ccn'
            icon={<div className='widget-payment-cards'>
              <div>
                <MCIcon active={cardType === 'MASTERCARD'} />
              </div>
              <div>
                <VisaIcon active={cardType === 'VISA'} />
              </div>
              <div>
                <AmexIcon active={cardType === 'AMEX'} />
              </div>
            </div>}
            error={cardLocales[errors.ccn]}
          />
          <TextField
            id='payment-cardholder'
            placeholder='Full name of cardholder'
            variant='outlined'
            value={fullName}
            onChange={(e) => setFullname(e.target.value)}
            className={'widget-payment-cardholder'}
          />
        </div>
        <div className='widget-payment-fields-row'>
          <WidgetPaymentInput
            bluesnapName='cvv'
            // icon={<CCVIcon />}
            error={cardLocales[errors.cvv]}
          />
          <WidgetPaymentInput
            bluesnapName='exp'
            //icon={<CalendarIcon />}
            error={cardLocales[errors.exp]}
          />
        </div>

        {paymentError && <WidgetPaymentError message='Payment unsuccessful' />}

        {setShouldSave && <div className='widget-payment-save-checkbox-container'>
          <input
            type='checkbox'
            checked={shouldSave}
            onChange={() => setShouldSave(state => !state)}
            className='widget-payment-save-checkbox'
            id='widget-payment-save-checkbox'
          />
          <label htmlFor='widget-payment-save-checkbox' className='widget-payment-save-label'>
            Save this card
          </label>
        </div>}
      </div>
    </div>

  )
}

export default WidgetNewCard
