import React from 'react';

const CloseIcon = ({ fillColor = '#9B9EA3' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
        <g opacity=".64">
            <path stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 1L1 13M1 1l12 12"/>
        </g>
    </svg>
)

export default CloseIcon;
