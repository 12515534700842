import React from 'react'

export default function CalendarIcon({ fillColor = '#9FB1BE' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="none" viewBox="0 0 18 20">
      <rect width="16.2" height="16.2" x="1" y="2.8" stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" rx="2.25" />
      <path stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12.7 1v3.6M5.5 1v3.6M1 8.2h16.2" />
    </svg>
  )
}
