import React from 'react'

const MCIcon = ({ active }) => (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8013 11.1279H7.60547V1.69492H12.8013V11.1279Z" fill={active ? '#FF5A00' : "#D8E0E5"} />
        <path d="M7.9496 6.40918C7.9496 8.32568 8.84119 10.0267 10.2093 11.1257C9.20244 11.9257 7.93231 12.4092 6.54689 12.4092C3.26492 12.4092 0.609375 9.72568 0.609375 6.40918C0.609375 3.09267 3.26492 0.40918 6.54689 0.40918C7.93231 0.40918 9.20244 0.892674 10.2093 1.69267C8.83927 2.77617 7.9496 4.49267 7.9496 6.40918Z" fill={active ? '#EB001B' : "#D8E0E5"} />
        <path d="M19.807 6.40918C19.807 3.09267 17.1514 0.40918 13.8695 0.40918C12.484 0.40918 11.2139 0.892674 10.207 1.69267C11.5925 2.79364 12.4667 4.49267 12.4667 6.40918C12.4667 8.32568 11.5752 10.0267 10.207 11.1257C11.212 11.9257 12.4821 12.4092 13.8675 12.4092C17.1514 12.4092 19.807 9.70821 19.807 6.40918Z" fill={active ? '#F79E1B' : "#D8E0E5"} />
    </svg>
);

export default MCIcon;
