import React from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";
import "../../styles/profile/Calendar.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import {
	formatDate
} from 'react-day-picker/moment';
import CalendarIcon from "../../assets/svg-icons/CalendarIcon";

export default function Calendar({
	 disabledBefore = new Date(),
	 value,
	 onInput,
	 mobile,
	 unavailableDays,
	 exceptionDates,
	 availableWeekDays,
	 fullyBooked,
	 fullyBookedServiceDate,
	 loader,
	 calendarLoader,
	 selectedDate,
	 handleDayPickerOpen
}) {
	const [dateError, setDateError] = React.useState(false);
	disabledBefore.setHours(0, 0, 0);
	disabledBefore.setMilliseconds(0);

	const disabledDays = (day) => {
		day.setHours(0, 0, 0);
		const date = moment(new Date(day), 'YYYY-MM-DD').format('YYYY-MM-DD');
		if (calendarLoader) return day.getTime() < disabledBefore.getTime() || day.getTime() > disabledBefore.getTime() || day.getTime() === disabledBefore.getTime();
		else
			return day.getTime() < disabledBefore.getTime()
				|| (availableWeekDays && !availableWeekDays.includes(day.getDay()) && exceptionDates && !exceptionDates.includes(date))
		|| (unavailableDays && unavailableDays.includes(date))
		|| (fullyBooked && fullyBooked.includes(date))
        || (fullyBookedServiceDate && fullyBookedServiceDate.includes(date))
	};

	const modifiersStyles = {
		booked: {
			color: 'red'
		},
		bookedService: {
			color: 'red'
		}
	};

	return (
		<>
			<div className={"input-label"}>
				Date
			</div>
			<div className={`envite-input calendar ${mobile !== 2 ? '' : 'date-with-time'} ${calendarLoader === 'blur' ? 'loading-blur' : ''} ${dateError ? 'has-error' : ''}`}>
				{(loader || (calendarLoader && calendarLoader !== "blur")) ? (
					<div className={`calendar-loader`}>
						<CircularProgress size={20} />
					</div>
				) : (
					<DayPickerInput
						dayPickerProps={{
							disabledDays : disabledDays,
							month : selectedDate && new Date(selectedDate),
							selectedDays : value,
							fixedWeeks : true,
							modifiers : {
								booked: day => fullyBooked && fullyBooked.includes(moment(day).format("YYYY-MM-DD")),
								bookedService: day => fullyBookedServiceDate && fullyBookedServiceDate.includes(moment(day).format("YYYY-MM-DD"))
							},
							modifiersStyles: modifiersStyles
						}}
						inputProps={{
							onChange : (e) => {
								const date  = new Date(e.target.value);
								const isPastDate = (new Date().getTime() - date.getTime()) > 86400000; // One day in milliseconds
								if( !date.getTime() || isPastDate ){
									setDateError(true);
									return;
								}
								onInput(date);
								setDateError(false);
							}
						}}
						placeholder={"Select date"}
						formatDate={formatDate}
						onDayChange={(day, modifiers = {}) => {
							!modifiers.disabled && onInput(day)
							day && setDateError(false);
						}}
						value={value}
						onDayPickerShow={handleDayPickerOpen}
						showOverlay
					/>
				)}
				<span className={"right-icon"}>
					<CalendarIcon fillColor={"#9B9EA3"}/>
				</span>
			</div>
		</>
	);
}
