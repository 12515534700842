
import moment from 'moment'

const dayFormate = (day) => {
    let hours = moment().hour();
    let minutes = moment().minutes();
    let time = moment(day, "YYYY-MM-DD HH:mm").set({
        hour: hours,
        minute: minutes,
    });
    return moment.utc(time).format("YYYY-MM-DD HH:mm");
};

const roundUpIntervals = (duration) => { //round intervals duration
    if (duration > 0 && duration <= 10) return 10;
    if (duration > 15 && duration <= 20) return 20;
    else return Math.ceil(duration / 15) * 15
}

const serviceDurations = (date, duration) => { // add time the duration of service
    let time = moment(date).format("HH:mm")
    let timeParts = time.split(':');
    let hour = parseInt(timeParts[0]);
    let minute = parseInt(timeParts[1])
    let day = moment(date).set({ hour: hour, minute: minute }).format('YYYY-MM-DD HH:mm')
    return moment(day).add(duration, "minutes").format('YYYY-MM-DD HH:mm');
}


export default {
    dayFormate,
    roundUpIntervals,
    serviceDurations
}