import React from 'react'

import { paymentService } from 'services'

const getPaymentController = ({ token, onError, onValid, setupComplete, onCardType }) => ({
  token,
  onFieldEventHandler: {
    onError,
    onBlur: () => { },
    onFocus: () => { },
    onType: onCardType,
    setupComplete,
    onValid
  },
  style: {
    "#ccn": { "color": '#000', "height": "42px", 'padding-left': "20px", "top": "54px", "background-color": "transparent!important", "font-size": "16px", 'border-radius': '7px' },
    "#exp": { "color": '#000', "height": "42px", 'padding-left': "20px", "top": "54px", "background-color": "transparent!important", "font-size": "16px", 'border-radius': '7px' },
    "#cvv": { "color": '#000', "font-size": "16px", 'padding-left': "20px" },
    "::placeholder": { "color": "rgb(170, 170, 170)", "font-size": "14px" },
    ".invalid": { "color": "#fff" }
  },
  ccnPlaceHolder: "Card number",
  cvvPlaceHolder: "CVV",
  expPlaceHolder: "Expiry date MM/YYYY"
})


const usePayment = () => {
  const [error, setError] = React.useState({ ccn: '' })
  const [pfToken, setPfToken] = React.useState()
  const [cardType, setCardType] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const shouldSubmit = React.useMemo(() => {
    return !['ccn', 'cvv', 'exp'].map((el) => !error[el] && typeof error[el] === 'boolean').includes(false)
  }, [error])

  const onError = React.useCallback(async (name, code, message) => {
    setError(state => ({ ...state, [name]: message }))

    if (code === 14040) {
      const data = await paymentService.generatePfToken()
      setPfToken(data.token)
    }
  }, [])

  const onCardType = React.useCallback((_tagId, newCardType) => {
    setCardType(newCardType)
  }, [setCardType])

  const initPaymentController = React.useCallback(async () => {
    try {
      setLoading(true)
      const data = await paymentService.generatePfToken()
      setPfToken(data.token)
      const controller = getPaymentController({
        token: data.token,
        onError,
        onValid: (name) => setError(state => ({ ...state, [name]: false })),
        setupComplete: () => setLoading(false),
        onCardType
      })
      window.bluesnap.hostedPaymentFieldsCreate(controller)
    } catch (error) {
      console.log('error: ', error);
    }
  }, [onError, onCardType])



  const submitPaymentInfo = () => {
    return new Promise((resolve, reject) => {
      try {
        window.bluesnap.hostedPaymentFieldsSubmitData(cb => resolve(cb))
      } catch (err) {
        reject(err)
      }
    })
  }

  return {
    initPaymentController,
    submitPaymentInfo,
    errors: error,
    shouldSubmit,
    pfToken,
    loading,
    setLoading,
    cardType
  }
}

export default usePayment
