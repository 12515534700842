import React, { useMemo, useState, useEffect } from "react";
import DefaultProfileSVG from "../../assets/svg-icons/DefaultProfileSVG";
import MessagePopup from "./MessagePopup";
import SocialsBar from "./SocialsBar";
import { isMobile } from 'react-device-detect';

const CHARS_TO_SHOW = 125;

const About = ({
  owner: { profile, displayName, generalText, email, firstname, lastname, socialNetworks },
}) => {
  const [messagePopupVisible, setMessagePopupVisible] = useState(false);
  const [showMoreClicked, setShowMoreClicked] = useState(false)
  const shouldTruncateText = useMemo(() => !showMoreClicked && isMobile && generalText && generalText.length > CHARS_TO_SHOW, [generalText, showMoreClicked])


  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact')) setMessagePopupVisible(true)
  }, [])

  return (
    <>
      <div className={"owner"}>
        <div className={"image-wrapper"}>
          {profile ? (
            <img className="profile-picture" src={profile} alt="user" />
          ) : (
            <DefaultProfileSVG className="profile-picture" />
          )}
        </div>
        <div className={"info-wrapper"}>
          {!isMobile && <h3 className={"display-mobile"}>{displayName}</h3>}
          {socialNetworks ? <SocialsBar {...socialNetworks} /> : <ul className="social-links"></ul>}
          <button
            className={"btn"}
            onClick={() => {
              setMessagePopupVisible(true);
            }}
            id={"envite-event-client-contact-me"}
          >
            Contact me
          </button>
        </div>
      </div>
      <div className={"info"}>
        <h3 className={"display-desktop display-owner-name"}>{displayName}</h3>
        {generalText && <p>{generalText.substring(0, shouldTruncateText ? CHARS_TO_SHOW : generalText.length)} {shouldTruncateText && '....'}</p>}
        {shouldTruncateText && <div className="info-action show-more" onClick={() => setShowMoreClicked(true)}>
          <p>More</p>
        </div>}
        {showMoreClicked && <div className="info-action show-less" onClick={() => setShowMoreClicked(false)}>
          <p>Less</p>
        </div>}
      </div>
      <MessagePopup
        visible={messagePopupVisible}
        setVisible={setMessagePopupVisible}
        owner={{ email, firstname, lastname, displayName }}
      />
    </>
  );
};

export default About;
