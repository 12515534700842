import React from 'react'

export default function ErrorSVG() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 14.6673C11.6821 14.6673 14.6668 11.6825 14.6668 8.00065C14.6668 4.31875 11.6821 1.33398 8.00016 1.33398C4.31826 1.33398 1.3335 4.31875 1.3335 8.00065C1.3335 11.6825 4.31826 14.6673 8.00016 14.6673Z" stroke="#FF707A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 5.33398V8.00065" stroke="#FF707A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="8.00016" cy="10.6667" rx="0.666667" ry="0.666667" fill="#FF707A" />
    </svg>

  )
}