import React from 'react';
import { Link } from 'react-router-dom';

export default function MainPage() {
  return (
    <div>
      This is main page. <Link to="/profile/mevrisham">Go to</Link> one of the profile page
    </div>
  )
}
