import React from 'react'

export default function OnlineSVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path stroke="#6A6E75" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M15 2H3c-.828 0-1.5.672-1.5 1.5V11c0 .828.672 1.5 1.5 1.5h12c.828 0 1.5-.672 1.5-1.5V3.5c0-.828-.672-1.5-1.5-1.5zM6 15.5h6M9 12.5v3" />
            <path stroke="#6A6E75" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M12.073 11.776v-.75c0-.398-.158-.78-.44-1.06-.28-.282-.662-.44-1.06-.44h-3c-.398 0-.78.158-1.06.44-.282.28-.44.662-.44 1.06v.75M9.073 8.026c.829 0 1.5-.672 1.5-1.5 0-.829-.671-1.5-1.5-1.5-.828 0-1.5.671-1.5 1.5 0 .828.672 1.5 1.5 1.5z" />
        </svg>

    )
}