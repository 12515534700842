import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import * as authService from "services/authService";
import { eventManager } from "utils";

import PasswordIcon from "assets/svg-icons/PasswordIcon";
import ProfilerWizardBackButton from "components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardBackButton";
import ProfilerWizardNextButton from "components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardNextButton";

import WidgetTermsAndConditions from "./WidgetTermsAndConditions";
import WidgetPrivacyPolicy from "./WidgetPrivacyPolicy";
import WidgetSignIn from "./WidgetSignIn";

import "../widget.css";
import ProviderSignin from "components/profile/ProviderSignin";

const WidgetSignUp = ({
  handleGoNext,
  handleGoBack,
  ownerId,
  tenantId,
  nextLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [termsAndCons, setTermsAndCons] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const setNavAndReturn = (val) => {
    return val;
  };

  const toggleTerms = () => setTermsAndCons((prev) => setNavAndReturn(!prev));
  const togglePolicy = () => setPrivacyPolicy((prev) => setNavAndReturn(!prev));
  const toggleSignIn = () => {
    setSignIn((prev) => setNavAndReturn(!prev));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await authService.signUp(
        email,
        password,
        fullName.trim(),
        ownerId,
        tenantId
      );
      setLoading(false);
      handleGoNext();
    } catch (err) {
      setErrors(err.response.data ? err.response.data.errors : {});
      setLoading(false);
      const { data } = err.response;
      if (data.statusCode === 400) {
        setErrors({
          email: [data.message]
        })
      }
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      setLoading(true);
      await authService.signInWithGoogle();
      setLoading(false);
      handleGoNext();
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail("Sign in failed");
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      setLoading(true);
      await authService.signInWithFacebook();
      setLoading(false);
      handleGoNext();
    } catch (err) {
      setLoading(false);
      eventManager.showNotificationFail(err.message);
    }
  };

  const handleEmailChange = (e) => {
    if (errors && errors.email) {
      setErrors(prev => (
        {
          ...prev,
          email: null,
        }
      ))
    }
    setEmail(e.target.value.trim())
  }

  const handleNameChange = (e) => {
    if (errors && errors.fullName) {
      setErrors(prev => (
        {
          ...prev,
          fullName: null,
        }
      ))
    }
    setFullName(e.target.value)
  }

  const handlePasswordChange = (e) => {
    if (errors && errors.password) {
      setErrors(prev => (
        {
          ...prev,
          password: null,
        }
      ))
    }
    setPassword(e.target.value)
  }

  if (termsAndCons)
    return <WidgetTermsAndConditions toggleTerms={toggleTerms} />;
  if (privacyPolicy) return <WidgetPrivacyPolicy toggleTerms={togglePolicy} />;
  if (signIn)
    return (
      <WidgetSignIn
        nextLoading={nextLoading}
        handleGoNext={handleGoNext}
        handleGoBack={handleGoBack}
        toggleSignIn={toggleSignIn}
      />
    );

  return (
    <>
      <form id="sign-up-form" onSubmit={handleSubmit}>
        <div className="widget-signup-inner-bottom">
          <h2 className="widget-signup-title"><span>Sign up</span> to book a service</h2>
          <p className="widget-signup-text">Don’t worry, it will take only 1 minute.</p>
          <div className="widget-registration-texfield-container">
            <label htmlFor="full-name">Full name</label>
            <TextField
              id="full-name"
              placeholder="Enter your full name"
              error={errors && errors.fullName}
              helperText={errors && errors.fullName && (errors.fullName[1] || errors.fullName[0])}
              value={fullName}
              variant="outlined"
              required
              onChange={handleNameChange}
              className={"widget-registration-input"}
              inputProps={{ maxLength: 35 }}
            />
            <label htmlFor="email">Email</label>
            <TextField
              id="email"
              placeholder="Enter your email"
              error={errors && errors.email}
              helperText={
                errors && errors.email && (errors.email[1] || errors.email[0])
              }
              onChange={handleEmailChange}
              variant="outlined"
              value={email}
              required
              className={"widget-registration-input"}
            />
            <label htmlFor="password">Password</label>
            <TextField
              id="password"
              error={errors && errors.password}
              helperText={errors && errors.password && errors.password[0]}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
              variant="outlined"
              value={password}
              required
              className={"widget-registration-input"}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      isActive={showPassword}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="widget-sign-up-or">
            <span>or</span>
          </div>
          <ProviderSignin handleSignInWithGoogle={handleSignInWithGoogle} handleSignInWithFacebook={handleSignInWithFacebook} type="signUp" />
          <div className="widget-signup-already-have-account-box">
            <span>Already have an account?</span>
            <button type="button" onClick={toggleSignIn}>
              Login
            </button>
          </div>
          <div className="widget-signup-terms">
            By creating an account, you agree with the
            <br />
            <span onClick={toggleTerms}>Terms of Service</span> and{" "}
            <span onClick={togglePolicy}>Privacy Policy</span>
          </div>
        </div>
        <div className="cancel-next-message">
          <ProfilerWizardBackButton onClick={handleGoBack} />
          <ProfilerWizardNextButton
            onClick={handleSubmit}
            loading={loading || nextLoading}
            text="Sign up"
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default WidgetSignUp;
