import React from 'react';

export default function Visibility({ fillColor }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" fill="none" viewBox="0 0 18 15">
            <path fillRule="evenodd" stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeOpacity=".8" strokeWidth="1.5" d="M1 7s2.91-6 8-6 8 6 8 6-2.91 6-8 6-8-6-8-6z" clipRule="evenodd"/>
            <path fillRule="evenodd" stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeOpacity=".8" strokeWidth="1.5" d="M8.998 9.25c1.205 0 2.182-1.007 2.182-2.25s-.977-2.25-2.182-2.25S6.816 5.757 6.816 7s.977 2.25 2.182 2.25z" clipRule="evenodd"/>
        </svg>
    );
}
