import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import "firebase/analytics";

let config = {
  develop: {
    apiKey: 'AIzaSyAC0NvARrg94SlggLYzRdY7Y_ikwVPgtZM',
    authDomain: 'envite-live-dev.firebaseapp.com',
    databaseURL: 'https://envite-live-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'envite-live-dev',
    storageBucket: 'envite-live-dev.appspot.com',
    messagingSenderId: '979235244182',
    appId: '1:979235244182:web:e7af1f658c8aedc8ce1ab7',
    measurementId: 'G-ESGQG2YQHJ',
  },
  staging: {
    apiKey: 'AIzaSyAnsN4lreB58fCZ85RjASA4RCmkq5kXv-k',
    authDomain: 'envite-live-staging.firebaseapp.com',
    databaseURL: 'https://envite-live-staging-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'envite-live-staging',
    storageBucket: 'envite-live-staging.appspot.com',
    messagingSenderId: '828749383642',
    appId: '1:828749383642:web:a3b0f295826174e08b62e3',
    measurementId: 'G-1JBVHVBGXZ',
  },
  production: {
    apiKey: 'AIzaSyDqMW7HZGligb7C2rp_EK_a7LkB2QepPyo',
    authDomain: 'envite-live-prod.firebaseapp.com',
    databaseURL: 'https://envite-live-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'envite-live-prod',
    storageBucket: 'envite-live-prod.appspot.com',
    messagingSenderId: '411757829897',
    appId: '1:411757829897:web:18820b24baf9328334bdd8',
    measurementId: 'G-3D8ZSHDW5J',
  },
}

export const app = firebase.initializeApp(config[process.env.REACT_APP_ENV])
export { firebase }
export default firebase
