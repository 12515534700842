const timezones = [
    { "id": "Asia/Kabul", "name": "Afghanistan/Kabul (GMT +04:30)", "country_code": "af", "offset": 16200 },
    { "id": "Africa/Juba", "name": "Africa/Juba (GMT +03:00)", "country_code": "ss", "offset": 10800 },
    { "id": "Europe/Tirane", "name": "Albania/Tirane (GMT +01:00)", "country_code": "al", "offset": 3600 },
    { "id": "Africa/Algiers", "name": "Algeria/Algiers (GMT +01:00)", "country_code": "dz", "offset": 3600 },
    { "id": "America/Curacao", "name": "America/Curacao (GMT -04:00)", "country_code": "cw", "offset": -14400 },
    { "id": "America/Kralendijk", "name": "America/Kralendijk (GMT -04:00)", "country_code": "bq", "offset": -14400 },
    { "id": "America/Lower_Princes", "name": "America/Lower_Princes (GMT -04:00)", "country_code": "sx", "offset": -14400 },
    { "id": "America/Marigot", "name": "America/Marigot (GMT -04:00)", "country_code": "mf", "offset": -14400 },
    { "id": "America/St_Barthelemy", "name": "America/St_Barthelemy (GMT -04:00)", "country_code": "bl", "offset": -14400 },
    { "id": "Pacific/Pago_Pago", "name": "American Samoa/Pago_Pago (GMT -11:00)", "country_code": "as", "offset": -39600 },
    { "id": "Europe/Andorra", "name": "AndorrA/Andorra (GMT +01:00)", "country_code": "ad", "offset": 3600 },
    { "id": "Africa/Luanda", "name": "Angola/Luanda (GMT +01:00)", "country_code": "ao", "offset": 3600 },
    { "id": "America/Anguilla", "name": "Anguilla/Anguilla (GMT -04:00)", "country_code": "ai", "offset": -14400 },
    { "id": "Antarctica/Casey", "name": "Antarctica/Casey (GMT +08:00)", "country_code": "aq", "offset": 28800 },
    { "id": "Antarctica/Davis", "name": "Antarctica/Davis (GMT +07:00)", "country_code": "aq", "offset": 25200 },
    { "id": "Antarctica/DumontDUrville", "name": "Antarctica/DumontDUrville (GMT +10:00)", "country_code": "aq", "offset": 36000 },
    { "id": "Antarctica/Mawson", "name": "Antarctica/Mawson (GMT +05:00)", "country_code": "aq", "offset": 18000 },
    { "id": "Antarctica/McMurdo", "name": "Antarctica/McMurdo (GMT +13:00)", "country_code": "aq", "offset": 46800 },
    { "id": "Antarctica/Palmer", "name": "Antarctica/Palmer (GMT -03:00)", "country_code": "aq", "offset": -10800 },
    { "id": "Antarctica/Rothera", "name": "Antarctica/Rothera (GMT -03:00)", "country_code": "aq", "offset": -10800 },
    { "id": "Antarctica/Syowa", "name": "Antarctica/Syowa (GMT +03:00)", "country_code": "aq", "offset": 10800 },
    { "id": "Antarctica/Troll", "name": "Antarctica/Troll (GMT +00:00)", "country_code": "aq", "offset": 0 },
    { "id": "Antarctica/Vostok", "name": "Antarctica/Vostok (GMT +06:00)", "country_code": "aq", "offset": 21600 },
    { "id": "America/Antigua", "name": "Antigua and Barbuda/Antigua (GMT -04:00)", "country_code": "ag", "offset": -14400 },
    { "id": "America/Argentina/San_Juan", "name": "Argentina/San_Juan (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Salta", "name": "Argentina/Salta (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Catamarca", "name": "Argentina/Catamarca (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Mendoza", "name": "Argentina/Mendoza (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Jujuy", "name": "Argentina/Jujuy (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Cordoba", "name": "Argentina/Cordoba (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Buenos_Aires", "name": "Argentina/Buenos_Aires (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/La_Rioja", "name": "Argentina/La_Rioja (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Rio_Gallegos", "name": "Argentina/Rio_Gallegos (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/San_Luis", "name": "Argentina/San_Luis (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Tucuman", "name": "Argentina/Tucuman (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "America/Argentina/Ushuaia", "name": "Argentina/Ushuaia (GMT -03:00)", "country_code": "ar", "offset": -10800 },
    { "id": "Asia/Yerevan", "name": "Armenia/Yerevan (GMT +04:00)", "country_code": "am", "offset": 14400 },
    { "id": "America/Aruba", "name": "Aruba/Aruba (GMT -04:00)", "country_code": "aw", "offset": -14400 },
    { "id": "Australia/Adelaide", "name": "Australia/Adelaide (GMT +10:30)", "country_code": "au", "offset": 37800 },
    { "id": "Australia/Brisbane", "name": "Australia/Brisbane (GMT +10:00)", "country_code": "au", "offset": 36000 },
    { "id": "Australia/Broken_Hill", "name": "Australia/Broken_Hill (GMT +10:30)", "country_code": "au", "offset": 37800 },
    { "id": "Australia/Currie", "name": "Australia/Currie (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Australia/Darwin", "name": "Australia/Darwin (GMT +09:30)", "country_code": "au", "offset": 34200 },
    { "id": "Australia/Eucla", "name": "Australia/Eucla (GMT +08:45)", "country_code": "au", "offset": 31500 },
    { "id": "Australia/Hobart", "name": "Australia/Hobart (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Australia/Lindeman", "name": "Australia/Lindeman (GMT +10:00)", "country_code": "au", "offset": 36000 },
    { "id": "Australia/Lord_Howe", "name": "Australia/Lord_Howe (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Antarctica/Macquarie", "name": "Australia/Macquarie (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Australia/Melbourne", "name": "Australia/Melbourne (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Australia/Perth", "name": "Australia/Perth (GMT +08:00)", "country_code": "au", "offset": 28800 },
    { "id": "Australia/Sydney", "name": "Australia/Sydney (GMT +11:00)", "country_code": "au", "offset": 39600 },
    { "id": "Europe/Vienna", "name": "Austria/Vienna (GMT +01:00)", "country_code": "at", "offset": 3600 },
    { "id": "Asia/Baku", "name": "Azerbaijan/Baku (GMT +04:00)", "country_code": "az", "offset": 14400 },
    { "id": "America/Nassau", "name": "Bahamas/Nassau (GMT -05:00)", "country_code": "bs", "offset": -18000 },
    { "id": "Asia/Bahrain", "name": "Bahrain/Bahrain (GMT +03:00)", "country_code": "bh", "offset": 10800 },
    { "id": "Asia/Dhaka", "name": "Bangladesh/Dhaka (GMT +06:00)", "country_code": "bd", "offset": 21600 },
    { "id": "America/Barbados", "name": "Barbados/Barbados (GMT -04:00)", "country_code": "bb", "offset": -14400 },
    { "id": "Europe/Minsk", "name": "Belarus/Minsk (GMT +03:00)", "country_code": "by", "offset": 10800 },
    { "id": "Europe/Brussels", "name": "Belgium/Brussels (GMT +01:00)", "country_code": "be", "offset": 3600 },
    { "id": "America/Belize", "name": "Belize/Belize (GMT -06:00)", "country_code": "bz", "offset": -21600 },
    { "id": "Africa/Porto-Novo", "name": "Benin/Porto-Novo (GMT +01:00)", "country_code": "bj", "offset": 3600 },
    { "id": "Atlantic/Bermuda", "name": "Bermuda/Bermuda (GMT -04:00)", "country_code": "bm", "offset": -14400 },
    { "id": "Asia/Thimphu", "name": "Bhutan/Thimphu (GMT +06:00)", "country_code": "bt", "offset": 21600 },
    { "id": "America/La_Paz", "name": "Bolivia/La_Paz (GMT -04:00)", "country_code": "bo", "offset": -14400 },
    { "id": "Europe/Sarajevo", "name": "Bosnia and Herzegovina/Sarajevo (GMT +01:00)", "country_code": "ba", "offset": 3600 },
    { "id": "Africa/Gaborone", "name": "Botswana/Gaborone (GMT +02:00)", "country_code": "bw", "offset": 7200 },
    { "id": "America/Araguaina", "name": "Brazil/Araguaina (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Bahia", "name": "Brazil/Bahia (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Belem", "name": "Brazil/Belem (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Boa_Vista", "name": "Brazil/Boa_Vista (GMT -04:00)", "country_code": "br", "offset": -14400 },
    { "id": "America/Campo_Grande", "name": "Brazil/Campo_Grande (GMT -04:00)", "country_code": "br", "offset": -14400 },
    { "id": "America/Cuiaba", "name": "Brazil/Cuiaba (GMT -04:00)", "country_code": "br", "offset": -14400 },
    { "id": "America/Eirunepe", "name": "Brazil/Eirunepe (GMT -05:00)", "country_code": "br", "offset": -18000 },
    { "id": "America/Fortaleza", "name": "Brazil/Fortaleza (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Maceio", "name": "Brazil/Maceio (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Manaus", "name": "Brazil/Manaus (GMT -04:00)", "country_code": "br", "offset": -14400 },
    { "id": "America/Noronha", "name": "Brazil/Noronha (GMT -02:00)", "country_code": "br", "offset": -7200 },
    { "id": "America/Porto_Velho", "name": "Brazil/Porto_Velho (GMT -04:00)", "country_code": "br", "offset": -14400 },
    { "id": "America/Recife", "name": "Brazil/Recife (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Rio_Branco", "name": "Brazil/Rio_Branco (GMT -05:00)", "country_code": "br", "offset": -18000 },
    { "id": "America/Santarem", "name": "Brazil/Santarem (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "America/Sao_Paulo", "name": "Brazil/Sao_Paulo (GMT -03:00)", "country_code": "br", "offset": -10800 },
    { "id": "Indian/Chagos", "name": "British Indian Ocean Territory/Chagos (GMT +06:00)", "country_code": "io", "offset": 21600 },
    { "id": "Asia/Brunei", "name": "Brunei Darussalam/Brunei (GMT +08:00)", "country_code": "bn", "offset": 28800 },
    { "id": "Europe/Sofia", "name": "Bulgaria/Sofia (GMT +02:00)", "country_code": "bg", "offset": 7200 },
    { "id": "Africa/Ouagadougou", "name": "Burkina Faso/Ouagadougou (GMT +00:00)", "country_code": "bf", "offset": 0 },
    { "id": "Africa/Bujumbura", "name": "Burundi/Bujumbura (GMT +02:00)", "country_code": "bi", "offset": 7200 },
    { "id": "Asia/Phnom_Penh", "name": "Cambodia/Phnom_Penh (GMT +07:00)", "country_code": "kh", "offset": 25200 },
    { "id": "Africa/Douala", "name": "Cameroon/Douala (GMT +01:00)", "country_code": "cm", "offset": 3600 },
    { "id": "America/Atikokan", "name": "Canada/Atikokan (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Blanc-Sablon", "name": "Canada/Blanc-Sablon (GMT -04:00)", "country_code": "ca", "offset": -14400 },
    { "id": "America/Cambridge_Bay", "name": "Canada/Cambridge_Bay (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Creston", "name": "Canada/Creston (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Dawson", "name": "Canada/Dawson (GMT -08:00)", "country_code": "ca", "offset": -28800 },
    { "id": "America/Dawson_Creek", "name": "Canada/Dawson_Creek (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Edmonton", "name": "Canada/Edmonton (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Fort_Nelson", "name": "Canada/Fort_Nelson (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Glace_Bay", "name": "Canada/Glace_Bay (GMT -04:00)", "country_code": "ca", "offset": -14400 },
    { "id": "America/Goose_Bay", "name": "Canada/Goose_Bay (GMT -04:00)", "country_code": "ca", "offset": -14400 },
    { "id": "America/Halifax", "name": "Canada/Halifax (GMT -04:00)", "country_code": "ca", "offset": -14400 },
    { "id": "America/Inuvik", "name": "Canada/Inuvik (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "America/Iqaluit", "name": "Canada/Iqaluit (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Moncton", "name": "Canada/Moncton (GMT -04:00)", "country_code": "ca", "offset": -14400 },
    { "id": "America/Nipigon", "name": "Canada/Nipigon (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Pangnirtung", "name": "Canada/Pangnirtung (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Rainy_River", "name": "Canada/Rainy_River (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/Rankin_Inlet", "name": "Canada/Rankin_Inlet (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/Regina", "name": "Canada/Regina (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/Resolute", "name": "Canada/Resolute (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/St_Johns", "name": "Canada/St_Johns (GMT -03:30)", "country_code": "ca", "offset": -12600 },
    { "id": "America/Swift_Current", "name": "Canada/Swift_Current (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/Thunder_Bay", "name": "Canada/Thunder_Bay (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Toronto", "name": "Canada/Toronto (GMT -05:00)", "country_code": "ca", "offset": -18000 },
    { "id": "America/Vancouver", "name": "Canada/Vancouver (GMT -08:00)", "country_code": "ca", "offset": -28800 },
    { "id": "America/Whitehorse", "name": "Canada/Whitehorse (GMT -08:00)", "country_code": "ca", "offset": -28800 },
    { "id": "America/Winnipeg", "name": "Canada/Winnipeg (GMT -06:00)", "country_code": "ca", "offset": -21600 },
    { "id": "America/Yellowknife", "name": "Canada/Yellowknife (GMT -07:00)", "country_code": "ca", "offset": -25200 },
    { "id": "Atlantic/Cape_Verde", "name": "Cape Verde/Cape_Verde (GMT -01:00)", "country_code": "cv", "offset": -3600 },
    { "id": "America/Cayman", "name": "Cayman Islands/Cayman (GMT -05:00)", "country_code": "ky", "offset": -18000 },
    { "id": "Africa/Bangui", "name": "Central African Republic/Bangui (GMT +01:00)", "country_code": "cf", "offset": 3600 },
    { "id": "Africa/Ndjamena", "name": "Chad/Ndjamena (GMT +01:00)", "country_code": "td", "offset": 3600 },
    { "id": "Pacific/Easter", "name": "Chile/Easter (GMT -05:00)", "country_code": "cl", "offset": -18000 },
    { "id": "America/Punta_Arenas", "name": "Chile/Punta_Arenas (GMT -03:00)", "country_code": "cl", "offset": -10800 },
    { "id": "America/Santiago", "name": "Chile/Santiago (GMT -03:00)", "country_code": "cl", "offset": -10800 },
    { "id": "Asia/Shanghai", "name": "China/Shanghai (GMT +08:00)", "country_code": "cn", "offset": 28800 },
    { "id": "Asia/Urumqi", "name": "China/Urumqi (GMT +06:00)", "country_code": "cn", "offset": 21600 },
    { "id": "Indian/Christmas", "name": "Christmas Island/Christmas (GMT +07:00)", "country_code": "cx", "offset": 25200 },
    { "id": "Indian/Cocos", "name": "Cocos (Keeling) Islands/Cocos (GMT +06:30)", "country_code": "cc", "offset": 23400 },
    { "id": "America/Bogota", "name": "Colombia/Bogota (GMT -05:00)", "country_code": "co", "offset": -18000 },
    { "id": "Indian/Comoro", "name": "Comoros/Comoro (GMT +03:00)", "country_code": "km", "offset": 10800 },
    { "id": "Africa/Kinshasa", "name": "Congo, The Democratic Republic of the/Kinshasa (GMT +01:00)", "country_code": "cd", "offset": 3600 },
    { "id": "Africa/Lubumbashi", "name": "Congo, The Democratic Republic of the/Lubumbashi (GMT +02:00)", "country_code": "cd", "offset": 7200 },
    { "id": "Africa/Brazzaville", "name": "Congo/Brazzaville (GMT +01:00)", "country_code": "cg", "offset": 3600 },
    { "id": "Pacific/Rarotonga", "name": "Cook Islands/Rarotonga (GMT -10:00)", "country_code": "ck", "offset": -36000 },
    { "id": "America/Costa_Rica", "name": "Costa Rica/Costa_Rica (GMT -06:00)", "country_code": "cr", "offset": -21600 },
    { "id": "Africa/Abidjan", "name": "Cote D'Ivoire/Abidjan (GMT +00:00)", "country_code": "ci", "offset": 0 },
    { "id": "Europe/Zagreb", "name": "Croatia/Zagreb (GMT +01:00)", "country_code": "hr", "offset": 3600 },
    { "id": "America/Havana", "name": "Cuba/Havana (GMT -05:00)", "country_code": "cu", "offset": -18000 },
    { "id": "Asia/Famagusta", "name": "Cyprus/Famagusta (GMT +02:00)", "country_code": "cy", "offset": 7200 },
    { "id": "Asia/Nicosia", "name": "Cyprus/Nicosia (GMT +02:00)", "country_code": "cy", "offset": 7200 },
    { "id": "Europe/Prague", "name": "Czech Republic/Prague (GMT +01:00)", "country_code": "cz", "offset": 3600 },
    { "id": "Europe/Copenhagen", "name": "Denmark/Copenhagen (GMT +01:00)", "country_code": "dk", "offset": 3600 },
    { "id": "Africa/Djibouti", "name": "Djibouti/Djibouti (GMT +03:00)", "country_code": "dj", "offset": 10800 },
    { "id": "America/Dominica", "name": "Dominica/Dominica (GMT -04:00)", "country_code": "dm", "offset": -14400 },
    { "id": "America/Santo_Domingo", "name": "Dominican Republic/Santo_Domingo (GMT -04:00)", "country_code": "do", "offset": -14400 },
    { "id": "Pacific/Galapagos", "name": "Ecuador/Galapagos (GMT -06:00)", "country_code": "ec", "offset": -21600 },
    { "id": "America/Guayaquil", "name": "Ecuador/Guayaquil (GMT -05:00)", "country_code": "ec", "offset": -18000 },
    { "id": "Africa/Cairo", "name": "Egypt/Cairo (GMT +02:00)", "country_code": "eg", "offset": 7200 },
    { "id": "America/El_Salvador", "name": "El Salvador/El_Salvador (GMT -06:00)", "country_code": "sv", "offset": -21600 },
    { "id": "Africa/Malabo", "name": "Equatorial Guinea/Malabo (GMT +01:00)", "country_code": "gq", "offset": 3600 },
    { "id": "Africa/Asmara", "name": "Eritrea/Asmara (GMT +03:00)", "country_code": "er", "offset": 10800 },
    { "id": "Europe/Tallinn", "name": "Estonia/Tallinn (GMT +02:00)", "country_code": "ee", "offset": 7200 },
    { "id": "Africa/Addis_Ababa", "name": "Ethiopia/Addis_Ababa (GMT +03:00)", "country_code": "et", "offset": 10800 },
    { "id": "Europe/Belgrade", "name": "Europe/Belgrade (GMT +01:00)", "country_code": "rs", "offset": 3600 },
    { "id": "Europe/Podgorica", "name": "Europe/Podgorica (GMT +01:00)", "country_code": "me", "offset": 3600 },
    { "id": "Atlantic/Stanley", "name": "Falkland Islands (Malvinas)/Stanley (GMT -03:00)", "country_code": "fk", "offset": -10800 },
    { "id": "Atlantic/Faroe", "name": "Faroe Islands/Faroe (GMT +00:00)", "country_code": "fo", "offset": 0 },
    { "id": "Pacific/Fiji", "name": "Fiji/Fiji (GMT +12:00)", "country_code": "fj", "offset": 43200 },
    { "id": "Europe/Helsinki", "name": "Finland/Helsinki (GMT +02:00)", "country_code": "fi", "offset": 7200 },
    { "id": "Europe/Paris", "name": "France/Paris (GMT +01:00)", "country_code": "fr", "offset": 3600 },
    { "id": "America/Cayenne", "name": "French Guiana/Cayenne (GMT -03:00)", "country_code": "gf", "offset": -10800 },
    { "id": "Pacific/Gambier", "name": "French Polynesia/Gambier (GMT -09:00)", "country_code": "pf", "offset": -32400 },
    { "id": "Pacific/Marquesas", "name": "French Polynesia/Marquesas (GMT -09:30)", "country_code": "pf", "offset": -34200 },
    { "id": "Pacific/Tahiti", "name": "French Polynesia/Tahiti (GMT -10:00)", "country_code": "pf", "offset": -36000 },
    { "id": "Indian/Kerguelen", "name": "French Southern Territories/Kerguelen (GMT +05:00)", "country_code": "tf", "offset": 18000 },
    { "id": "Africa/Libreville", "name": "Gabon/Libreville (GMT +01:00)", "country_code": "ga", "offset": 3600 },
    { "id": "Africa/Banjul", "name": "Gambia/Banjul (GMT +00:00)", "country_code": "gm", "offset": 0 },
    { "id": "Asia/Tbilisi", "name": "Georgia/Tbilisi (GMT +04:00)", "country_code": "ge", "offset": 14400 },
    { "id": "Europe/Berlin", "name": "Germany/Berlin (GMT +01:00)", "country_code": "de", "offset": 3600 },
    { "id": "Europe/Busingen", "name": "Germany/Busingen (GMT +01:00)", "country_code": "de", "offset": 3600 },
    { "id": "Africa/Accra", "name": "Ghana/Accra (GMT +00:00)", "country_code": "gh", "offset": 0 },
    { "id": "Europe/Gibraltar", "name": "Gibraltar/Gibraltar (GMT +01:00)", "country_code": "gi", "offset": 3600 },
    { "id": "Europe/Athens", "name": "Greece/Athens (GMT +02:00)", "country_code": "gr", "offset": 7200 },
    { "id": "America/Danmarkshavn", "name": "Greenland/Danmarkshavn (GMT +00:00)", "country_code": "gl", "offset": 0 },
    { "id": "America/Godthab", "name": "Greenland/Godthab (GMT -03:00)", "country_code": "gl", "offset": -10800 },
    { "id": "America/Scoresbysund", "name": "Greenland/Scoresbysund (GMT -01:00)", "country_code": "gl", "offset": -3600 },
    { "id": "America/Thule", "name": "Greenland/Thule (GMT -04:00)", "country_code": "gl", "offset": -14400 },
    { "id": "America/Grenada", "name": "Grenada/Grenada (GMT -04:00)", "country_code": "gd", "offset": -14400 },
    { "id": "America/Guadeloupe", "name": "Guadeloupe/Guadeloupe (GMT -04:00)", "country_code": "gp", "offset": -14400 },
    { "id": "Pacific/Guam", "name": "Guam/Guam (GMT +10:00)", "country_code": "gu", "offset": 36000 },
    { "id": "America/Guatemala", "name": "Guatemala/Guatemala (GMT -06:00)", "country_code": "gt", "offset": -21600 },
    { "id": "Europe/Guernsey", "name": "Guernsey/Guernsey (GMT +00:00)", "country_code": "gg", "offset": 0 },
    { "id": "Africa/Bissau", "name": "Guinea-Bissau/Bissau (GMT +00:00)", "country_code": "gw", "offset": 0 },
    { "id": "Africa/Conakry", "name": "Guinea/Conakry (GMT +00:00)", "country_code": "gn", "offset": 0 },
    { "id": "America/Guyana", "name": "Guyana/Guyana (GMT -04:00)", "country_code": "gy", "offset": -14400 },
    { "id": "America/Port-au-Prince", "name": "Haiti/Port-au-Prince (GMT -05:00)", "country_code": "ht", "offset": -18000 },
    { "id": "Europe/Vatican", "name": "Holy See (Vatican City State)/Vatican (GMT +01:00)", "country_code": "va", "offset": 3600 },
    { "id": "America/Tegucigalpa", "name": "Honduras/Tegucigalpa (GMT -06:00)", "country_code": "hn", "offset": -21600 },
    { "id": "Asia/Hong_Kong", "name": "Hong Kong/Hong_Kong (GMT +08:00)", "country_code": "hk", "offset": 28800 },
    { "id": "Europe/Budapest", "name": "Hungary/Budapest (GMT +01:00)", "country_code": "hu", "offset": 3600 },
    { "id": "Atlantic/Reykjavik", "name": "Iceland/Reykjavik (GMT +00:00)", "country_code": "is", "offset": 0 },
    { "id": "Asia/Kolkata", "name": "India/Kolkata (GMT +05:30)", "country_code": "in", "offset": 19800 },
    { "id": "Asia/Jakarta", "name": "Indonesia/Jakarta (GMT +07:00)", "country_code": "id", "offset": 25200 },
    { "id": "Asia/Jayapura", "name": "Indonesia/Jayapura (GMT +09:00)", "country_code": "id", "offset": 32400 },
    { "id": "Asia/Makassar", "name": "Indonesia/Makassar (GMT +08:00)", "country_code": "id", "offset": 28800 },
    { "id": "Asia/Pontianak", "name": "Indonesia/Pontianak (GMT +07:00)", "country_code": "id", "offset": 25200 },
    { "id": "Asia/Tehran", "name": "Iran, Islamic Republic Of/Tehran (GMT +03:30)", "country_code": "ir", "offset": 12600 },
    { "id": "Asia/Baghdad", "name": "Iraq/Baghdad (GMT +03:00)", "country_code": "iq", "offset": 10800 },
    { "id": "Europe/Dublin", "name": "Ireland/Dublin (GMT +00:00)", "country_code": "ie", "offset": 0 },
    { "id": "Europe/Isle_of_Man", "name": "Isle of Man/Isle_of_Man (GMT +00:00)", "country_code": "im", "offset": 0 },
    { "id": "Asia/Jerusalem", "name": "Israel/Jerusalem (GMT +02:00)", "country_code": "il", "offset": 7200 },
    { "id": "Europe/Rome", "name": "Italy/Rome (GMT +01:00)", "country_code": "it", "offset": 3600 },
    { "id": "America/Jamaica", "name": "Jamaica/Jamaica (GMT -05:00)", "country_code": "jm", "offset": -18000 },
    { "id": "Asia/Tokyo", "name": "Japan/Tokyo (GMT +09:00)", "country_code": "jp", "offset": 32400 },
    { "id": "Europe/Jersey", "name": "Jersey/Jersey (GMT +00:00)", "country_code": "je", "offset": 0 },
    { "id": "Asia/Amman", "name": "Jordan/Amman (GMT +02:00)", "country_code": "jo", "offset": 7200 },
    { "id": "Asia/Almaty", "name": "Kazakhstan/Almaty (GMT +06:00)", "country_code": "kz", "offset": 21600 },
    { "id": "Asia/Aqtau", "name": "Kazakhstan/Aqtau (GMT +05:00)", "country_code": "kz", "offset": 18000 },
    { "id": "Asia/Aqtobe", "name": "Kazakhstan/Aqtobe (GMT +05:00)", "country_code": "kz", "offset": 18000 },
    { "id": "Asia/Atyrau", "name": "Kazakhstan/Atyrau (GMT +05:00)", "country_code": "kz", "offset": 18000 },
    { "id": "Asia/Oral", "name": "Kazakhstan/Oral (GMT +05:00)", "country_code": "kz", "offset": 18000 },
    { "id": "Asia/Qostanay", "name": "Kazakhstan/Qostanay (GMT +06:00)", "country_code": "kz", "offset": 21600 },
    { "id": "Asia/Qyzylorda", "name": "Kazakhstan/Qyzylorda (GMT +05:00)", "country_code": "kz", "offset": 18000 },
    { "id": "Africa/Nairobi", "name": "Kenya/Nairobi (GMT +03:00)", "country_code": "ke", "offset": 10800 },
    { "id": "Pacific/Enderbury", "name": "Kiribati/Enderbury (GMT +13:00)", "country_code": "ki", "offset": 46800 },
    { "id": "Pacific/Kiritimati", "name": "Kiribati/Kiritimati (GMT +14:00)", "country_code": "ki", "offset": 50400 },
    { "id": "Pacific/Tarawa", "name": "Kiribati/Tarawa (GMT +12:00)", "country_code": "ki", "offset": 43200 },
    { "id": "Asia/Pyongyang", "name": "Korea, Democratic People'S Republic of/Pyongyang (GMT +09:00)", "country_code": "kp", "offset": 32400 },
    { "id": "Asia/Seoul", "name": "Korea, Republic of/Seoul (GMT +09:00)", "country_code": "kr", "offset": 32400 },
    { "id": "Asia/Kuwait", "name": "Kuwait/Kuwait (GMT +03:00)", "country_code": "kw", "offset": 10800 },
    { "id": "Asia/Bishkek", "name": "Kyrgyzstan/Bishkek (GMT +06:00)", "country_code": "kg", "offset": 21600 },
    { "id": "Asia/Vientiane", "name": "Lao People'S Democratic Republic/Vientiane (GMT +07:00)", "country_code": "la", "offset": 25200 },
    { "id": "Europe/Riga", "name": "Latvia/Riga (GMT +02:00)", "country_code": "lv", "offset": 7200 },
    { "id": "Asia/Beirut", "name": "Lebanon/Beirut (GMT +02:00)", "country_code": "lb", "offset": 7200 },
    { "id": "Africa/Maseru", "name": "Lesotho/Maseru (GMT +02:00)", "country_code": "ls", "offset": 7200 },
    { "id": "Africa/Monrovia", "name": "Liberia/Monrovia (GMT +00:00)", "country_code": "lr", "offset": 0 },
    { "id": "Africa/Tripoli", "name": "Libyan Arab Jamahiriya/Tripoli (GMT +02:00)", "country_code": "ly", "offset": 7200 },
    { "id": "Europe/Vaduz", "name": "Liechtenstein/Vaduz (GMT +01:00)", "country_code": "li", "offset": 3600 },
    { "id": "Europe/Vilnius", "name": "Lithuania/Vilnius (GMT +02:00)", "country_code": "lt", "offset": 7200 },
    { "id": "Europe/Luxembourg", "name": "Luxembourg/Luxembourg (GMT +01:00)", "country_code": "lu", "offset": 3600 },
    { "id": "Asia/Macau", "name": "Macao/Macau (GMT +08:00)", "country_code": "mo", "offset": 28800 },
    { "id": "Europe/Skopje", "name": "Macedonia, The Former Yugoslav Republic of/Skopje (GMT +01:00)", "country_code": "mk", "offset": 3600 },
    { "id": "Indian/Antananarivo", "name": "Madagascar/Antananarivo (GMT +03:00)", "country_code": "mg", "offset": 10800 },
    { "id": "Africa/Blantyre", "name": "Malawi/Blantyre (GMT +02:00)", "country_code": "mw", "offset": 7200 },
    { "id": "Asia/Kuala_Lumpur", "name": "Malaysia/Kuala_Lumpur (GMT +08:00)", "country_code": "my", "offset": 28800 },
    { "id": "Asia/Kuching", "name": "Malaysia/Kuching (GMT +08:00)", "country_code": "my", "offset": 28800 },
    { "id": "Indian/Maldives", "name": "Maldives/Maldives (GMT +05:00)", "country_code": "mv", "offset": 18000 },
    { "id": "Africa/Bamako", "name": "Mali/Bamako (GMT +00:00)", "country_code": "ml", "offset": 0 },
    { "id": "Europe/Malta", "name": "Malta/Malta (GMT +01:00)", "country_code": "mt", "offset": 3600 },
    { "id": "Pacific/Kwajalein", "name": "Marshall Islands/Kwajalein (GMT +12:00)", "country_code": "mh", "offset": 43200 },
    { "id": "Pacific/Majuro", "name": "Marshall Islands/Majuro (GMT +12:00)", "country_code": "mh", "offset": 43200 },
    { "id": "America/Martinique", "name": "Martinique/Martinique (GMT -04:00)", "country_code": "mq", "offset": -14400 },
    { "id": "Africa/Nouakchott", "name": "Mauritania/Nouakchott (GMT +00:00)", "country_code": "mr", "offset": 0 },
    { "id": "Indian/Mauritius", "name": "Mauritius/Mauritius (GMT +04:00)", "country_code": "mu", "offset": 14400 },
    { "id": "Indian/Mayotte", "name": "Mayotte/Mayotte (GMT +03:00)", "country_code": "yt", "offset": 10800 },
    { "id": "America/Bahia_Banderas", "name": "Mexico/Bahia_Banderas (GMT -06:00)", "country_code": "mx", "offset": -21600 },
    { "id": "America/Cancun", "name": "Mexico/Cancun (GMT -05:00)", "country_code": "mx", "offset": -18000 },
    { "id": "America/Chihuahua", "name": "Mexico/Chihuahua (GMT -07:00)", "country_code": "mx", "offset": -25200 },
    { "id": "America/Hermosillo", "name": "Mexico/Hermosillo (GMT -07:00)", "country_code": "mx", "offset": -25200 },
    { "id": "America/Matamoros", "name": "Mexico/Matamoros (GMT -06:00)", "country_code": "mx", "offset": -21600 },
    { "id": "America/Mazatlan", "name": "Mexico/Mazatlan (GMT -07:00)", "country_code": "mx", "offset": -25200 },
    { "id": "America/Merida", "name": "Mexico/Merida (GMT -06:00)", "country_code": "mx", "offset": -21600 },
    { "id": "America/Mexico_City", "name": "Mexico/Mexico_City (GMT -06:00)", "country_code": "mx", "offset": -21600 },
    { "id": "America/Monterrey", "name": "Mexico/Monterrey (GMT -06:00)", "country_code": "mx", "offset": -21600 },
    { "id": "America/Ojinaga", "name": "Mexico/Ojinaga (GMT -07:00)", "country_code": "mx", "offset": -25200 },
    { "id": "America/Tijuana", "name": "Mexico/Tijuana (GMT -08:00)", "country_code": "mx", "offset": -28800 },
    { "id": "Pacific/Chuuk", "name": "Micronesia, Federated States of/Chuuk (GMT +10:00)", "country_code": "fm", "offset": 36000 },
    { "id": "Pacific/Kosrae", "name": "Micronesia, Federated States of/Kosrae (GMT +11:00)", "country_code": "fm", "offset": 39600 },
    { "id": "Pacific/Pohnpei", "name": "Micronesia, Federated States of/Pohnpei (GMT +11:00)", "country_code": "fm", "offset": 39600 },
    { "id": "Europe/Chisinau", "name": "Moldova, Republic of/Chisinau (GMT +02:00)", "country_code": "md", "offset": 7200 },
    { "id": "Europe/Monaco", "name": "Monaco/Monaco (GMT +01:00)", "country_code": "mc", "offset": 3600 },
    { "id": "Asia/Choibalsan", "name": "Mongolia/Choibalsan (GMT +08:00)", "country_code": "mn", "offset": 28800 },
    { "id": "Asia/Hovd", "name": "Mongolia/Hovd (GMT +07:00)", "country_code": "mn", "offset": 25200 },
    { "id": "Asia/Ulaanbaatar", "name": "Mongolia/Ulaanbaatar (GMT +08:00)", "country_code": "mn", "offset": 28800 },
    { "id": "America/Montserrat", "name": "Montserrat/Montserrat (GMT -04:00)", "country_code": "ms", "offset": -14400 },
    { "id": "Africa/Casablanca", "name": "Morocco/Casablanca (GMT +01:00)", "country_code": "ma", "offset": 3600 },
    { "id": "Africa/Maputo", "name": "Mozambique/Maputo (GMT +02:00)", "country_code": "mz", "offset": 7200 },
    { "id": "Asia/Yangon", "name": "Myanmar/Yangon (GMT +06:30)", "country_code": "mm", "offset": 23400 },
    { "id": "Africa/Windhoek", "name": "Namibia/Windhoek (GMT +02:00)", "country_code": "na", "offset": 7200 },
    { "id": "Pacific/Nauru", "name": "Nauru/Nauru (GMT +12:00)", "country_code": "nr", "offset": 43200 },
    { "id": "Asia/Kathmandu", "name": "Nepal/Kathmandu (GMT +05:45)", "country_code": "np", "offset": 20700 },
    { "id": "Europe/Amsterdam", "name": "Netherlands/Amsterdam (GMT +01:00)", "country_code": "nl", "offset": 3600 },
    { "id": "Pacific/Noumea", "name": "New Caledonia/Noumea (GMT +11:00)", "country_code": "nc", "offset": 39600 },
    { "id": "Pacific/Auckland", "name": "New Zealand/Auckland (GMT +13:00)", "country_code": "nz", "offset": 46800 },
    { "id": "Pacific/Chatham", "name": "New Zealand/Chatham (GMT +13:45)", "country_code": "nz", "offset": 49500 },
    { "id": "America/Managua", "name": "Nicaragua/Managua (GMT -06:00)", "country_code": "ni", "offset": -21600 },
    { "id": "Africa/Niamey", "name": "Niger/Niamey (GMT +01:00)", "country_code": "ne", "offset": 3600 },
    { "id": "Africa/Lagos", "name": "Nigeria/Lagos (GMT +01:00)", "country_code": "ng", "offset": 3600 },
    { "id": "Pacific/Niue", "name": "Niue/Niue (GMT -11:00)", "country_code": "nu", "offset": -39600 },
    { "id": "Pacific/Norfolk", "name": "Norfolk Island/Norfolk (GMT +12:00)", "country_code": "nf", "offset": 43200 },
    { "id": "Pacific/Saipan", "name": "Northern Mariana Islands/Saipan (GMT +10:00)", "country_code": "mp", "offset": 36000 },
    { "id": "Europe/Oslo", "name": "Norway/Oslo (GMT +01:00)", "country_code": "no", "offset": 3600 },
    { "id": "Asia/Muscat", "name": "Oman/Muscat (GMT +04:00)", "country_code": "om", "offset": 14400 },
    { "id": "Asia/Karachi", "name": "Pakistan/Karachi (GMT +05:00)", "country_code": "pk", "offset": 18000 },
    { "id": "Pacific/Palau", "name": "Palau/Palau (GMT +09:00)", "country_code": "pw", "offset": 32400 },
    { "id": "Asia/Gaza", "name": "Palestinian Territory, Occupied/Gaza (GMT +02:00)", "country_code": "ps", "offset": 7200 },
    { "id": "Asia/Hebron", "name": "Palestinian Territory, Occupied/Hebron (GMT +02:00)", "country_code": "ps", "offset": 7200 },
    { "id": "America/Panama", "name": "Panama/Panama (GMT -05:00)", "country_code": "pa", "offset": -18000 },
    { "id": "Pacific/Bougainville", "name": "Papua New Guinea/Bougainville (GMT +11:00)", "country_code": "pg", "offset": 39600 },
    { "id": "Pacific/Port_Moresby", "name": "Papua New Guinea/Port_Moresby (GMT +10:00)", "country_code": "pg", "offset": 36000 },
    { "id": "America/Asuncion", "name": "Paraguay/Asuncion (GMT -03:00)", "country_code": "py", "offset": -10800 },
    { "id": "America/Lima", "name": "Peru/Lima (GMT -05:00)", "country_code": "pe", "offset": -18000 },
    { "id": "Asia/Manila", "name": "Philippines/Manila (GMT +08:00)", "country_code": "ph", "offset": 28800 },
    { "id": "Pacific/Pitcairn", "name": "Pitcairn/Pitcairn (GMT -08:00)", "country_code": "pn", "offset": -28800 },
    { "id": "Europe/Warsaw", "name": "Poland/Warsaw (GMT +01:00)", "country_code": "pl", "offset": 3600 },
    { "id": "Atlantic/Azores", "name": "Portugal/Azores (GMT -01:00)", "country_code": "pt", "offset": -3600 },
    { "id": "Europe/Lisbon", "name": "Portugal/Lisbon (GMT +00:00)", "country_code": "pt", "offset": 0 },
    { "id": "Atlantic/Madeira", "name": "Portugal/Madeira (GMT +00:00)", "country_code": "pt", "offset": 0 },
    { "id": "America/Puerto_Rico", "name": "Puerto Rico/Puerto_Rico (GMT -04:00)", "country_code": "pr", "offset": -14400 },
    { "id": "Asia/Qatar", "name": "Qatar/Qatar (GMT +03:00)", "country_code": "qa", "offset": 10800 },
    { "id": "Africa/Kigali", "name": "RWANDA/Kigali (GMT +02:00)", "country_code": "rw", "offset": 7200 },
    { "id": "Indian/Reunion", "name": "Reunion/Reunion (GMT +04:00)", "country_code": "re", "offset": 14400 },
    { "id": "Europe/Bucharest", "name": "Romania/Bucharest (GMT +02:00)", "country_code": "ro", "offset": 7200 },
    { "id": "Asia/Anadyr", "name": "Russian Federation/Anadyr (GMT +12:00)", "country_code": "ru", "offset": 43200 },
    { "id": "Europe/Astrakhan", "name": "Russian Federation/Astrakhan (GMT +04:00)", "country_code": "ru", "offset": 14400 },
    { "id": "Asia/Barnaul", "name": "Russian Federation/Barnaul (GMT +07:00)", "country_code": "ru", "offset": 25200 },
    { "id": "Asia/Chita", "name": "Russian Federation/Chita (GMT +09:00)", "country_code": "ru", "offset": 32400 },
    { "id": "Asia/Irkutsk", "name": "Russian Federation/Irkutsk (GMT +08:00)", "country_code": "ru", "offset": 28800 },
    { "id": "Europe/Kaliningrad", "name": "Russian Federation/Kaliningrad (GMT +02:00)", "country_code": "ru", "offset": 7200 },
    { "id": "Asia/Kamchatka", "name": "Russian Federation/Kamchatka (GMT +12:00)", "country_code": "ru", "offset": 43200 },
    { "id": "Asia/Khandyga", "name": "Russian Federation/Khandyga (GMT +09:00)", "country_code": "ru", "offset": 32400 },
    { "id": "Europe/Kirov", "name": "Russian Federation/Kirov (GMT +03:00)", "country_code": "ru", "offset": 10800 },
    { "id": "Asia/Krasnoyarsk", "name": "Russian Federation/Krasnoyarsk (GMT +07:00)", "country_code": "ru", "offset": 25200 },
    { "id": "Asia/Magadan", "name": "Russian Federation/Magadan (GMT +11:00)", "country_code": "ru", "offset": 39600 },
    { "id": "Europe/Moscow", "name": "Russian Federation/Moscow (GMT +03:00)", "country_code": "ru", "offset": 10800 },
    { "id": "Asia/Novokuznetsk", "name": "Russian Federation/Novokuznetsk (GMT +07:00)", "country_code": "ru", "offset": 25200 },
    { "id": "Asia/Novosibirsk", "name": "Russian Federation/Novosibirsk (GMT +07:00)", "country_code": "ru", "offset": 25200 },
    { "id": "Asia/Omsk", "name": "Russian Federation/Omsk (GMT +06:00)", "country_code": "ru", "offset": 21600 },
    { "id": "Asia/Sakhalin", "name": "Russian Federation/Sakhalin (GMT +11:00)", "country_code": "ru", "offset": 39600 },
    { "id": "Europe/Samara", "name": "Russian Federation/Samara (GMT +04:00)", "country_code": "ru", "offset": 14400 },
    { "id": "Europe/Saratov", "name": "Russian Federation/Saratov (GMT +04:00)", "country_code": "ru", "offset": 14400 },
    { "id": "Asia/Srednekolymsk", "name": "Russian Federation/Srednekolymsk (GMT +11:00)", "country_code": "ru", "offset": 39600 },
    { "id": "Asia/Tomsk", "name": "Russian Federation/Tomsk (GMT +07:00)", "country_code": "ru", "offset": 25200 },
    { "id": "Europe/Ulyanovsk", "name": "Russian Federation/Ulyanovsk (GMT +04:00)", "country_code": "ru", "offset": 14400 },
    { "id": "Asia/Ust-Nera", "name": "Russian Federation/Ust-Nera (GMT +10:00)", "country_code": "ru", "offset": 36000 },
    { "id": "Asia/Vladivostok", "name": "Russian Federation/Vladivostok (GMT +10:00)", "country_code": "ru", "offset": 36000 },
    { "id": "Europe/Volgograd", "name": "Russian Federation/Volgograd (GMT +04:00)", "country_code": "ru", "offset": 14400 },
    { "id": "Asia/Yakutsk", "name": "Russian Federation/Yakutsk (GMT +09:00)", "country_code": "ru", "offset": 32400 },
    { "id": "Asia/Yekaterinburg", "name": "Russian Federation/Yekaterinburg (GMT +05:00)", "country_code": "ru", "offset": 18000 },
    { "id": "Atlantic/St_Helena", "name": "Saint Helena/St_Helena (GMT +00:00)", "country_code": "sh", "offset": 0 },
    { "id": "America/St_Kitts", "name": "Saint Kitts and Nevis/St_Kitts (GMT -04:00)", "country_code": "kn", "offset": -14400 },
    { "id": "America/St_Lucia", "name": "Saint Lucia/St_Lucia (GMT -04:00)", "country_code": "lc", "offset": -14400 },
    { "id": "America/Miquelon", "name": "Saint Pierre and Miquelon/Miquelon (GMT -03:00)", "country_code": "pm", "offset": -10800 },
    { "id": "America/St_Vincent", "name": "Saint Vincent and the Grenadines/St_Vincent (GMT -04:00)", "country_code": "vc", "offset": -14400 },
    { "id": "Pacific/Apia", "name": "Samoa/Apia (GMT +14:00)", "country_code": "ws", "offset": 50400 },
    { "id": "Europe/San_Marino", "name": "San Marino/San_Marino (GMT +01:00)", "country_code": "sm", "offset": 3600 },
    { "id": "Africa/Sao_Tome", "name": "Sao Tome and Principe/Sao_Tome (GMT +00:00)", "country_code": "st", "offset": 0 },
    { "id": "Asia/Riyadh", "name": "Saudi Arabia/Riyadh (GMT +03:00)", "country_code": "sa", "offset": 10800 },
    { "id": "Africa/Dakar", "name": "Senegal/Dakar (GMT +00:00)", "country_code": "sn", "offset": 0 },
    { "id": "Indian/Mahe", "name": "Seychelles/Mahe (GMT +04:00)", "country_code": "sc", "offset": 14400 },
    { "id": "Africa/Freetown", "name": "Sierra Leone/Freetown (GMT +00:00)", "country_code": "sl", "offset": 0 },
    { "id": "Asia/Singapore", "name": "Singapore/Singapore (GMT +08:00)", "country_code": "sg", "offset": 28800 },
    { "id": "Europe/Bratislava", "name": "Slovakia/Bratislava (GMT +01:00)", "country_code": "sk", "offset": 3600 },
    { "id": "Europe/Ljubljana", "name": "Slovenia/Ljubljana (GMT +01:00)", "country_code": "si", "offset": 3600 },
    { "id": "Pacific/Guadalcanal", "name": "Solomon Islands/Guadalcanal (GMT +11:00)", "country_code": "sb", "offset": 39600 },
    { "id": "Africa/Mogadishu", "name": "Somalia/Mogadishu (GMT +03:00)", "country_code": "so", "offset": 10800 },
    { "id": "Africa/Johannesburg", "name": "South Africa/Johannesburg (GMT +02:00)", "country_code": "za", "offset": 7200 },
    { "id": "Atlantic/South_Georgia", "name": "South Georgia and the South Sandwich Islands/South_Georgia (GMT -02:00)", "country_code": "gs", "offset": -7200 },
    { "id": "Atlantic/Canary", "name": "Spain/Canary (GMT +00:00)", "country_code": "es", "offset": 0 },
    { "id": "Africa/Ceuta", "name": "Spain/Ceuta (GMT +01:00)", "country_code": "es", "offset": 3600 },
    { "id": "Europe/Madrid", "name": "Spain/Madrid (GMT +01:00)", "country_code": "es", "offset": 3600 },
    { "id": "Asia/Colombo", "name": "Sri Lanka/Colombo (GMT +05:30)", "country_code": "lk", "offset": 19800 },
    { "id": "Africa/Khartoum", "name": "Sudan/Khartoum (GMT +02:00)", "country_code": "sd", "offset": 7200 },
    { "id": "America/Paramaribo", "name": "Suri\"name\"/Paramaribo (GMT -03:00)", "country_code": "sr", "offset": -10800 },
    { "id": "Arctic/Longyearbyen", "name": "Svalbard and Jan Mayen/Longyearbyen (GMT +01:00)", "country_code": "sj", "offset": 3600 },
    { "id": "Africa/Mbabane", "name": "Swaziland/Mbabane (GMT +02:00)", "country_code": "sz", "offset": 7200 },
    { "id": "Europe/Stockholm", "name": "Sweden/Stockholm (GMT +01:00)", "country_code": "se", "offset": 3600 },
    { "id": "Europe/Zurich", "name": "Switzerland/Zurich (GMT +01:00)", "country_code": "ch", "offset": 3600 },
    { "id": "Asia/Damascus", "name": "Syrian Arab Republic/Damascus (GMT +02:00)", "country_code": "sy", "offset": 7200 },
    { "id": "Asia/Taipei", "name": "Taiwan, Province of China/Taipei (GMT +08:00)", "country_code": "tw", "offset": 28800 },
    { "id": "Asia/Dushanbe", "name": "Tajikistan/Dushanbe (GMT +05:00)", "country_code": "tj", "offset": 18000 },
    { "id": "Africa/Dar_es_Salaam", "name": "Tanzania, United Republic of/Dar_es_Salaam (GMT +03:00)", "country_code": "tz", "offset": 10800 },
    { "id": "Asia/Bangkok", "name": "Thailand/Bangkok (GMT +07:00)", "country_code": "th", "offset": 25200 },
    { "id": "Asia/Dili", "name": "Timor-Leste/Dili (GMT +09:00)", "country_code": "tl", "offset": 32400 },
    { "id": "Africa/Lome", "name": "Togo/Lome (GMT +00:00)", "country_code": "tg", "offset": 0 },
    { "id": "Pacific/Fakaofo", "name": "Tokelau/Fakaofo (GMT +13:00)", "country_code": "tk", "offset": 46800 },
    { "id": "Pacific/Tongatapu", "name": "Tonga/Tongatapu (GMT +13:00)", "country_code": "to", "offset": 46800 },
    { "id": "America/Port_of_Spain", "name": "Trinidad and Tobago/Port_of_Spain (GMT -04:00)", "country_code": "tt", "offset": -14400 },
    { "id": "Africa/Tunis", "name": "Tunisia/Tunis (GMT +01:00)", "country_code": "tn", "offset": 3600 },
    { "id": "Europe/Istanbul", "name": "Turkey/Istanbul (GMT +03:00)", "country_code": "tr", "offset": 10800 },
    { "id": "Asia/Ashgabat", "name": "Turkmenistan/Ashgabat (GMT +05:00)", "country_code": "tm", "offset": 18000 },
    { "id": "America/Grand_Turk", "name": "Turks and Caicos Islands/Grand_Turk (GMT -05:00)", "country_code": "tc", "offset": -18000 },
    { "id": "Pacific/Funafuti", "name": "Tuvalu/Funafuti (GMT +12:00)", "country_code": "tv", "offset": 43200 },
    { "id": "Africa/Kampala", "name": "Uganda/Kampala (GMT +03:00)", "country_code": "ug", "offset": 10800 },
    { "id": "Europe/Kiev", "name": "Ukraine/Kiev (GMT +02:00)", "country_code": "ua", "offset": 7200 },
    { "id": "Europe/Simferopol", "name": "Ukraine/Simferopol (GMT +03:00)", "country_code": "ua", "offset": 10800 },
    { "id": "Europe/Uzhgorod", "name": "Ukraine/Uzhgorod (GMT +02:00)", "country_code": "ua", "offset": 7200 },
    { "id": "Europe/Zaporozhye", "name": "Ukraine/Zaporozhye (GMT +02:00)", "country_code": "ua", "offset": 7200 },
    { "id": "Asia/Dubai", "name": "United Arab Emirates/Dubai (GMT +04:00)", "country_code": "ae", "offset": 14400 },
    { "id": "Europe/London", "name": "United Kingdom/London (GMT +00:00)", "country_code": "gb", "offset": 0 },
    { "id": "Pacific/Midway", "name": "United States Minor Outlying Islands/Midway (GMT -11:00)", "country_code": "um", "offset": -39600 },
    { "id": "Pacific/Wake", "name": "United States Minor Outlying Islands/Wake (GMT +12:00)", "country_code": "um", "offset": 43200 },
    { "id": "America/Adak", "name": "United States/Adak (GMT -10:00)", "country_code": "us", "offset": -36000 },
    { "id": "America/Anchorage", "name": "United States/Anchorage (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/Boise", "name": "United States/Boise (GMT -07:00)", "country_code": "us", "offset": -25200 },
    { "id": "America/Chicago", "name": "United States/Chicago (GMT -06:00)", "country_code": "us", "offset": -21600 },
    { "id": "America/Denver", "name": "United States/Denver (GMT -07:00)", "country_code": "us", "offset": -25200 },
    { "id": "America/Detroit", "name": "United States/Detroit (GMT -05:00)", "country_code": "us", "offset": -18000 },
    { "id": "Pacific/Honolulu", "name": "United States/Honolulu (GMT -10:00)", "country_code": "us", "offset": -36000 },
    { "id": "America/Indiana/Knox", "name": "United States/Indiana", "country_code": "us", "offset": -21600 },
    { "id": "America/Indiana/Tell_City", "name": "United States/Indiana", "country_code": "us", "offset": -21600 },
    { "id": "America/Indiana/Petersburg", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Indiana/Marengo", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Indiana/Indianapolis", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Indiana/Vevay", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Indiana/Vincennes", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Indiana/Winamac", "name": "United States/Indiana", "country_code": "us", "offset": -18000 },
    { "id": "America/Juneau", "name": "United States/Juneau (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/Kentucky/Louisville", "name": "United States/Kentucky", "country_code": "us", "offset": -18000 },
    { "id": "America/Kentucky/Monticello", "name": "United States/Kentucky", "country_code": "us", "offset": -18000 },
    { "id": "America/Los_Angeles", "name": "United States/Los_Angeles (GMT -08:00)", "country_code": "us", "offset": -28800 },
    { "id": "America/Menominee", "name": "United States/Menominee (GMT -06:00)", "country_code": "us", "offset": -21600 },
    { "id": "America/Metlakatla", "name": "United States/Metlakatla (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/New_York", "name": "United States/New_York (GMT -05:00)", "country_code": "us", "offset": -18000 },
    { "id": "America/Nome", "name": "United States/Nome (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/North_Dakota/New_Salem", "name": "United States/North_Dakota", "country_code": "us", "offset": -21600 },
    { "id": "America/North_Dakota/Beulah", "name": "United States/North_Dakota", "country_code": "us", "offset": -21600 },
    { "id": "America/North_Dakota/Center", "name": "United States/North_Dakota", "country_code": "us", "offset": -21600 },
    { "id": "America/Phoenix", "name": "United States/Phoenix (GMT -07:00)", "country_code": "us", "offset": -25200 },
    { "id": "America/Sitka", "name": "United States/Sitka (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/Yakutat", "name": "United States/Yakutat (GMT -09:00)", "country_code": "us", "offset": -32400 },
    { "id": "America/Montevideo", "name": "Uruguay/Montevideo (GMT -03:00)", "country_code": "uy", "offset": -10800 },
    { "id": "Asia/Samarkand", "name": "Uzbekistan/Samarkand (GMT +05:00)", "country_code": "uz", "offset": 18000 },
    { "id": "Asia/Tashkent", "name": "Uzbekistan/Tashkent (GMT +05:00)", "country_code": "uz", "offset": 18000 },
    { "id": "Pacific/Efate", "name": "Vanuatu/Efate (GMT +11:00)", "country_code": "vu", "offset": 39600 },
    { "id": "America/Caracas", "name": "Venezuela/Caracas (GMT -04:00)", "country_code": "ve", "offset": -14400 },
    { "id": "Asia/Ho_Chi_Minh", "name": "Viet Nam/Ho_Chi_Minh (GMT +07:00)", "country_code": "vn", "offset": 25200 },
    { "id": "America/Tortola", "name": "Virgin Islands, British/Tortola (GMT -04:00)", "country_code": "vg", "offset": -14400 },
    { "id": "America/St_Thomas", "name": "Virgin Islands, U.S./St_Thomas (GMT -04:00)", "country_code": "vi", "offset": -14400 },
    { "id": "Pacific/Wallis", "name": "Wallis and Futuna/Wallis (GMT +12:00)", "country_code": "wf", "offset": 43200 },
    { "id": "Africa/El_Aaiun", "name": "Western Sahara/El_Aaiun (GMT +01:00)", "country_code": "eh", "offset": 3600 },
    { "id": "Asia/Aden", "name": "Yemen/Aden (GMT +03:00)", "country_code": "ye", "offset": 10800 },
    { "id": "Africa/Lusaka", "name": "Zambia/Lusaka (GMT +02:00)", "country_code": "zm", "offset": 7200 },
    { "id": "Africa/Harare", "name": "Zimbabwe/Harare (GMT +02:00)", "country_code": "zw", "offset": 7200 },
    { "id": "Europe/Mariehamn", "name": "\u00c5land Islands/Mariehamn (GMT +02:00)", "country_code": "ax", "offset": 7200 }
]

export default timezones;

