import React, { useState, useMemo } from "react";
import copyText from "../../../helpers/copyText";
import TickSVG from '../../../assets/svg-icons/TickSVG'
import WidgetCopySVG from '../../../assets/svg-icons/WidgetCopySVG'
import { CircularProgress } from "@material-ui/core";

const WidgetSummary = ({ owner, diffHours, createdBookingId }) => {

  const [showTick, setShowTick] = useState(null);
  const generatedMeetingUrl = useMemo(() => `${process.env.REACT_APP_PROFILE_BASE_URL}/${owner.pageUrl}/meet/${createdBookingId}`, [createdBookingId])

  const _copyText = (text) => {
    setShowTick(true);
    copyText(text)
    setTimeout(() => setShowTick(false), 3000);
  }

  let diffView = diffHours >= 1;

  return (
    <div>
      <div className={`widget-main__wrapper ${diffView ? 'diff-view' : ''} ${!createdBookingId ? 'widget-main__wrapper__loading' : ''} widget-main-summary__wrapper`}>
        <div className="widget-summary-link-box">
          <div className="widget-summary-link-title">
            Link to this meeting has been sent to your email
          </div>
          {
            <div className="widget-summary-generated-link" onClick={() => _copyText(generatedMeetingUrl)}>
              {!createdBookingId ?
                <CircularProgress size={20} color="primary" />
              : <>
                <span className="widget-summary-short-link">
                  {generatedMeetingUrl}
                </span>
                <span className="widget-summary-copy-icon">
                  {showTick ? <TickSVG /> : <WidgetCopySVG />}
                </span> 
              </>}
              
            </div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default WidgetSummary;
