import React from 'react'

export default function DefaultProfileNewSVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="none" viewBox="0 0 72 72">
            <circle cx="36" cy="36" r="36" fill="#F3F8F9" />
            <circle cx="36.001" cy="36" r="29.908" fill="#B9D7E0" opacity=".6" />
            <path fill="#fff" fillRule="evenodd" d="M35.92 37.718c-4.04 0-7.344-3.311-7.344-7.359 0-4.047 3.304-7.359 7.344-7.359 4.039 0 7.344 3.312 7.344 7.36 0 4.047-3.305 7.358-7.344 7.358zM21.963 48.127c.192-.84.423-1.644.693-2.375.346-.95.77-1.864 1.23-2.704 2.27-4.093 6.233-5.664 9.888-3.874 1.423.695 2.962.695 4.347 0 3.655-1.754 7.656-.219 9.926 3.837.462.84.885 1.754 1.231 2.704.27.768.5 1.572.693 2.412.077.256 0 .548-.193.767-.192.22-.462.33-.73.33H22.885c-.307 0-.577-.11-.73-.33-.193-.219-.27-.511-.193-.767z" clipRule="evenodd" />
        </svg>
    )
}