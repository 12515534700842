import Api from './api';

const api = new Api('/user')

export const fetchOneUser = async (email, ownerId) => {
    const data = await api.get(`/findOne`, {
        params: {
            email,
            ownerId,
        }
    })
    return data
}
