import React, { useRef, useState } from "react";
import "../../styles/profile/GalleryVideo.css";

export default function GalleryVideo({ src, alt, attributes, onClick }) {
	const video = useRef();
	const [videoPaused, setVideoPaused] = useState(true);

	/*const toggleVideoPlayPause = () => {
		console.log(video, video.current.paused);
		if (video.current.paused) {
			video.current.play();
		} else {
			video.current.pause();
		}
		setVideoPaused(() => video.current.paused);
	};*/

	return (
		<div
			className={["gallery-video-wrapper", videoPaused ? "paused" : "playing"].join(" ")}
			style={{ cursor : "pointer" }}
			onClick={onClick}
		>
			<video
				src={`${src}#t=0.1`}
				alt={alt}
				{...attributes}
				preload="metadata"
				playsInline
				controls={!videoPaused}
				className="gallery-video"
				ref={video}
				onEnded={() => setVideoPaused(true)}
			/>
			<button className="play-button"/>
		</div>
	);
}
