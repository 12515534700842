import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as authService from "services/authService";
import ProfilerWizardBackButton from "components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardBackButton";
import ProfilerWizardNextButton from "components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardNextButton";
import WidgetForgotPassword from "./WidgetForgotPassword";
import "../widget.css";
import { eventManager } from "utils"
import ProviderSignin from "components/profile/ProviderSignin";

const WidgetSignIn = ({ toggleSignIn, handleGoNext, nextLoading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState("")

  const toggleForgotPass = () => {
    setForgotPass(prev => !prev)
  };

  const handleSignInWithGoogle = async () => {
    try {
      setLoading(true)
      await authService.signInWithGoogle()
      setLoading(false)
      handleGoNext()
    } catch (err) {
      setLoading(false)
      eventManager.showNotificationFail('Sign in failed')
    }
  }

  const handleSignInWithFacebook = async () => {
    try {
      setLoading(true)
      await authService.signInWithFacebook()
      setLoading(false)
      handleGoNext()
    } catch (err) {
      setLoading(false)
      eventManager.showNotificationFail(err.message)
    }
  }

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await authService.signIn(email, password)
      setLoading(false)
      handleGoNext()
    } catch (err) {
      console.log('err: ', err);
      setErrors(err.message || "")
      setLoading(false)
    }
  }

  return (
    <>
      {forgotPass ? <WidgetForgotPassword toggleForgotPass={toggleForgotPass} /> :
        <form onSubmit={handleSignIn} id="sign-in-form">
          <div className="widget-signup-inner-bottom">
            <h2 className="widget-signup-title">In order to book this service, please <span>Login</span></h2>
            <div className="widget-registration-texfield-container">
              <label htmlFor="email">Email</label>
              <TextField
                id="email"
                placeholder="Enter your email"
                error={(errors.includes("email") || errors.includes("corresponding")) && true}
                helperText={(errors.includes("email") || errors.includes("corresponding")) && "Email is not valid"}
                defaultValue={email}
                variant="outlined"
                className={"widget-registration-input"}
                onChange={e => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <TextField
                id="password"
                placeholder="Enter your password"
                error={errors.includes("password") && true}
                helperText={errors.includes("password") && "Password is not correct"}
                defaultValue={password}
                variant="outlined"
                onChange={e => setPassword(e.target.value)}
                className={"widget-registration-input"}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <svg className={["text-field-icon-right", "password-eye", showPassword ? 'active' : null].join(' ')} onClick={() => setShowPassword(!showPassword)} xmlns="http://www.w3.org/2000/svg" width="24" height="21" fill="none" viewBox="0 0 18 16">
                        <path fillRule="evenodd" stroke="#29A7FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1.667 8S4.334 2.667 9 2.667C13.667 2.667 16.334 8 16.334 8S13.667 13.333 9 13.333C4.334 13.333 1.667 8 1.667 8z" clipRule="evenodd" />
                        <path fillRule="evenodd" stroke="#29A7FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 10c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" clipRule="evenodd" />
                      </svg>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="forgot-password" onClick={toggleForgotPass}>Forgot your password?</div>
            <div className="widget-sign-up-or">
              <span>or</span>
            </div>
            <ProviderSignin handleSignInWithGoogle={handleSignInWithGoogle} handleSignInWithFacebook={handleSignInWithFacebook} type="signIn" />
            <div className="widget-signup-already-have-account-box">
              <span>Don't have an account?</span>
              <button type='button' onClick={toggleSignIn}>Sign up</button>
            </div>

            <div className="cancel-next-message">
              <ProfilerWizardBackButton
                onClick={toggleSignIn}
              />
              <ProfilerWizardNextButton
                onClick={handleSignIn}
                loading={loading || nextLoading}
                text='Login'
                type="submit"
              />
            </div>
          </div>
        </form>
      }
    </>
  );
};

export default WidgetSignIn;
