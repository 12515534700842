import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { app as firebase } from "../../configs/fbConfig";
import "../../styles/profile/Profile.css";
import About from "./About";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { AuthContext } from "context/AuthProvider";
import * as servicesService from "../../services/servicesService";
import * as bookingService from "../../services/bookingService";
import * as reviewService from "../../services/reviewService";
import { loadBlueSnap } from '../../utils/bluesnapLoad'
import Logo from "../../assets/svg-icons/Logo";
import "../../assets/fonts/fonts.css";
import ServiceItem from "./ServiceItem";
import BookingPopup from "./BookingPopup";
import mixpanelApp from "../../services/mixpanel";
import UserActions from "./UserActions";
import Sticky from "react-sticky-el";
import AuthPopup from "./AuthPopup";
import { Helmet } from "react-helmet";
import EnviteStorage from '../../services/storage'
import EnviteLogoMobile from "../../assets/svg-icons/EnviteLogoMobile";
import { availabilityHelper, userLocation } from "helpers";
import { isMobile } from "react-device-detect";
import DropDownSVG from "../../assets/svg-icons/DropDownSVG";
import Menu from '@material-ui/core/Menu';
import { MenuItem } from "@material-ui/core";
import LogOutDropDownSVG from "../../assets/svg-icons/LogOutDropDownSVG";
import LogInDropDownSVG from "../../assets/svg-icons/LogInDropDownSVG";
import ServiceCard from "components/ServiceCard";

export default function Profile({ forceLogin }) {
  const starsList = [];
  const { profile } = useContext(AuthContext);
  const [theme, setTheme] = useState("");
  const [owner, setOwner] = useState(null);
  const [services, setServices] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [dropDownVisibility, setDropDownVisibility] = useState(false)
  const [unavailableDays, setUnavailableDays] = useState([]);
  const [availableWeekDays, setAvailableWeekDays] = useState([]);
  const [exceptionDates, setExceptionDates] = useState([]);
  const [fullyBooked, setFullyBooked] = useState([]);
  const { serviceName } = useParams();
  const [loader, setLoader] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const bookingPopup = useRef();
  const [bookingPopupVisible, setBookingPopupVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const tenantId = useMemo(() => EnviteStorage.get("tenantId"), [EnviteStorage.get("tenantId")])
  const showBurgerMenu = window.innerWidth <= 768
  const [adminIframe, setAdminIframe] = useState(false)

  useEffect(() => {
    loadBlueSnap();
  }, []);

  useEffect(() => {
    if (forceLogin) setLoginPopupVisible(true)
  }, [forceLogin])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.get('login') === '1' && setLoginPopupVisible(true);
  }, []);

  const setTenant = React.useCallback((tenantId) => {
    const existingTenant = tenantId;
    if (existingTenant && existingTenant !== tenantId) {
      firebase.auth().signOut();
    }
    EnviteStorage.set("tenantId", tenantId);
    firebase.auth().tenantId = tenantId || "EnviteTenant-dhgy2";
  }, [tenantId]);

  useEffect(() => {
      let fromAdmin = new URL(window.location.href).searchParams.get("fromAdmin");
      if(fromAdmin){
        setAdminIframe(true)
      }
  }, [])

  useEffect(() => {
    const getTeacherData = (serviceName) => {
      axios
        .get(`/owners/service/${serviceName}`)
        .then((res) => {
          userLocation().then((userLocation) => {
            const ownerData = res.data
            setOwner({
              ...ownerData,
              userLocation: userLocation || {},
              registrationFields: ownerData.registrationFields || []
            });
          })
          setTenant(res.data.tenantId || "EnviteTenant-dhgy2");
          EnviteStorage.set("ownerId", res.data.id);
          mixpanelApp.register({
            vendor_id: res.data.id,
            vendor_name: res.data.name,
            vendor_link: serviceName,
          });
          mixpanelApp.track("page_visit", { page: "profile" });
        })
        .catch((err) => {
          console.log("failed fetching");
        });
    };

    getTeacherData(serviceName);
  }, [serviceName, setTenant]);

  useEffect(() => {
    owner && owner.theme && setTheme(owner.theme);
    if (owner && owner.id) {
      fetchServices(owner.id);
      fetchReviews(owner.id);
    }
  }, [owner]);

  const fetchServices = async (id) => {
    try {
      const { data } = await servicesService.fetchServices(id);
      setServices(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReviews = async (id) => {
    try {
      const { data } = await reviewService.fetchReviews(id);
      setReviews(data);
    } catch (error) {
      console.error(error);
    }
  };

  const createReviews = async (review, rating, picture, name) => {
    if (rating > 0) {
      try {
        const data = await reviewService.createReviews({
          review: review,
          star: rating,
          image: picture,
          name: name || profile.fullName,
          ownerId: owner.id,
          userId: profile.uid,
        });
        setReview("");
        setRating("");
      } catch (error) {
        console.error(error);
      }
    }
  };


  const getAvailableDatesForService = useCallback(
    async (id, serviceId) => {
      setLoader(true);
      let time = availabilityHelper.dayFormate(new Date())
      try {
        const { data } = await servicesService.getAvailableDatesForService(
          id,
          selectedService.id ? selectedService.id : serviceId,
          time
        );
        setUnavailableDays(data.unavailableArr);
        setAvailableWeekDays(data.availbaleWeekdays);
        setExceptionDates(data.exceptionDates);
        setFullyBooked(data.fullyBooked);
        setLoader(false);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedService]
  );

  const getServiceIntervals = async (params, callback = () => { }) => {
    try {
      const { data } = await servicesService.getServiceIntervals(
        params.date,
        selectedService.id,
        owner.id,
        params.timezone
      );
      callback(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getBookingTimes = async (day, callback = () => { }) => {
    try {
      const { data } = await bookingService.getBookingTimes(
        selectedService.id,
        owner.id,
        day
      );
      callback(data);
    } catch (error) {
      console.log(error);
    }
  };

  const bookHandler = useCallback(() => {
    let serviceId = selectedService.id;
    if (!serviceId && services.length === 1) {
      serviceId = services[0].id;
      setSelectedService(services[0])
    }
    setBookingPopupVisible(true);
    getAvailableDatesForService(owner.id, serviceId, availabilityHelper.dayFormate(new Date()));
  }, [selectedService, getAvailableDatesForService, owner, services]);

  const handleDropdownClose = () => {
    setDropDownVisibility(false)
  }

  const printServices = useCallback(
    (mobile = false) => {
      if (!(services && services.length)) {
        return <p>Owner hasn't added any service yet.</p>;
      }

      const isServiceSelected = Object.keys(selectedService).length;
      const bookButton = (
        <div className={"btn-booking-container"}>
          <button
            className={"btn btn-booking"}
            onClick={bookHandler}
            disabled={!(isServiceSelected || services.length === 1)}
            id={"envite-event-client-book-meeting"}
          >
            Book a meeting
          </button>
        </div>
      );

      return (
        <>
          <div className={"custom-scroll services-wrapper"}>
            {services.map((service) => {
              return (
                <ServiceCard
                  key={service.id}
                  service={service}
                  onClick={
                    () => {
                      setSelectedService(selectedService.id === service.id ? {} : services.find((el) => el.id === service.id))
                    }
                  }
                  isSelected={
                    selectedService && service.id === selectedService.id
                  }
                />
              );
            })}
            {mobile && bookButton}
          </div>
          {!mobile && bookButton}
        </>
      );
    },
    [services, selectedService, bookHandler]
  );

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (user) => {
        !user && setCurrentUser(user)
        if (user && user.tenantId === tenantId) {
          setCurrentUser(user);
        }
      });
    return () => unsubscribe();
  }, [tenantId]);

  return (
    <div className={adminIframe && "content-wrapper-admin-page"}>
      {owner && (
        <>
          <Helmet>
            <meta property="type" content="website" />
            <meta property="og:locale" content="en" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={owner.displayName} />
            <meta property="og:description" content={`Welcome to my Envite page. Now you can book meetings with me and learn more about my services.`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Envite.live" />
          </Helmet>
          <div
            className={`profile-wrapper ${theme
              ? `profile-wrapper_light` /*`profile-wrapper_${theme}`*/
              : null
              }
                ${forceLogin ? 'profile-wrapper-meeting-login' : ''}
              `}
          >
            <div className="top-bar">
              <div className={"content-wrapper"}>
                {showBurgerMenu ?
                  <a href={process.env.REACT_APP_LANDING_URL} className={"logo-container"}>
                    <EnviteLogoMobile />
                  </a>
                  :
                  <a href={process.env.REACT_APP_LANDING_URL} className={"logo-container"}>
                    <Logo />
                  </a>}
                {!isMobile ?
                  currentUser ? (
                    <UserActions
                      owner={owner}
                      currentUser={currentUser}
                      signOut={() => {
                        firebase.auth().signOut();
                        EnviteStorage.remove("idToken");
                        EnviteStorage.remove("accessToken");
                      }}
                    />
                  ) : (
                    <div className={"btn-login"}>
                      <button
                        className={"btn"}
                        onClick={() => setLoginPopupVisible(true)}
                      >
                        Login
                      </button>
                    </div>
                  ) :
                  <div
                    onClick={() => setDropDownVisibility(!dropDownVisibility)}
                  >
                    <DropDownSVG />
                  </div>
                }
                {/*!currentUser && (
                  <div className={"horizontal-dots display-mobile"}>
                  <HorizontalDots />
                </div>
              )*/}
              </div>
            </div>
            <main>
              <div className={"content-wrapper"}>
                <div className={"sections"}>
                  <section className={"service-name display-mobile"}>
                    <div className={"title-container"}>
                      {owner.logo && (
                        <div className={"box-icon"}>
                          <img src={owner.logo} alt="logo" className="logo" />
                        </div>
                      )}
                      <div className={"box-title"}>{owner.serviceName}</div>
                    </div>
                  </section>
                  <section className={"about"}>
                    <About owner={owner} />
                  </section>
                  <section className={"services display-mobile"}>
                    <h3 className={"section-title"}>Services</h3>
                    {printServices(true)}
                  </section>
                  {owner.gallery && owner.gallery.length !== 0 && (
                    <section className={"gallery"}>
                      <h3 className={"section-title"}>Gallery</h3>
                      <Gallery contents={owner.gallery} />
                    </section>
                  )}
                  <section className={"reviews"}>
                    <h3 className={"section-title"}>Reviews</h3>
                    <Reviews
                      reviews={reviews}
                      createReviews={createReviews}
                      setReview={(review) => setReview(review)}
                      review={review}
                      starsList={starsList}
                      setRating={(rating) => setRating(rating)}
                      rating={rating}
                      owner={owner}
                    />
                  </section>
                </div>
                <div className={"sidebar display-desktop"}>
                  <Sticky stickyClassName={"sticky"}>
                    <div className={"sidebar-box"}>
                      <div className={"title-container"}>
                        {owner.logo && (
                          <div className={"box-icon"}>
                            <img src={owner.logo} alt="logo" className="logo" />
                          </div>
                        )}
                        <div className={"box-title"}>{owner.serviceName}</div>
                      </div>
                      <div className={"box-divider"} />
                      <div className={"box-content"}>
                        <h3>Services</h3>
                        {printServices()}
                      </div>
                    </div>
                  </Sticky>
                </div>
              </div>
            </main>
            <AuthPopup
              owner={owner}
              loginPopupVisible={loginPopupVisible}
              setLoginPopupVisible={setLoginPopupVisible}
              forceLogin={forceLogin}
            />
            <BookingPopup
              bookingPopup={bookingPopup}
              visible={bookingPopupVisible}
              setVisible={setBookingPopupVisible}
              owner={owner}
              services={services}
              unavailableDays={unavailableDays}
              getBookingTimes={getBookingTimes}
              availableWeekDays={availableWeekDays}
              exceptionDates={exceptionDates}
              fullyBooked={fullyBooked}
              getServiceIntervals={getServiceIntervals}
              loader={loader}
              selectedService={selectedService}
              getAvailableDatesForService={getAvailableDatesForService}
            />
          </div>
          <Menu
            open={dropDownVisibility}
            onClose={handleDropdownClose}
            className={"auth-dropdown"}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 43, left: 900 }}
          >
            {currentUser ?
              <MenuItem
                className="menu-item-action"
                onClick={() => {
                  firebase.auth().signOut();
                  EnviteStorage.remove("idToken");
                  EnviteStorage.remove("accessToken");
                  handleDropdownClose()
                }}
              >{<LogOutDropDownSVG />} Log out</MenuItem>
              :
              <MenuItem
                onClick={() => {
                  handleDropdownClose()
                  setLoginPopupVisible(true)
                }}
                className="menu-item-action"
              >{<LogInDropDownSVG />}Log in</MenuItem>
            }
          </Menu>
        </>
      )}
    </div>
  );
}
