import React, { useEffect, useState } from 'react'
import Calendar from 'components/profile/Calendar'
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import timezones from "../../../../helpers/timezones";
import Select from 'react-select';
import { availabilityHelper } from 'helpers';

const ChooseDate = ({ wizardState,
    setSelectedTime,
    selectedTime,
    setSelectedDate,
    handleChangeState,
    intervals,
    setIntervals,
    error,
    selectedService,
    owner,
    getServiceIntervals,
    getBookingTimes,
    unavailableDays,
    exceptionDates,
    availableWeekDays,
    fullyBooked,
    loader }) => {

    const { selectedDate, selectedTimezone } = wizardState;
    const [timesLoading, setTimesLoading] = useState(false);
    const [timeError, setTimeError] = useState(error)
    const [timeSelectorOpen, setTimeSelectorOpen] = useState(false)

    useEffect(() => {
        setTimeError(error)
    }, [error])


    const checkBookedTimes = (bookedTimes, time) => {
        let available = true;
        bookedTimes.forEach(bookedTime => {
            let splitTime = bookedTime.time.split(":");
            let formatedBookeTime = moment().set({ hour: splitTime[0], minute: splitTime[1] }).format("YYYY-MM-DD HH:mm")
            if (time >= bookedTime.time && time < moment(availabilityHelper.serviceDurations(formatedBookeTime, availabilityHelper.roundUpIntervals(bookedTime.duration))).format("HH:mm")) {
                available = false
                return available;
            }
        })
        return available;
    }

    const formIntervals = (intervals, selectedService, bookedTimes, selectedDate) => { //build correct interval times for services
        let duration = availabilityHelper.roundUpIntervals(selectedService.duration)
        let hours = [];

        intervals.forEach(interval => {

            if (moment(interval.from).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) {
                if (moment(interval.from).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
                    moment(interval.from).format("HH:mm") < moment().format("HH:mm")) {
                } else {
                    hours.push({ time: moment(interval.from).format("HH:mm"), available: checkBookedTimes(bookedTimes, moment(interval.from).format("HH:mm")), id: interval.from })
                }
            }

            while (true) {
                let newTime = availabilityHelper.serviceDurations(moment(interval.from).format('YYYY-MM-DD HH:mm'), duration);
                let stopTime = availabilityHelper.serviceDurations(newTime, duration);
                interval.from = newTime;
                if (moment(newTime).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) {
                    if (moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(newTime).format("HH:mm") < moment().format("HH:mm")) { // if it is passed time dont' push in array
                    } else {
                        hours.push({ time: moment(newTime).format("HH:mm"), available: checkBookedTimes(bookedTimes, moment(newTime).format("HH:mm")), id: newTime })
                    }
                }
                if (stopTime > moment(interval.to).format('YYYY-MM-DD HH:mm')) break;
            }

        })

        hours.sort((a, b) => {
            return moment().set({ hour: a.time.split(":")[0], minute: a.time.split(":")[1] }).valueOf()
                - moment().set({ hour: b.time.split(":")[0], minute: b.time.split(":")[1] }).valueOf()
        });
        setIntervals(hours.length > 1 ? hours.slice(0, hours.length - 1) : hours)
        if (selectedDate) setTimeSelectorOpen(true)
    }

    const loadTimes = (val, timezone = selectedTimezone) => {
        const time = availabilityHelper.dayFormate(val);
        setTimesLoading(true);
        getServiceIntervals({
            timezone: timezone ? -(timezone.offset / 60) : new Date().getTimezoneOffset(),
            date: time
        }, serviceIntervals => {
            getBookingTimes(time, bookingTimes => {
                formIntervals(serviceIntervals, selectedService, bookingTimes, val);
                setTimesLoading(false);
            });
        });
    }

    const handleDateChange = (val) => {
        handleChangeState({ selectedDate: val, selectedTime: null });
        setSelectedDate(val);
        loadTimes(val);
    }

    let defaultTimezoneValue = null;
    const timezoneOptions = timezones.map(el => {
        const ownerCountryCode = owner.userLocation?.value?.toLowerCase() || owner.country?.value?.toLowerCase();
        const option = {
            label: el.name,
            value: el,
            flag: require('../../../../assets/flags/' + el.country_code + '.svg').default
        };

        if (el.country_code === ownerCountryCode) {
            defaultTimezoneValue = option;
        }
        return option;
    })

    const customOption = ({ data, innerProps }) => (
        <div className="select__option customized" {...innerProps}>
            <img src={data.flag} alt={"flag"} /> {data.label}
        </div>
    );

    const customSingleValue = ({ data, innerProps }) => (
        <div className="select__single-value customized" {...innerProps}>
            <img src={data.flag} alt={"flag"} /> {data.label}
        </div>
    )

    useEffect(() => {
        if (defaultTimezoneValue) {
            handleChangeState({ selectedTimezone: defaultTimezoneValue.value })
        }
        return () => setTimeSelectorOpen(null)
    }, [loader])

    return (
        <>
            <h3>Choose date and time</h3>
            <Calendar
                value={selectedDate}
                onInput={handleDateChange}
                unavailableDays={unavailableDays}
                exceptionDates={exceptionDates}
                availableWeekDays={availableWeekDays}
                fullyBooked={fullyBooked}
                loader={loader}
                handleDayPickerOpen={() => setTimeSelectorOpen(false)}
                id={"envite-event-client-pick-date"}
            />
            <div className={"input-label"}>
                Time
            </div>
            <div className={"time-picker-container"}>
                {timesLoading && (
                    <div className={`calendar-loader`}>
                        <CircularProgress size={20} />
                    </div>
                )}
                <Select
                    className="envite__selectInput"
                    classNamePrefix="select"
                    placeholder='Set time'
                    id={"envite-event-client-pick-time"}
                    onChange={(e) => {
                        setTimeError(false)
                        handleChangeState({
                            selectedTime: e.value
                        });
                        setSelectedTime(e);
                    }}
                    value={selectedTime}
                    name="time"
                    options={intervals.map(interval => ({
                        label: moment(interval.id).format(owner.timeFormat ? owner.timeFormat : "hh:mm"),
                        value: interval,
                        isDisabled: !interval.available
                    }))}
                    menuIsOpen={timeSelectorOpen}
                    onMenuOpen={() => setTimeSelectorOpen(true)}
                    onMenuClose={() => setTimeSelectorOpen(false)}
                />
                {
                    timeError && <div className="time-error">
                        {timeError.data.message}
                    </div>
                }
            </div>
            <div className={"input-label"}>
                Time zone
            </div>
            <div className={"timezone-container"}>
                <Select
                    className="envite__selectInput"
                    classNamePrefix="select"
                    onChange={(e) => {
                        handleChangeState({ selectedTimezone: e.value });
                        loadTimes(selectedDate, e.value);
                    }}
                    defaultValue={defaultTimezoneValue}
                    name="timezone"
                    id={"envite-event-client-pick-timezone"}
                    options={timezoneOptions}
                    isSearchable={true}
                    components={{
                        Option: customOption,
                        SingleValue: customSingleValue
                    }}
                    menuPlacement="top"
                //menuIsOpen
                />
            </div>
        </>
    )
}

export default ChooseDate
