import React, { useState } from "react";
import LoginPopup from "./LoginPopup";
import SignUpPopup from "./SignUpPopup";
import PasswordResetPopup from "./PasswordResetPopup";

export default function AuthPopup({
  owner,
  loginPopupVisible,
  setLoginPopupVisible,
  forceLogin
}) {
  const [signUpPopupVisible, setSignUpPopupVisible] = useState(false);
  const [passwordResetPopupVisible, setPasswordResetPopupVisible] = useState(
    false
  );

  return (
    <>
      {loginPopupVisible && (
        <LoginPopup
          visible={loginPopupVisible}
          setVisible={setLoginPopupVisible}
          setSignUpVisible={setSignUpPopupVisible}
          setPasswordResetVisible={setPasswordResetPopupVisible}
          forceLogin={forceLogin}
        />
      )}
      {signUpPopupVisible && (
        <SignUpPopup
          visible={signUpPopupVisible}
          setVisible={setSignUpPopupVisible}
          setLoginVisible={setLoginPopupVisible}
          ownerId={owner.id}
        />
      )}
      {passwordResetPopupVisible && (
        <PasswordResetPopup
          visible={passwordResetPopupVisible}
          setVisible={setPasswordResetPopupVisible}
          setLoginVisible={setLoginPopupVisible}
        />
      )}
    </>
  );
}
