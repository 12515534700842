import EmptyStarSvg from "assets/svg-icons/EmptyStarSVG";
import FullstarSVG from "assets/svg-icons/FullstarSvg";
import HalfstarSVG from "assets/svg-icons/HalfstarSVG";
import React from "react";
import "../../styles/profile/Rating.css";

export default function Rating({ stars = 0, maxStars = 5, enabled = false }) {
    const starsList = [];

    for (let i = 0; i < Math.floor(stars); i++) {
        starsList.push((
            i < stars && <FullstarSVG  key={i*2}/>
        ));
    }
    if (stars !== maxStars) {
        if ((stars - Math.floor(stars)) / 0.5 < 0.5) {
            starsList.push((
                <EmptyStarSvg key={"1"}/>
            ));
        } else if ((stars - Math.floor(stars)) / 0.5 > 0.5 && (stars - Math.floor(stars)) / 0.5 < 1.5) {
            starsList.push((
                <HalfstarSVG key={"2"}/>
            ));
        } else if ((stars - Math.floor(stars)) / 0.5 > 1.5) {
            starsList.push((
                <FullstarSVG key={"3"}/>
            ));
        }
    }
    for (let i = 0; i < maxStars - 1 - Math.floor(stars); i++) {
        starsList.push((
            <EmptyStarSvg key={i*3} />
        ));
    }
    
    return <ul className={`rating ${enabled ? "enabled" : null}`}>{starsList}</ul>

}
