import React from 'react'
import WidgetSummary from 'components/widget/flow/WidgetSummary';

const Summary = ({ owner, wizardState, createdBookingId }) => {
    const { selectedDate, selectedTime } = wizardState;

    const getDiffHours = React.useCallback(({selectedDate, selectedTime}) =>  {
        if(!selectedDate || !selectedTime) return;
        const timeArray = selectedTime.value.time.split(":");
        const currentDate = new Date();
        selectedDate.setHours(timeArray[0]);
        selectedDate.setMinutes(timeArray[1]);

        return Math.floor((selectedDate.getTime() - currentDate.getTime())/1000/60/60);
    },  [selectedDate, selectedTime])

    return (
        <>
            <WidgetSummary owner={owner} createdBookingId={createdBookingId} diffHours={() => getDiffHours(wizardState)}/>
        </>
    )
}

export default Summary
