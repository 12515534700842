const renderWidget = (websiteId, tenantId, meetingId) => {
  window.envite || (function (d) {
    var o = window.envite = function () {
      o.params[arguments[0]] ?
        o.params[arguments[0]].push(arguments[1]) : o.params[arguments[0]] = [arguments[1]];
    }
      , h = d.getElementsByTagName('head')[0];
    var c = d.createElement('script'); o.params = {}; c.async = true; c.type = 'text/javascript';
    c.charset = 'utf-8'; c.src = `${process.env.REACT_APP_CHAT_SCRIPT_URL} `; h.appendChild(c);
  })(document);
  window.envite('init', websiteId);
  window.envite('tenantId', tenantId);
  window.envite('meetingId', meetingId);
  
}

export default renderWidget;