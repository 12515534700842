import React from "react";

export default function PrivacyPolicyPopup() {
  return (
    <div className='terms-and-conditions'>
      <h1>Envite. Privacy Policy Effective as April 28, 2021</h1>

      <p>Envite, which is operated and developed by Maje Social Commerce Ltd (“Envite,” “our,” “we,” or “us”) provides a social extension to ecommerce sites, enabling a shared shopping experience with friends, family, professionals and influencers, while messaging such users around the world. Please read our Terms of Service so you understand how we allow to use Envite. You agree to our Privacy Policy (“Privacy Policy”), which should be read as part of our Terms of Service (“Terms”) by installing, accessing, or using our services, apps, services, features, software, or website (together, “Services”).</p>

      <h3>Information We Collect</h3>

      <p>Envite receives or collects information when we operate and provide our Services, including when you install, access, or use our Services.</p>

      <h4>Information You Provide</h4>
      <ul>
        <li><b>Your Account Information.</b> You provide your account details of your Facebook or Google accounts (which may include details such as email, mobile phone number and more) to create a Envite account. You provide us the emails or phone numbers of your contacts on a regular basis, including those of both the users of our Services. You confirm you are authorized to provide us such emails and numbers. You may also add other information to your account, such as a profile name, profile picture, and status message.</li>
        <li><b>Your Messages.</b> We do not retain your messages in the ordinary course of providing our Services to you. Once your messages (including your chats, photos, videos, voice messages, files, and share location information) are delivered, they are deleted from our servers. Your messages are stored on your own device. If a message cannot be delivered immediately (for example, if you are offline), we may keep it on our servers for up to 30 days as we try to deliver it. If a message is still undelivered after 30 days, we delete it. To improve performance and deliver media messages more efficiently, such as when many people are sharing a photo or video, we may retain that content on our servers for a longer period of time.</li>
        <li><b>Your Connections.</b> To help you organize how you communicate with others, we may create a favorites list of your contacts for you, and you can create, join, or get added to groups and broadcast lists, and such</li>
        <li><b>Your Account Information.</b> You provide your account details of your Facebook or Google accounts (which may include details such as email, mobile phone number and more) to create a Envite account. You provide us the emails or phone numbers of your contacts on a regular basis, including those of both the users of our Services. You confirm you are authorized to provide us such emails and numbers. You may also add other information to your account, such as a profile name, profile picture, and status message.</li>
        <li><b>Your Messages.</b> We do not retain your messages in the ordinary course of providing our Services to you. Once your messages (including your chats, photos, videos, voice messages, files, and share location information) are delivered, they are deleted from our servers. Your messages are stored on your own device. If a message cannot be delivered immediately (for example, if you are offline), we may keep it on our servers for up to 30 days as we try to deliver it. If a message is still undelivered after 30 days, we delete it. To improve performance and deliver media messages more efficiently, such as when many people are sharing a photo or video, we may retain that content on our servers for a longer period of time.</li>
        <li><b>Your Connections.</b> To help you organize how you communicate with others, we may create a favorites list of your contacts for you, and you can create, join, or get added to groups and broadcast lists, and such groups and lists get associated with your account information.</li>
        <li>groups and lists get associated with your account information.</li>
      </ul>

      <h4>Automatically Collected Information</h4>
      <ul>
        <li><b>Usage and Log Information.</b> We collect service-related, diagnostic, and performance information. This includes information about your activity (such as how you use our Services, how you interact with others using our Services, and the like), log files, and diagnostic, crash, website, and performance logs and reports.</li>
        <li><b>Transactional Information.</b> If you pay for our Services, we may receive information and confirmations, such as payment receipts, including from app stores or other third parties processing your payment.</li>
        <li><b>Device and Connection Information.</b> We collect device-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, browser information, IP address, mobile network information including phone number, and device identifiers. We collect device location information if you use our location features, such as when you choose to share your location with your contacts, view locations nearby or those others have shared with you, and the like, and for diagnostics and troubleshooting purposes such as if you are having trouble with our app’s location features.</li>
        <li><b>Cookies.</b> We use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services. For example, we use cookies to provide Envite for web and desktop and other web-based services. We may also use cookies to understand which of our FAQs are most popular and to show you relevant content related to our Services. Additionally, we may use cookies to remember your choices, such as your language preferences, and otherwise to customize our Services for you.  </li>
      </ul>

      <h4>Third-Party Information</h4>
      <ul>
        <li>Information Others Provide About You. We receive information other people provide us, which may include information about you. For example, when other users you know use our Services, they may provide your phone number from their mobile address book (just as you may provide theirs), or they may send you a message, send messages to groups to which you belong, or call you.</li>
        <li>Third-Party Providers. We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with companies to distribute our apps, provide our infrastructure, delivery, and other systems, supply map and places information, process payments, help us understand how people use our Services, and market our Services.  </li>
        <li>Third-Party Services. We allow you to use our Services in connection with third-party services. If you use our Services with such third-party services, we may receive information about you from them; for example, if you use the Envite share button on a ecommerce service to share a product with your Envite contacts, groups, or broadcast lists on our Services, or if you choose to access our Services through a mobile carrier’s or device provider’s promotion of our Services. Please note that when you use third-party services, their own terms and privacy policies will govern your use of those services.</li>
      </ul>

      <h3>How We Use Information</h3>

      <p>We use all the information we have to help us operate, provide, improve, understand, customize, support, and market our Services.</p>

      <ul>
        <li><b>Our Services.</b> We operate and provide our Services, including improving, fixing, and customizing our Services. We understand how people use our Services, and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us. We may use cookies to operate, provide, improve, understand, and customize our Services.</li>
        <li><b>Safety and Security.</b> We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally.</li>
        <li><b>Commercial Messaging.</b> We will allow you and third parties, like businesses, to communicate with each other using Envite, such as through order, transaction, and appointment information, delivery and shipping notifications, product and service updates, and marketing.</li>
      </ul>

      <h3>Information You and We Share</h3>

      <p>You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.</p>

      <ul>
        <li>Account Information. Your email or phone number, profile name and photo, online status may be available to anyone who uses our Services.</li>
        <li>Your Contacts and Others. Users with whom you communicate may store or reshare your information (including your email or phone number or messages) with others on and off our Services.</li>
        <li>Third-Party Providers. We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. When we share information with third-party providers, we require them to use your information in accordance with our instructions and terms or with express permission from you.</li>
      </ul>

      <h3>Affiliated Companies</h3>

      <p>Envite may receive information from, and shares information with, its parent company or affiliated companies. We may use the information we receive from them, and they may use the information we share with them, to help operate, provide, improve, understand, customize, support, and market our Services and their offerings. This includes helping improve infrastructure and delivery systems, understanding how our Services or theirs are used, securing systems, and fighting spam, abuse, or infringement activities.</p>

      <h3>Assignment, Change Of Control, And Transfer</h3>

      <p>All of our rights and obligations under our Privacy Policy are freely assignable by us to any of our affiliates, in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner.</p>

      <h3>Law and Protection</h3>

      <p>We may collect, use, preserve, and share your information if we have a good-faith belief that it is reasonably necessary to: (a) respond pursuant to applicable law or regulations, to legal process, or to government requests; (b) enforce our Terms and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, and address fraud and other illegal activity, security, or technical issues; or (d) protect the rights, property, and safety of our users, Envite, its affiliated companies, or others.</p>

      <h3>Updates to Our Policy</h3>

      <p>We may amend or update our Privacy Policy. We will provide you notice of amendments to this Privacy Policy, as appropriate, and update the “Last Modified” date at the top of this Privacy Policy. Your continued use of our Services confirms your acceptance of our Privacy Policy, as amended. If you do not agree to our Privacy Policy, as amended, you must stop using our Services. Please review our Privacy Policy from time to time.</p>

      <h3>California Consumer Privacy Act</h3>

      <p>California residents may learn more about their rights, including how to exercise their rights under the California Consumer Privacy Act of 2018, by clicking here.</p>

      <h3>Contact Us</h3>
      <p>If you have questions about our Privacy Policy, please contact us:  </p>

      <p>MAJE SOCIAL COMMERCE TECH LTD.<br />
        11  Max Brod St.<br />
        Tel-Aviv 6962138<br />
        Israel<br />
        info@envite.live<br />
      </p>

      <h3>California Privacy Notice</h3>

      <p>California residents? Please learn more about their privacy rights in the following Privacy Notice for California Residence:</p>

      <p>This Privacy Notice for California Residence (“Notice”) is a supplement to our Privacy Policy. It explains how we collect, use, and share your Personal Information and how to exercise your rights under the California Consumer Privacy Act of 2018 (“CCPA”).</p>

      <h3>I. What does Envite collect? What kind of Personal Information?</h3>

      <p>For this Notice, “Personal Information” means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you. Personal Information does not include information that is aggregated, de-identified or information that cannot be reasonably linked to you. </p>

      <p>To provide our Services, we must process some information about you, including Personal Information.</p>

      <p>Information You Provide</p>
      <ul>
        <li>Your Account Information. You provide your email or mobile phone number to create a Envite account by connecting with your Facebook or Google accounts. You may also add other information to your account, such as a profile name, profile photos with faces in them if you choose to provide them, and status messages.</li>
        <li>Your Messages. Your messages are stored on Envite and part of its Services. If a message cannot be delivered immediately (for example, if you are offline), we may keep it on our servers for up to 30 days as we try to deliver it. If a message is still undelivered after 30 days, we delete it.</li>
        <li>Your Connections. You can create, join, or get added to groups and broadcast lists.</li>
        <li>Customer Support. You may provide us with information related to your use of our Services, including copies of your messages, and how to contact you so we can provide you customer support.</li >
      </ul >

      <p>Automatically-Collected Information</p>
      <ul>
        <li>Usage and Log Information. We collect service-related, diagnostic, and performance information. This includes information about your activity (such as how you use our Services, how you interact with others using our Services), log files, and reports.</li>
        <li>Device and Connection Information. We collect device-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, browser information, IP address, mobile network information, and device identifiers. We also collect device location information if you use our location features, such as when you choose to share your location with your contacts, view locations nearby or those others have shared with you, and for diagnostics and troubleshooting purposes.</li>
        <li>Status Information. We collect information about your online and status message changes on our Services, such as whether you are online (your “online status”), when you last used our Services, and when you last updated your status message.</li>
      </ul>

      <p>Other Sources of Information</p>
      <ul>
        <li>Information Others Provide About You. We may receive information about you from other users of our Services, such as if they send you a message or call you.</li>
        <li>Service Providers. We sometimes work with Service Providers, such as when they use research to improve, understand, customize, and market our Services and their offerings.</li>
      </ul>

      <p>To learn more about the specific pieces of Personal Information we collect and the sources of that information, return to our Privacy Policy and see the “Information We Collect” section.</p>

      <h3>II. How do we use Personal Information?</h3>

      <p>We use the Personal Information we have to provide and support our Services as described in our Privacy Policy. We use all the information we have to help us operate, provide, improve, understand, customize, support, and market our Services, including providing customer support, investigating suspicious activity or violations of our Terms, communicating with you about our Services and allowing third parties, like businesses, to communicate with you through our Services.</p>

      <p>You can learn more about our business and commercial purposes for collecting Personal Information by visiting the “How We Use Information” section of our Privacy Policy.</p>

      <h3>III. How is Personal Information shared?</h3>

      <p>We do not sell your Personal Information, and never will. You share your information as you use and communicate through our Services, and we share your information for business purposes to help us operate, provide, improve, understand, customize, support, and market our Services.</p>

      <ul>
        <li>Service Providers.Our Service Providers help us operate, provide, improve, understand, customize, support, and market our Services. When we share information with our Service Providers, we require them to use your information in accordance with our instructions and terms.</li>
        <li>Legal Process Requests. We may share your information with law enforcement or other third parties in connection with legal requests.</li>
      </ul>

      <p>You can learn more about the categories of Personal Information we share for business purposes and the categories of recipients with whom we share it in the “Information You And We Share” and “Affiliated Companies” sections of our Privacy Policy.</p>

      <h3>IV. How can you exercise your rights under the CCPA?</h3>

      <p>Under the CCPA, you have the right to access the Personal Information we’ve collected about you during the past 12 months and information about our data practices. You also have the right to request that we delete the Personal Information that we have collected from you</p>

      <p>For iPhone users, you can learn how to access, manage and delete your Personal Information.</p>

      <p>Please note, for all requests, you will need to verify your identity by providing us with the following information:</p>

      <ul>
        <li>Your full name</li>
        <li>Your email address</li>
        <li>Your Envite account details (i.e. email or phone number) </li>
        <li>The software version of your phone's operating system</li>
        <li>Whether you are a California consumer pursuant to Cal. Civ. Code Sec. 1798.140(g)</li>
      </ul>

      <p>If you would like to know the specific pieces of Personal Information that we have collected about you, or to delete your Personal Information, you may need to provide the additional information</p>

      <p>Lastly, you have the right to be free from any discrimination for exercising your rights to access or delete your Personal Information. We will not discriminate against you for exercising any of these rights.</p>

      <p>If you have additional questions about this Notice or how to exercise your rights under the CCPA, please contact us.</p>

      <h3>Intellectual Property Policy </h3>

      <p>Envite, which is developed and operated by Maje Social Commerce Tech Ltd. (“Envite,” “our,” “we,” or “us”) is committed to helping people and organizations protect their intellectual property rights. Our users agree to our Terms of Service (“Terms”) by installing, accessing, or using our apps, services, features, software, or website (together, “Services”). Our Terms do not allow our users to violate someone else’s intellectual property rights when using our Services, including their copyrights and trademarks.</p>

      <p>As explained in more detail in our Privacy Policy, we do not retain our users’ messages in the ordinary course of providing our Services. We do, however, host our users’ account information, including our users’ profile picture, profile name, or status message, if they decide to include them as part of their account information.</p>

      <h3>Copyright</h3>

      <p>To report copyright infringement and request that Envite remove any infringing content it is hosting (such as a Envite user’s profile picture, profile name, or status message), please email a completed copyright infringement claim to support@Envite.shop (including all of the information listed below). You can also mail a complete copyright infringement claim to Envite’s copyright agent:</p>

      <p>
        MAJE SOCIAL COMMERCE TECH LTD.<br />
        Private company No. 515616126<br />
        11  Max Brod St. Tel-Aviv 6962138 Israel<br />
        info@envite.live<br />
      </p>

      <p>Before you report a claim of copyright infringement, you may want to send a message to the relevant Envite user you believe may be infringing your copyright. You may be able to resolve the issue without contacting Envite.</p>

      <h3>Trademark</h3>

      <p>To report trademark infringement and request that Envite remove any infringing content it is hosting, please email a complete trademark infringement claim to info@envite.live (including all of the information listed below).</p>

      <p>Before you report a claim of trademark infringement, you may want to send a message to the relevant Envite user you believe may be infringing your trademark. You may be able to resolve the issue without contacting Envite.</p>

      <p>Please include all of the following information when reporting a copyright or trademark infringement claim to Envite:</p>

      <ul>
        <li>Your complete contact information (full name, mailing address, and phone number). Note that we regularly provide your contact information, including your name and email address (if provided), the name of your organization or client who owns the rights in question, and the content of your report to the person whose content you are reporting. You may wish to provide a professional or business email address where you can be reached.</li>
        <li>A description of the copyrighted work or trademark that you claim has been infringed.</li>
        <li>A description of the content hosted on our Services that you claim infringes your copyright or trademark.</li>
        <li>Information reasonably sufficient to permit us to locate the material on our Services. The easiest way to do this is by providing us the phone number of the individual who has submitted the infringing content on our Services.</li>
        <li>A declaration that:</li>
        <ul>
          <li>You have a good faith belief that use of the copyrighted or trademarked content described above, in the manner you have complained of, is not authorized by the copyright or trademark owner, its agent, or the law;</li>
          <li>The information in your claim is accurate; and</li>
          <li>You declare, under penalty of perjury, that you are the owner or authorized to act on behalf of the owner of an exclusive copyright or trademark that is allegedly infringed.</li>
        </ul>

        <li>Your electronic signature or physical signature.</li>
      </ul>

      <h3>Cookies</h3>
      <h4>About cookies</h4>
      <p>A cookie is a small text file that a website you visit asks your browser to store on your computer or mobile device.</p>

      <h4>How we use cookies</h4>
      <p>We use cookies to understand, secure, operate, and provide our Services. For example, we use cookies:</p>

      <ul>
        <li>to provide Envite for web and desktop and other Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services;</li>
        <li>to understand which of our FAQs are most popular and to show you relevant content related to our Services;</li>
        <li>to remember your choices, such as your language preferences, and otherwise to customize our Services for you; and</li>
        <li>to rank the FAQs on our website based on popularity, understand mobile versus desktop users of our web-based Services, or understand popularity and effectiveness of certain of our web pages.</li>
      </ul>

      <h4>How to control cookies</h4>

      <p>You can follow the instructions provided by your browser or device (usually located under “Settings” or “Preferences”) to modify your cookie settings. Please note that if you set your browser or device to disable cookies, certain of our Services may not function properly.</p>
    </div>
  );
}
