import jwt_decode from "jwt-decode";
import axios from 'axios';
import widgetCommuncationService from "./widgetCommunicationService";
import EnviteStorage from './storage'
import firebase from "configs/fbConfig";



const url = process.env.REACT_APP_API_URL;




const getNewToken = async () => {
  // const idToken = await FBServiceTs.fbAuth.currentUser.getIdToken()
  const idToken = await firebase.auth().currentUser.getIdToken()
  EnviteStorage.set('idToken', idToken)

  const ownerId = EnviteStorage.get('ownerId')
  return axios
    .post(`${url}/auth/widget/token`, {}, {
      headers: {
        'x-id-token': idToken,
        'x-owner-id': ownerId
      }
    })
}

function Api(baseRoute = '/') {
  const instance = axios.create({
    baseURL: `${url}${baseRoute}`
  });

  instance.interceptors.request.use(async (config) => {
    const token = EnviteStorage.get('idToken');
    let accessToken = EnviteStorage.get('accessToken');

    if (accessToken) {
      const data = jwt_decode(accessToken)

      if (data.exp < (new Date()).getTime()) {
        try {
          const { data: { accessToken: newAccessToken } } = await getNewToken()
          try {
            widgetCommuncationService.init('envite-iframe')
            widgetCommuncationService.sendMessage({ type: 'access-token', data: newAccessToken })
          } catch (error) {
          }

          EnviteStorage.set('accessToken', newAccessToken)
          accessToken = newAccessToken
        } catch (error) {
          /*if (error.response && error.response.status === 403) {
            EnviteStorage.remove('accessToken')
            EnviteStorage.remove('idToken')
          }*/
        }
      }
    }

    const ownerId = EnviteStorage.get('ownerId');

    if (accessToken) config.headers.common['Authorization'] = `Bearer ${accessToken}`
    if (ownerId) config.headers.common['x-owner-id'] = ownerId
    if (token) config.headers.common['x-id-token'] = token

    instance.defaults.headers.common['Accept'] = 'application/json';
    return config
  }, (error) => Promise.reject(error))

  return instance
}

export default Api;
