import * as React from "react";

function HalfstarSVG() {

    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
        <mask id="yy1pyjfzva" width="14" height="14" x="0" y="0" maskUnits="userSpaceOnUse">
            <path fill="#fff" d="M0 0H14V14H0z" />
        </mask>
        <g mask="url(#yy1pyjfzva)">
            <path fill="#FBC758" d="M5 4.5L7 1v10l-4 2 1-4-3-3.5 4-1z" />
            <path fillRule="evenodd" stroke="#FBC758" strokeLinecap="round" strokeLinejoin="round" d="M7 1l2.008 3.95 4.492.637-3.25 3.072.767 4.341L7 10.95 2.983 13l.767-4.34L.5 5.586l4.492-.637L7 1z" clipRule="evenodd" />
        </g>
    </svg>
}

export default HalfstarSVG;
