import React from 'react'
import AboutInfoForm from 'components/profile/AboutInfoForm'

const FillAboutInfo = ({
  owner,
  wizardState,
  handleChangeState,
  handleGoNext,
  handleGoBack,
  goTo,
  user,
  nextLoading,
}) => {
  const { aboutInfo } = wizardState

  const handleAboutInfoChange = (val) => {
    handleChangeState({ aboutInfo: val })
  }

  return (
    <>
      <AboutInfoForm
        currentUser={user}
        value={aboutInfo}
        goTo={goTo}
        nextLoading={nextLoading}
        fields={owner.registrationFields}
        onInput={(info) => handleAboutInfoChange(Object.assign(Object.create(Object.getPrototypeOf(info)), info))}
        handleGoNext={handleGoNext}
        handleGoBack={handleGoBack}
      />
    </>
  )
}

export default FillAboutInfo
