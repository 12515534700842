import React from 'react'

export default function EmailIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" fill="none" viewBox="0 0 18 15" className={props.class}>
      <path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M2.6 1h12.8c.88 0 1.6.72 1.6 1.6v9.6c0 .88-.72 1.6-1.6 1.6H2.6c-.88 0-1.6-.72-1.6-1.6V2.6C1 1.72 1.72 1 2.6 1z" clipRule="evenodd" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 2.602l-8 5.6-8-5.6" />
    </svg>
  )
}
