import React from "react";

export default function EnviteLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="37" viewBox="0 0 129 37" fill="none" className="envite-logo-svg">
      <path d="M33.152 16.4395C35.46 16.4395 37.297 17.1932 38.663 18.7005C40.0526 20.1842 40.7474 22.2332 40.7474 24.8474V36.3642H34.7417V25.66C34.7417 24.3411 34.4002 23.3166 33.7172 22.5865C33.0342 21.8564 32.1157 21.4913 30.9617 21.4913C29.8076 21.4913 28.8891 21.8564 28.2061 22.5865C27.5231 23.3166 27.1816 24.3411 27.1816 25.66V36.3642H21.1406V16.6515H27.1816V19.2657C27.794 18.3943 28.6183 17.7113 29.6545 17.2167C30.6908 16.6986 31.8566 16.4395 33.152 16.4395Z" fill="#190E2F" />
      <path d="M53.4592 30.7118L57.7338 16.6515H64.1634L57.1686 36.3642H49.7145L42.7196 16.6515H49.1846L53.4592 30.7118Z" fill="#190E2F" />
      <path d="M69.391 14.6025C68.3312 14.6025 67.4598 14.2963 66.7768 13.684C66.1174 13.0481 65.7876 12.2709 65.7876 11.3524C65.7876 10.4103 66.1174 9.63308 66.7768 9.02074C67.4598 8.38484 68.3312 8.06689 69.391 8.06689C70.4273 8.06689 71.2752 8.38484 71.9346 9.02074C72.6176 9.63308 72.9591 10.4103 72.9591 11.3524C72.9591 12.2709 72.6176 13.0481 71.9346 13.684C71.2752 14.2963 70.4273 14.6025 69.391 14.6025ZM72.3939 16.6515V36.3642H66.3529V16.6515H72.3939Z" fill="#190E2F" />
      <path d="M87.4053 31.2417V36.3642H84.3318C82.1415 36.3642 80.434 35.8343 79.2093 34.7745C77.9846 33.6911 77.3723 31.9365 77.3723 29.5107V21.668H74.97V16.6515H77.3723V11.8469H83.4133V16.6515H87.3699V21.668H83.4133V29.5813C83.4133 30.1701 83.5546 30.594 83.8372 30.8531C84.1198 31.1122 84.5909 31.2417 85.2503 31.2417H87.4053Z" fill="#190E2F" />
      <path d="M108.999 26.1899C108.999 26.7551 108.964 27.3439 108.893 27.9563H95.2215C95.3157 29.1809 95.7043 30.123 96.3873 30.7825C97.0938 31.4183 97.9535 31.7363 98.9662 31.7363C100.473 31.7363 101.522 31.1004 102.11 29.8286H108.54C108.21 31.124 107.61 32.2898 106.738 33.326C105.89 34.3623 104.819 35.1748 103.523 35.7636C102.228 36.3524 100.78 36.6468 99.1781 36.6468C97.2469 36.6468 95.5276 36.2347 94.0203 35.4103C92.513 34.586 91.3354 33.4085 90.4876 31.8776C89.6397 30.3467 89.2158 28.5568 89.2158 26.5078C89.2158 24.4588 89.628 22.6689 90.4523 21.1381C91.3001 19.6072 92.4777 18.4296 93.985 17.6053C95.4923 16.781 97.2234 16.3688 99.1781 16.3688C101.086 16.3688 102.782 16.7692 104.265 17.57C105.749 18.3707 106.903 19.513 107.727 20.9967C108.575 22.4805 108.999 24.2115 108.999 26.1899ZM102.817 24.6001C102.817 23.5639 102.464 22.7396 101.757 22.1272C101.05 21.5149 100.167 21.2087 99.1075 21.2087C98.0948 21.2087 97.2351 21.5031 96.5286 22.0919C95.8456 22.6807 95.4217 23.5168 95.2568 24.6001H102.817Z" fill="#190E2F" />
      <rect y="11.6355" width="17.2221" height="5.74071" fill="#F5B118" />
      <rect y="30.6235" width="17.2221" height="5.74071" fill="#FF707A" />
      <rect y="20.9087" width="13.2478" height="5.74071" fill="#00AEDC" />
    </svg>
  );
}
