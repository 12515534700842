import React from 'react'

import ErrorSVG from 'assets/svg-icons/ErrorSVG'

import './widgetPaymentErrorStyles.css'

const WidgetPaymentError = ({ message }) => {
  return (
    <div className='widget-payment-error-container'>
      <div className='widget-payment-error-background' />
      <div className='widget-payment-warn-icon'>
        <ErrorSVG />
      </div>
      <p className='widget-payment-error-text'>
        {message || 'Payment wasn\'t succeed properly'}
      </p>
    </div>
  )
}

export default WidgetPaymentError
