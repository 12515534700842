const storage = {};

class MemoryStorage {
    get length() {
        return Object.keys(this.storage).length
    }

    get storage() {
        return storage
    }

    getItem(key) {
        return (key in this.storage) ? this.storage[key] : null
    }

    setItem(key, value) {
        this.storage[key] = value
    }

    removeItem(key) {
        const found = (key in this.storage);
        if (found) {
            delete this.storage[key]
        }
    }

    clear() {
        for (const k in this.storage) {
            if (this.storage.hasOwnProperty(k)) {
                delete this.storage[k]
            }
        }
    }

}

export default new MemoryStorage()
