import React from "react";
import arrow_icon from "../../../assets/icons/right-arrow.png";
import PrivacyPolicyPopup from '../../profile/PrivacyPolicyPopup'
import '../widget.css'

const WidgetPrivacyPolicy = ({ toggleTerms }) => {


  return (
    <div>
      <div className="widget-main__container ">
        <div className="widget-Back-from-Terms-wrapper" >
          <img
            className="widget-Terms-left-arrow-image"
            src={arrow_icon}
            alt=""
            onClick={toggleTerms}
          />
          <div className="widget-back-button" onClick={toggleTerms} > Back </div>
        </div>
        <PrivacyPolicyPopup />
      </div>
    </div>
  )
}
export default WidgetPrivacyPolicy;
