import React from 'react';

const InstagramIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="url(#paint0_linear)" d="M14.297 12c0 1.269-1.028 2.297-2.297 2.297-1.269 0-2.297-1.028-2.297-2.297 0-1.269 1.028-2.297 2.297-2.297 1.269 0 2.297 1.028 2.297 2.297z"/>
        <path fill="url(#paint1_linear)" fillRule="evenodd" d="M17.372 7.936c-.11-.299-.287-.57-.516-.792-.222-.229-.493-.405-.792-.516-.243-.094-.608-.206-1.28-.237-.726-.033-.944-.04-2.784-.04-1.84 0-2.058.007-2.784.04-.672.03-1.037.143-1.28.237-.3.11-.57.287-.792.516-.229.222-.405.493-.516.792-.094.243-.206.608-.237 1.28-.033.726-.04.944-.04 2.784 0 1.84.007 2.058.04 2.785.03.671.143 1.036.237 1.279.11.3.287.57.516.792.222.23.493.405.792.516.243.094.608.206 1.28.237.726.033.944.04 2.784.04 1.84 0 2.058-.007 2.784-.04.672-.03 1.037-.143 1.28-.237.6-.232 1.076-.707 1.308-1.308.094-.243.206-.607.237-1.28.033-.726.04-.944.04-2.784 0-1.84-.007-2.058-.04-2.784-.03-.672-.143-1.037-.237-1.28zM12 15.538c-1.954 0-3.539-1.584-3.539-3.538 0-1.954 1.585-3.538 3.539-3.538 1.954 0 3.538 1.584 3.538 3.538 0 1.954-1.584 3.538-3.538 3.538zm2.851-7.216c0 .456.37.827.827.827.457 0 .827-.37.827-.827 0-.457-.37-.827-.827-.827-.456 0-.827.37-.827.827z" clipRule="evenodd"/>
        <path fill="url(#paint2_linear)" fillRule="evenodd" d="M0 12C0 5.374 5.374 0 12 0s12 5.374 12 12-5.374 12-12 12S0 18.626 0 12zm18.529 4.514c.17-.439.287-.94.32-1.673.034-.735.042-.97.042-2.841 0-1.871-.008-2.106-.042-2.84-.033-.734-.15-1.235-.32-1.674-.173-.46-.444-.876-.795-1.22-.344-.35-.76-.622-1.22-.795-.439-.17-.94-.287-1.673-.32-.735-.034-.97-.042-2.841-.042-1.871 0-2.106.008-2.84.042-.734.033-1.235.15-1.674.32-.46.173-.876.444-1.22.795-.35.344-.622.76-.795 1.22-.17.439-.287.94-.32 1.673-.034.735-.042.97-.042 2.841 0 1.871.008 2.106.042 2.841.033.733.15 1.234.32 1.673.173.46.445.876.795 1.22.344.35.76.622 1.22.795.439.17.94.287 1.673.32.735.034.97.042 2.841.042 1.871 0 2.106-.008 2.841-.042.733-.033 1.234-.15 1.673-.32.925-.358 1.657-1.09 2.015-2.015z" clipRule="evenodd"/>
        <defs>
            <linearGradient id="paint0_linear" x1="10.124" x2="13.876" y1="13.876" y2="10.124" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFC107"/>
                <stop offset=".507" stopColor="#F44336"/>
                <stop offset=".99" stopColor="#9C27B0"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="7.385" x2="16.615" y1="16.615" y2="7.385" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFC107"/>
                <stop offset=".507" stopColor="#F44336"/>
                <stop offset=".99" stopColor="#9C27B0"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="2.197" x2="21.803" y1="21.803" y2="2.197" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFC107"/>
                <stop offset=".507" stopColor="#F44336"/>
                <stop offset=".99" stopColor="#9C27B0"/>
            </linearGradient>
        </defs>
    </svg>
);

export default InstagramIcon;
