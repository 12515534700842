export const loadBlueSnap = () => {

    if (![null, 'sandbox', 'production', 'develop'].includes(process.env.REACT_APP_BLUESNAP_ENVIRONMENT)) {
        console.error("Wrong Environment")
        return
    }

    const existingDoc = document.getElementById("bluesnap-payment");
    if (existingDoc) return;
    const script = document.createElement("script");

    const isProd = process.env.REACT_APP_BLUESNAP_ENVIRONMENT === "production"

    script.src =
        isProd
            ? "https://ws.bluesnap.com/web-sdk/4/bluesnap.js"
            : "https://sandbox.bluesnap.com/web-sdk/4/bluesnap.js";

    script.id = "bluesnap-payment";

    document.body.appendChild(script);
}