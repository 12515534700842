import ErrorIconSVG from 'assets/svg-icons/ErrorIconSVG'
import React from 'react'


const ReviewTooltipText = ({mobile = false}) =>{
    return (
        <div className={`${mobile ?"reviews-mobile-tooltip" : "reviews-tooltip"}`}>
            <ErrorIconSVG /> 
            Login to write a review
        </div>
   
    )
}

export default ReviewTooltipText