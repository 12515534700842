import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../utils/axios'
import renderWidget from '../../helpers/renderWidget'
import { Helmet } from "react-helmet";
import '../../styles/profile/ProfilePlaceholder.css'
import { mixpanelApp, mixpanelAdminApp } from '../../services/mixpanel'
import EnviteLoader from "../global/EnviteLoader/EnviteLoader";

export default function ProfilePlaceholder() {
  const { serviceName, meetingId } = useParams();
  const [ownerName, setOwnerName] = useState('');
  const [ownerId, setOwnerId] = useState('');

  useEffect(() => {
    const getTeacherData = (serviceName) => {
      axios.get(`/owners/service/${serviceName}`)
        .then(res => {
          setOwnerName(res.data.displayName);
          setOwnerId(res.data.id);
          renderWidget(res.data.websiteId, res.data.tenantId, meetingId);
          mixpanelApp.track('page_visit', {
            page: 'meeting',
            vendor_id: res.data.id,
            vendor_name: res.data.name,
            vendor_link: serviceName,
          })
        })
        .catch(err => {
          console.log('failed fetching')
        })
    }

    getTeacherData(serviceName);
  }, [serviceName]);

  useEffect(
    () => {
      const eventHandler = (event) => {
        if (event.data.info && event.data.currentUser && event?.data?.message === 'user-joined-meeting') {
          mixpanelAdminApp.identify(ownerId)
          mixpanelAdminApp.track(
            'Client_joined_meeting',
            {
              meetingID: event.data.info.meetingID,
              clientName: event.data.currentUser.name,
              clientId: event.data.currentUser.id
            }
          )
          mixpanelApp.track(
            'Client_joined_meeting',
            {
              meetingID: event.data.info.meetingID,
              clientName: event.data.currentUser.name,
              clientId: event.data.currentUser.id
            }
          )
        }
      }

      window.addEventListener("message", eventHandler);
      return () => window.removeEventListener("message", eventHandler)
    }, [ownerId])

  return (
    <>
      <Helmet>
        <meta property="type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Envite.live" />
        <meta property="og:description" content={`${ownerName} invites you to join the public lobby on Envite!  This is the space to meet in a real time video chat from anywhere, anytime`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Envite.live" />
      </Helmet>
      <div className='profile-placeholder'>
        <EnviteLoader />
      </div>
    </>
  )
}
