import React, {useMemo, useState, useCallback} from 'react'

import ProfilerWizardContext from './ProfilerWizardContext'

export const directions = {
  forward: 'forward',
  back: 'back',
}

const ProfilerWizard = ({ children, defaultActiveIndex, onIndexChange, resetSteps, setResetSteps }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex || 0)
  const [direction, setDirection] = useState(directions.forward)
  const [wizardState, setWizardState] = useState({})

  React.useEffect(() => {
    onIndexChange(activeIndex)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const goNext = useCallback(() => {
    setDirection(directions.forward)
    setActiveIndex(activeIndex => {
      if(activeIndex >= children.length - 1) return children.length - 1
      return activeIndex + 1
    })
  }, [setDirection, setActiveIndex, children.length])

  const goBack = useCallback(() => {
    setActiveIndex(activeIndex => activeIndex >= 1 ? activeIndex - 1 : 0)
    setDirection(directions.back)
  }, [setDirection, setActiveIndex])

  const goTo = (i) => {
    setActiveIndex(activeIndex => {
      if (i > activeIndex) {
        setDirection(directions.forward)
      } else if (i < activeIndex) {
        setDirection(directions.back)
      }
      return i
    })
  }

  const goToStart = () => {
    setActiveIndex(0)
    setDirection(directions.back)
  }

  const goToEnd = () => {
    setActiveIndex(children.length - 1)
    setDirection(directions.forward)
  }

  const handleChangeState = (data) => {
    setWizardState(state => ({ ...state, ...data }))
  }

  const handleResetState = () => {
    setWizardState({})
  }

  const currentStep = useMemo(() => {
    const wizardStep = children[activeIndex]
    return wizardStep
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, children]);

  React.useEffect(() => {
    if(resetSteps){
      goToStart();
      handleResetState();
      setResetSteps(false);
    }
  }, [resetSteps, setResetSteps])

  return (
    <ProfilerWizardContext.Provider
      value={{
        handleChangeState,
        wizardState,
        goNext,
        goBack,
        direction,
        goTo,
        goToStart,
        goToEnd,
        handleResetState,
        activeIndex
      }}
    >
      {currentStep}
    </ProfilerWizardContext.Provider>
  )
}

export default ProfilerWizard
