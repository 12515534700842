import * as Sentry from '@sentry/react'
import { app as fbApp } from '../configs/fbConfig'

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE || 'unreleased',
    environment: process.env.REACT_APP_ENV || 'local',
  })

  fbApp.auth().onAuthStateChanged(function (user) {
    Sentry.setUser(user ? { id: user.uid, email: user.email } : null)
  })
}

export default Sentry
