import Api from './api';

const api = new Api('/reviews')

export const createReviews = async ({
    review,
    star,
    image,
    name,
    ownerId,
    userId,
}) => {
    const { data } = await api.post('/', { review, star, image, name, ownerId, userId })
    return data
}

export const fetchReviews = async (id) => {
    const data = await api.get(`/${id}`)
    return data
}

export const checkSubmitted = async (ownerId) => {
    const data = await api.get(`/submitted/check`, {params: {ownerId}})
    return data
}
