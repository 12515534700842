import React, { useContext, useState } from "react";

import "../../styles/profile/ProfileFlowStack.css";

import { AuthContext } from "context/AuthProvider";
import { authService, bookingService, paymentService } from "services";

import ProfilerWizard from "./ProfilerWizard";
import ProfilerWizardSummary from "./ProfilerWizard/ProfilerWizardController/ProfilerWizardSummary";
import ProfilerWizardStep from "./ProfilerWizard/ProfilerWizardStep";
import ChooseDate from "./ProfilerWizard/Steps/ChooseDate";
import FillAboutInfo from "./ProfilerWizard/Steps/FillAboutInfo";
import SignUp from "./ProfilerWizard/Steps/SignUp";
import Summary from "./ProfilerWizard/Steps/Summary";
import WidgetPayment from "components/widget/flow/WidgetPayment";
import EnviteStorage from '../../services/storage'
import { app as firebase } from "configs/fbConfig";
import mixpanelApp from "services/mixpanel";


export default function ProfileFlowStack({
	setActiveIndex,
	selectedService,
	owner,
	unavailableDays,
	getServiceIntervals,
	getBookingTimes,
	availableWeekDays,
	exceptionDates,
	loader,
	fullyBooked,
	setSelectedDate,
	setSelectedTime,
	selectedTime,
	resetSteps,
	setResetSteps,
	setVisible,
	getAvailableDatesForService,
}) {
	const { user, profile, setProfile } = useContext(AuthContext)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [bookingError, setBookingError] = useState();
	const [intervals, setIntervals] = useState([]);
	const [createdBookingId, setCreatedBookingId] = useState('')
	const bookingRef = React.useRef()

	React.useEffect(() => {
		if (!user || !EnviteStorage.get('accessToken')) return
		paymentService.getPaymentMethods()
			.then(data => setPaymentMethods(data))
			.catch(err => console.error(err))
	}, [user, resetSteps])

	const handleCreateBooking = async ({ wizardState }) => {
		try {
			const { selectedDate, selectedTime, selectedTimezone } = wizardState;
			const [hours, minutes] = selectedTime.time.split(':');

			const bookingDate = new Date(selectedDate)
			bookingDate.setHours(hours)
			bookingDate.setMinutes(minutes)
			bookingDate.valueOf()

			const scheduledTimestamp = bookingDate.valueOf();
			const currentTimestamp = new Date().valueOf();

			const data = await bookingService.createBooking({
				serviceId: selectedService.id,
				serviceName: selectedService.name,
				scheduledAt: scheduledTimestamp,
				duration: selectedService.duration,
				ownerId: owner.id,
				timezone: -(selectedTimezone.offset / 60),
				created_at: currentTimestamp,
				updated_at: currentTimestamp,
				status: 'true',
			})
			setCreatedBookingId(data.id)
			let scheduledItem = {
				userId: user.uid, userName: user.displayName, userEmail: user.email,
				userType: 'client', serviceType: selectedService.type, servicePrice: selectedService.price
			}

			mixpanelApp.track('scheduled_meetings', scheduledItem)

			firebase.analytics().logEvent('scheduled_meetings', {
				items: [scheduledItem]
			})

			return data
		} catch (err) {
			setBookingError(err.response)
			console.error(err)
			throw err
		}
	}

	const updateCustomFieldsValue = async () => {
		if (profile && !profile.hasBooking && owner.registrationFields.length) {
			const { data: newProfile } = await authService.updateProfile(
				user.email,
				user.name || user.displayName,
				profile.fields,
				true,
				true
			)
			setProfile(newProfile)
		}
	}

	const createBookingWithDebounce = async ({ wizardState, goToEnd }) => {
		if (bookingRef.current) return true
		bookingRef.current = true
		try {

			const isSuccess = await handleCreateBooking({ wizardState })
			if (isSuccess) {
				await updateCustomFieldsValue()
				goToEnd()
			}
			setTimeout(() => bookingRef.current = false, 500)
		} catch (error) {
			setTimeout(() => bookingRef.current = false, 500)
			throw error
		}
	}

	const createBookingIfSkipped = async ({ wizardState, goToEnd, activeIndex }) => {
		setBookingError(false);
		if (
			user?.email &&
			skipAboutInfo() &&
			parseInt(selectedService.price) === 0

		) {
			return await createBookingWithDebounce({ wizardState, goToEnd })
		}

	}

	const skipAboutInfo = () => {
		if (!owner.registrationFields.length) return true
		if (!!profile && !!profile.regFieldsProceeded) return true
		return false
	}


	const skipAboutInfoStep = () => {
		if (!owner.registrationFields.length) return true
		if (profile && profile.hasBooking) return true;
		return false;
	}

	return (
		<ProfilerWizard
			resetSteps={resetSteps}
			setResetSteps={setResetSteps}
			onIndexChange={(activeIndex) => {
				setBookingError(false);
				setActiveIndex(activeIndex);
			}}
		>
			<ProfilerWizardStep
				firstStep
				setResetSteps={setResetSteps}
				hideButton={true}
				setVisible={setVisible}
				beforeNext={createBookingIfSkipped}
				allowNext={({ wizardState }) => wizardState.selectedDate && wizardState.selectedTime && wizardState.selectedTimezone}
				render={({
					handleGoNext,
					handleChangeState,
					wizardState
				}) => (
					<ChooseDate
						handleChangeState={handleChangeState}
						handleGoNext={handleGoNext}
						wizardState={wizardState}
						owner={owner}
						selectedService={selectedService}
						getServiceIntervals={getServiceIntervals}
						getBookingTimes={getBookingTimes}
						unavailableDays={unavailableDays}
						availableWeekDays={availableWeekDays}
						exceptionDates={exceptionDates}
						fullyBooked={fullyBooked}
						loader={loader}
						setSelectedDate={setSelectedDate}
						setSelectedTime={setSelectedTime}
						selectedTime={selectedTime}
						intervals={intervals}
						setIntervals={setIntervals}
						error={bookingError}
					/>
				)}
			/>
			<ProfilerWizardStep
				skip={() => user?.email}
				nextText='Sign Up'
				beforeNext={createBookingIfSkipped}
				error={bookingError}
				renderButton={() => <></>}
				render={({
					wizardState,
					handleGoNext,
					handleGoBack,
					nextLoading
				}) => (
					<SignUp
						handleGoNext={handleGoNext}
						handleGoBack={handleGoBack}
						nextLoading={nextLoading}
						owner={owner}
						wizardState={wizardState}
					/>
				)
				}
			/>

			<ProfilerWizardStep
				skip={skipAboutInfoStep}
				form='about-form'
				renderButton={() => <></>}
				beforeNext={createBookingIfSkipped}
				error={bookingError}
				render={({
					handleChangeState,
					wizardState,
					handleGoNext,
					goTo,
					handleGoBack,
					nextLoading
				}) => (
					<FillAboutInfo
						handleChangeState={handleChangeState}
						user={user}
						nextLoading={nextLoading}
						owner={owner}
						goTo={goTo}
						wizardState={wizardState}
						handleGoNext={handleGoNext}
						handleGoBack={handleGoBack}
					/>
				)}
			/>
			<ProfilerWizardStep
				renderButton={() => <></>}
				nextText='Confirm'
				beforeNext={createBookingIfSkipped}
				error={bookingError}
				render={({
					wizardState,
					handleGoBack,
					handleChangeState,
					goNext,
					handleGoNext
				}) => {
					return <WidgetPayment
						selectedService={selectedService}
						wizardState={wizardState}
						handleGoNext={handleGoNext}
						skip={({ wizardState }) => parseInt(selectedService.price) === 0}
						owner={owner}
						user={user}
						profile={profile}
						paymentMethods={paymentMethods}
						handleGoBack={handleGoBack}
						handleChangeState={handleChangeState}
						updateCustomFieldsValue={updateCustomFieldsValue}
						setCreatedBookingId={setCreatedBookingId}
					/>
				}}
			/>
			<ProfilerWizardStep
				renderButton={() => <></>}
				render={({
					wizardState,
				}) => (
					<>
						<Summary
							owner={owner}
							wizardState={wizardState}
							createdBookingId={createdBookingId}
						/>
						<ProfilerWizardSummary
							onClick={() => {
								getAvailableDatesForService(owner.id);
								setResetSteps(true);
								setSelectedTime("")
							}}
							wizardState={wizardState}
						/>
					</>
				)}
			/>
		</ProfilerWizard>
	);
}
