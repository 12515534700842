import objectAssign from 'object-assign'
import MemoryStorage from './MemoryStorage'

class Storage {

    constructor(config = {}) {
        this.setOptions(config)
    }

    get name() {
        return '__NAME__[v__VERSION__]'
    }

    get version() {
        return '__VERSION__'
    }

    get length() {
        return this.keys().length
    }

    get driver() {
        switch (this.options.driver) {
            case 'local':
            default:
                return (typeof window !== 'undefined') ? window.localStorage : MemoryStorage;
            case 'session':
                return (typeof window !== 'undefined') ? window.sessionStorage : MemoryStorage;
            case 'memory':
                return MemoryStorage
        }
    }

    setOptions(config = {}) {
        const options = objectAssign({
            prefix: 'app_',
            driver: 'local',
            ttl   : 60 * 60 * 24 * 1000 // 24 hours
        }, config);
        this.options = Object.freeze(options)
    }

    printError(e) {
        console.error(`${this.name}: ${e.stack}`)
    }

    addPrefix(key) {
        return `${this.options.prefix || ''}${key}`
    }

    removePrefix(key) {
        const re = new RegExp(`^${this.options.prefix || ''}`);
        return key.replace(re, '')
    }

    toJSON(data, options = {}) {
        const ttl = ('ttl' in options) ? options.ttl : this.options.ttl;
        return JSON.stringify({
            value: data,
            ttl  : Date.now() + ttl
        })
    }

    fromJSON(key) {
        try {
            const data = JSON.parse(this.driver.getItem(key));
            if (data !== null) {
                if (('ttl' in data) &&
                    Number(data.ttl) < Date.now()) {
                    this.remove(this.removePrefix(key));
                    return null
                }
                if ('value' in data) {
                    return data.value
                }
                return data
            }
            return null
        } catch (e) {
            return null
        }
    }

    get(key, fallback = null) {
        try {
            const val = this.fromJSON(this.addPrefix(key));
            if (val === null) {
                return fallback
            }
            return val
        } catch (e) {
            this.printError(e)
        }
    }

    set(key, val, options = {}) {
        try {
            this.driver.setItem(this.addPrefix(key), this.toJSON(val, options))
        } catch (e) {
            this.printError(e)
        }
    }

    remove(key) {
        try {
            this.driver.removeItem(this.addPrefix(key))
        } catch (e) {
            this.printError(e)
        }
    }

    clear(force = false) {
        try {
            if (force) {
                this.driver.clear()
            } else {
                const keys = this.keys().filter(key => key.startsWith(this.options.prefix || ''));
                keys.forEach(key => this.remove(this.removePrefix(key)))
            }
        } catch (e) {
            this.printError(e)
        }
    }

    has(key) {
        if (this.options.driver !== 'memory') {
            return (this.addPrefix(key) in this.driver)
        }
        return (this.addPrefix(key) in this.driver.storage)
    }

    key(index) {
        try {
            const key = this.keys()[index];
            return this.get(this.removePrefix(key))
        } catch (e) {
            this.printError(e)
        }
    }

    keys() {
        if (this.options.driver !== 'memory') {
            return Object.keys(this.driver)
        }
        return Object.keys(this.driver.storage)
    }
}

export default Storage
