import React, { useState, useContext, useEffect, useCallback } from "react";
import ReactPaginate from 'react-paginate';
import "../../styles/profile/Reviews.css";
import Review from "./Review";
import WriteReview from "./WriteReview";
import { AuthContext } from "context/AuthProvider";
import LeftArrow from "../../assets/svg-icons/LeftArrow";
import * as reviewService from "../../services/reviewService";

const Reviews = ({ reviews, createReviews, setReview, review, starsList, setRating, rating , owner}) => {
	const reviewsEl = [];
	const [currentPage, setCurrentPage] = useState(0);
	const [disableReview, setDisableReview] = useState(true)
	const { profile } = useContext(AuthContext)
	const perPage = 2;

	useEffect(() => {
		disableCreateReview(owner.id)
	}, [owner])

	const disableCreateReview =  useCallback(async ownerId => {
		const data = await reviewService.checkSubmitted(ownerId)
		setDisableReview(data.data.submitted)
	}, [setDisableReview])
	
	for (let i = currentPage * 2; i < currentPage * 2 + perPage && i < reviews.length; i++) {
		reviewsEl.push(
			<li key={reviews[i].id}>
				<Review review={reviews[i]} />
			</li>
		);
	}

	const pageChange = ({ selected }) => {
		setCurrentPage(selected);
	}

	return (
		<div className={"profile-reviews"}>
			<ul>{reviewsEl}</ul>
			{reviews.length > perPage ? (
				<div className="pagination-container">
					<ReactPaginate
						previousLabel={(<span><LeftArrow fillColor={"#3c627c"}/></span>)}
						nextLabel={(<span style={{ transform: 'rotate(180deg)' }}><LeftArrow fillColor={"#3c627c"}/></span>)}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={reviews.length / perPage}
						marginPagesDisplayed={1}
						pageRangeDisplayed={2}
						onPageChange={pageChange}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			)
				:
				null
			}
			{!disableReview && <WriteReview
				profile={profile}
				createReviews={createReviews}
				setReview={setReview}
				review={review}
				starsList={starsList}
				setRating={setRating}
				rating={rating}
				disableReview={!profile}
				setDisableReview={setDisableReview}
			/>}
		</div>

	);
}

export default Reviews;
