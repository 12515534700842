export const ipLookUp = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/')
        const country = await response.json()
        return country
    } catch (e) {
        console.error('ipLookUp:', e)
        return false
    }
}

const userLocation = async () => {
    let userLoc = await ipLookUp() // get user country
    return userLoc ? ({
        value: userLoc.country,
        label: userLoc.country_name
    }) : userLoc
}


export default userLocation