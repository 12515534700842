import React from 'react'

export default function OfflineSVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <g fill="#6A6E75" clipPath="url(#clip0)">
                <path d="M14.915 10.85H11.3C12.738 9 13.654 7.1 13.62 5.831c0-2.903-2.349-4.638-4.62-4.638S4.38 2.928 4.38 5.83c-.036 1.267.883 3.17 2.32 5.019H3.085L1 16.807h16l-2.085-5.957zm-.667.94l.619 1.77-5.122-.866c.211-.228.489-.536.798-.903h3.705zM5.32 5.832C5.32 3.517 7.19 2.133 9 2.133c2.304 0 3.68 1.88 3.68 3.698.1 1.657-2.276 4.843-3.68 6.282-1.406-1.44-3.78-4.625-3.68-6.282zm3.003 6.936l-2.77.884-1.251-1.86h3.155c.344.408.648.743.866.976zm-4.77-.405l2.358 3.505H2.326l1.227-3.505zm3.491 3.505L6.1 14.463l2.934-.935 6.187 1.045.453 1.294h-8.63z"/>
                <path d="M9 7.793c1.092 0 1.98-.888 1.98-1.98-.109-2.626-3.851-2.625-3.96 0 0 1.092.889 1.98 1.98 1.98zm0-3.018c.573 0 1.04.466 1.04 1.039-.058 1.378-2.022 1.377-2.079 0 0-.573.466-1.04 1.04-1.04z"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H16V16H0z" transform="translate(1 1)"/>
                </clipPath>
            </defs>
        </svg>
    )
}