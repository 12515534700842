import React from 'react'

export default function SuccessIcon(props) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="40" cy="40" r="40" fill="#B0EAE0"/>
      <circle opacity="0.4" cx="40.0002" cy="40.0002" r="28.8" fill="#5CD6BE"/>
      <circle cx="39.9999" cy="39.9999" r="17.6" fill="#5CD6BE"/>
      <path d="M47.1907 34.3169L37.0757 44.4318L32.478 39.8341" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
