import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import '../../styles/profile/AboutInfoDatePicker.css';

export default function AboutInfoDatePicker({ error, fieldValues, fieldname, handleDateChange }) {

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className='about-info__datePicker--container'
          maxDateMessage='Please enter past date'
          disableFuture
          autoOk
          error={error}
          placeholder="day/month/year"
          variant="inline"
          inputVariant="outlined"
          label="Date of Birth"
          format="dd/MM/yyyy"
          value={fieldValues[fieldname] || null}
          InputAdornmentProps={{ position: "start" }}
          onChange={date => handleDateChange(date)}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
