import React from "react";
import LinkedinIcon from "../../assets/svg-icons/LinkedinIcon";
import FacebookIcon from "../../assets/svg-icons/FacebookIcon";
import TwitterIcon from "../../assets/svg-icons/TwitterIcon";
import InstagramIcon from "../../assets/svg-icons/InstagramIcon";

export default function SocialsBar({ facebook, instagram, linkdin, twitter }) {

    const socials = [
        {
            href: linkdin?.replace(/^https?:\/\//, ''),
            component: <LinkedinIcon />,
        },
        {
            href: facebook?.replace(/^https?:\/\//, ''),
            component: <FacebookIcon />,
        },
        {
            href: twitter?.replace(/^https?:\/\//, ''),
            component: <TwitterIcon />
        },
        {
            href: instagram?.replace(/^https?:\/\//, ''),
            component: <InstagramIcon />
        }
    ];

    for (let i = 0; i < socials.length; i++) {
        socials[i] = (
            socials[i].href &&
            <li key={i}>
                <a href={`//${socials[i].href}`} target="_blank" rel="noreferrer">
                    {socials[i].component}
                </a>
            </li>
        )
    }

    return (<ul className="social-links">
        {socials}
    </ul>);
}
