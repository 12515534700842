import React from 'react'

export default function LeftArrow({ fillColor = "#9FB1BE", className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" className={className} onClick={() => onClick && onClick()}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke={fillColor} strokeWidth="1.571">
          <path d="M1518 586L1523 591 1518 596" transform="translate(-1517 -585) matrix(-1 0 0 1 3041 0)" />
        </g>
      </g>
    </svg>
  )
}


