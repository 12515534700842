import React, { useEffect, useState, useMemo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "../../assets/svg-icons/CloseIcon";
import { sendEmailToMerchant } from "../../services/mailerService";
import SentIcon from "../../assets/svg-icons/SentIcon";

export default function MessagePopup({ visible, setVisible, owner }) {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [messageIsSent, setMessageIsSent] = useState(false);

    useEffect(() => {
        if (!visible) {
            return;
        }
    }, [visible]);

    useEffect(() => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(email).toLowerCase()) && message.length) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [email, message]);

    const textToShow = useMemo(() => {
        return message.split('\n')
            .flatMap((item) => {
                if (item === "") return ["<br/>"]
                else return ["<br/>", item]
            }).slice(1)
    }, [message])


    const onSendClick = async (e) => {
        e.preventDefault();
        if (isValid) {
            setLoader(true);
            if (email.length && message.length) {
                let params = {
                    merchantEmail: owner.email,
                    merchantName: owner.firstname,
                    userEmail: email,
                    message: textToShow.join(""),
                };
                try {
                    const { data } = await sendEmailToMerchant(params);
                    if (data.success) {
                        setLoader(false);
                        setEmail('');
                        setMessage('');
                        setMessageIsSent(true);
                    }
                } catch (err) {
                    // setMessageStatus("fail");
                    setLoader(false);
                }

            } else {
                setLoader(false);
            }
        }
    };

    return (
        <div
            className="message-popup-wrapper"
            style={{ display: visible ? "flex" : "none" }}
        >
            <div className="message-popup">
                <div className="message-header">
                    <span className="title">Send message</span>
                    <span
                        className="close-icon"
                        onClick={() => {
                            setVisible(false);
                            setMessageIsSent(false);
                        }}
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div className="message-content">
                    {messageIsSent ? (
                        <div className={"message-is-sent"}>
                            <div className={"icon-wrapper"}>
                                <SentIcon />
                            </div>
                            <div className={"sent-title"}>Message sent!</div>
                            <div className={"sent-text"}>Your message was successfully sent to {owner.firstname} {owner.lastname}</div>
                        </div>
                    ) : (
                        <form id="form-message" onSubmit={onSendClick}>
                            <div id="email" className="message-email-input">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={"Enter your email address"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                />

                            </div>


                            <textarea
                                placeholder="Write a message..."
                                rows={10}
                                onChange={(e) => setMessage(e.target.value)}
                                className={"message-text"}
                                value={message}
                            />

                            <div className="send-message-button--wrapper">
                                <button
                                    type="submit"
                                    className={
                                        !isValid || loader ? "message-button" : "message-button disabled"
                                    }
                                    disabled={!isValid || loader}
                                >
                                    {loader ? (
                                        <CircularProgress
                                            size={25}
                                            className="send-message__button--loader"
                                        />
                                    ) : (
                                        <div className="button-text">Send</div>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}
