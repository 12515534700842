import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "context/AuthProvider";
import SingUpInvite from "./SingUpInvite";
import { useParams } from "react-router";
import axios from "../../utils/axios";
import EnviteStorage from "services/storage";
import { loadBlueSnap } from '../../utils/bluesnapLoad'
import { app as firebase } from "../../configs/fbConfig";
import { CircularProgress } from "@material-ui/core";
import "../../styles/ConfirmMeeting/index.css";
import SuccessModal from "./SuccessModal";
import { fetchOneUser } from '../../services/userService'
import { getBookingById } from '../../services/bookingService'
import LoginPopup from '../profile/LoginPopup'
import InvitationInfo from './InvitationInfo'
import PasswordResetPopup from "../profile/PasswordResetPopup";
import { USER_BILLING, SIGNUP_BILLING, SIGNUP_NO_BILLING } from '../../constants/invitationProgressTexts'

export default function ConfirmMeeting({ history }) {
  const { user } = useContext(AuthContext)
  const { serviceName } = useParams();
  const [loading, setLoading] = useState(true)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [owner, setOwner] = useState({})
  const [userExists, setUserExists] = useState(false)
  const [notLoggedIn, setNotLoggedIn] = useState(true)
  const [booking, setBooking] = useState({})
  const [passwordResetPopupVisible, setPasswordResetPopupVisible] = useState(false)
  const [progTexts, setProgTexts] = useState({
    array: [],
    step: 0,
    textsArr: [],
    moveToNextStep: () => {
      setProgTexts(prev => ({
        ...prev,
        step: prev.step + 1,
        textsArr: prev.array[prev.step + 1]
      }))
    }
  })

  const email = useMemo(() => new URLSearchParams(window.location.search).get('email'), [])
  const userName = useMemo(() => new URLSearchParams(window.location.search).get('name'), [])
  const bookingId = useMemo(() => new URLSearchParams(window.location.search).get('bookingId'), [])

  const fetchBooking = async (userExists) => {
    const booking = await getBookingById(bookingId, owner.id)
    setBooking(booking)
    if (booking?.service?.price) {
      if (userExists) setProgTexts(prev => ({
        ...prev,
        array: USER_BILLING,
        textsArr: USER_BILLING[0]
      }))
      else setProgTexts(prev => ({
        ...prev,
        array: SIGNUP_BILLING,
        textsArr: SIGNUP_BILLING[0]
      }))
    } else {
      if (!userExists) setProgTexts(prev => ({
        ...prev,
        array: SIGNUP_NO_BILLING,
        textsArr: SIGNUP_NO_BILLING[0]
      }))
    }
  }

  const checkUser = async () => {
    const { data } = await fetchOneUser(email, owner.id)
    setUserExists(!!data)
    setLoading(false)
    fetchBooking(!!data)
  }

  useEffect(() => {
    email && owner.id && checkUser()
  }, [email, owner.id])

  useEffect(() => {
    loadBlueSnap();
  }, []);

  useEffect(() => {
    if (!serviceName) return
    axios.get(`/owners/service/${serviceName}`).then(res => {
      const ownerTenenatId = res.data.tenantId;
      EnviteStorage.set("tenantId", ownerTenenatId);
      EnviteStorage.set("ownerId", res.data.id);
      setOwner(res.data)
      firebase.auth().tenantId = ownerTenenatId
    })
  }, [serviceName])

  useEffect(() => {
    if (user?.email === email) setNotLoggedIn(false)
  }, [user])


  const loginComponent = useMemo(() => {
    if (passwordResetPopupVisible) return <PasswordResetPopup
      visible={passwordResetPopupVisible}
      setVisible={setPasswordResetPopupVisible}
    />
    if (!loading && userExists && notLoggedIn) return <LoginPopup setVisible={setNotLoggedIn} invitation={{
      email
    }} setPasswordResetVisible={setPasswordResetPopupVisible} />
  }, [loading, userExists, notLoggedIn, passwordResetPopupVisible])

  const signUpComponent = useMemo(() => (
    !loading && email && !userExists && notLoggedIn &&
    <SingUpInvite
      setNotLoggedIn={setNotLoggedIn}
      email={email}
      userName={userName}
      ownerId={owner.id}
      progTexts={progTexts}
      setProgTexts={setProgTexts}
    />
  ), [loading, userExists, notLoggedIn, userName, owner, progTexts])

  const invitationInfoComponent = useMemo(() => (
    !notLoggedIn && !showSuccessModal &&
    <InvitationInfo
      user={user}
      owner={owner}
      booking={booking}
      setShowSuccessModal={setShowSuccessModal}
      setBooking={setBooking}
      progTexts={progTexts}
      setProgTexts={setProgTexts}
      history={history}
    />
  ), [notLoggedIn, showSuccessModal, booking, user, owner, progTexts])


  return (
    <div className="profile-placeholder">

      {loading && <CircularProgress className="main-loading" />}

      {signUpComponent}
      {loginComponent}

      {invitationInfoComponent}

      {showSuccessModal && <SuccessModal email={email} pageUrl={owner.pageUrl} />}
    </div>
  );
}
