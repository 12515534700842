import React from "react";
import "../../styles/profile/WriteReview.css";
import NewRating from "./NewRating";
import ReviewTooltipText from "./ReviewTooltipText";
import { isMobile } from 'react-device-detect';


export default function WriteReview({ profile, createReviews, setReview, review, starsList, setRating, rating, disableReview, setDisableReview}) {

	const submit = (e) => {
		createReviews(review, rating, profile.picture, profile.name)
		setDisableReview(true)
		e.preventDefault();
	};

	return (
		<form className="write-review" onSubmit={submit}>
			<div className="top-part">
				<h2>Write a review</h2>
				<NewRating
					enabled={!disableReview} setRating={setRating} reviewstarsList={starsList} rating={rating} />
			</div>
			<div className="write-review-textfield">
		{isMobile	?  	<>
							<textarea
								name="review"
								id={"envite-event-client-write-review"}
								className={"profile__review-field"}
								rows="5"
								placeholder="Share your thoughts about this service"
								value={disableReview? "" : review }
								onInput={(e) => disableReview ? null : setReview(e.target.value)}
							/>
							{ disableReview && <ReviewTooltipText mobile={true} /> }
						</>
					:	<>
								<textarea
									name="review"
									id={"envite-event-client-write-review"}
									className={"profile__review-field"}
									rows="5"
									placeholder="Share your thoughts about this service"
									disabled={disableReview}
									value={review}
									onInput={(e) => setReview(e.target.value)}
								/>
								{ disableReview && <ReviewTooltipText /> }
						</>
			}
			</div>
			<div className={"post-button-wrapper"}>
				<button className={`btn post-button ${rating > 0 ? 'post-button--active' : ''}`} disabled={rating === 0 || disableReview} id={"envite-event-client-post-review"}>Post</button>
			</div>
		</form>
	);
}
