import React, { useState } from "react";
import "../../styles/profile/Gallery.css";
import GalleryVideo from "./GalleryVideo";
import Slideshow from "./Slideshow";
import { fileTypesArray } from "../../utils/FileTypes"

export default function Gallery({ contents }) {
    const [slideshowIsVisible, setSlideshowVisibility] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(null);
    const fileTypesArr = fileTypesArray()

    const generateElement = (elData, i) => {
        switch (elData.type) {
            case fileTypesArr.imgTypes.includes(elData.type.split('/')[1]) && elData.type:
                return (<img src={elData.url} alt="" style={{ cursor: "pointer" }} onClick={() => {
                    setSlideshowVisibility(true);
                    setActiveImageIndex(i);
                }} />);
            case fileTypesArr.videoTypes.includes(elData.type.split('/')[1]) && elData.type:
                return (<GalleryVideo src={elData.url} attributes={{ controls: false }} onClick={() => {
                    setSlideshowVisibility(true);
                    setActiveImageIndex(i);
                }} />);
            default:
                break;
        }
    }

    return (
        <div className={"profile-gallery"}>
            <ul className="gallery-contents">
                {contents.map((content, i) => {
                    if (Object.keys(content).length === 0 && content.constructor === Object) return null
                    return (
                        <li key={content.url}>
                            {generateElement(content, i)}
                        </li>
                    )
                })}
            </ul>
            {slideshowIsVisible && (
                <Slideshow
                    data={contents}
                    activeImageIndex={activeImageIndex}
                    closeSlideshow={() => setSlideshowVisibility(false)}
                />
            )}
        </div>
    );
}
