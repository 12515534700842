import React from 'react';
import Button from '@material-ui/core/Button';
import googleButtonImage from "../../assets/images/btn_google_signin_light_normal_web@2x.png"

const ProviderSignin = ({ handleSignInWithGoogle, handleSignInWithFacebook, type }) => {
    return (
        <div className={"sc-container"}>
            <Button
                className={"sc-button google-login"}
                id={"envite-event-client-signin-google"}
                style={{
                    position: "relative",
                }}
                onClick={handleSignInWithGoogle}
            >
                <img src={googleButtonImage} alt="Google sign in button" className="google-sign-in-image" />
            </Button>
            <Button
                className={"sc-button fb-login"}
                style={{
                    position: "relative",
                }}
                onClick={handleSignInWithFacebook}
                id={"envite-event-client-signin-fb"}
            >
                {`${type === 'signIn' ? "Continue with Facebook" : "Continue with Facebook"}`}
            </Button>
        </div>
    )

}

export default ProviderSignin;