import mixpanel from 'mixpanel-browser'
import { app as fbApp } from '../configs/fbConfig'

export const mixpanelApp = mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  opt_out_tracking_by_default: !process.env.REACT_APP_MIXPANEL_ENABLED,
  ignore_dnt: true,
  api_host: 'https://api-eu.mixpanel.com',
}, 'envite-mixpanel')

export const mixpanelAdminApp = mixpanel.init(process.env.REACT_APP_MIXPANEL_ADMIN_TOKEN, {
  opt_out_tracking_by_default: !process.env.REACT_APP_MIXPANEL_ENABLED,
  ignore_dnt: true,
  api_host: 'https://api-eu.mixpanel.com',
}, 'envite-admin-mixpanel')

let firstVisitTracked = false

fbApp.auth().onAuthStateChanged(function (user) {

  if (user?.tenantId?.includes('admin')) {
    return
  }

  const mixpanelUser = mixpanelApp.get_property('$user_id')

  if (typeof mixpanelUser !== 'undefined' && mixpanelUser !== user?.uid) {
    mixpanelApp.reset()
  }

  if (user) {
    mixpanelApp.identify(user.uid)
    mixpanelApp.people.set({
      'uid': user.uid,
      '$email': user.email,
      '$name': user.displayName,
    })
  }
  firstVisitTracked = true
})

window.mixpanelApp = mixpanelApp
window.mixpanelAdminApp = mixpanelAdminApp

export default mixpanelApp
