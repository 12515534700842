import React from 'react'
import { CircularProgress } from '@material-ui/core';

import ProfilerWizardBackButton from 'components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardBackButton'
import ProfilerWizardNextButton from 'components/profile/ProfilerWizard/ProfilerWizardController/ProfilerWizardNextButton'

import { usePayment } from 'hooks'
import * as bookingService from "services/bookingService";
import WidgetChooseCards from './WidgetChooseCard'
import WidgetNewCard from './WidgetNewCard'


import '../../widget.css'
import { createAnalyticsEvent } from 'services/event';
import mixpanelApp from 'services/mixpanel';



const WidgetPayment = ({
  wizardState,
  handleGoNext,
  handleGoBack,
  owner,
  user,
  handleChangeState,
  paymentMethods,
  skip,
  selectedService,
  updateCustomFieldsValue,
  setCreatedBookingId
}) => {
  const [shouldSave, setShouldSave] = React.useState(false)
  const [active, setActive] = React.useState()
  const [isNewCard, setIsNewCard] = React.useState()
  const [paymentError, setPaymentError] = React.useState()
  const [paymentLoading, setPaymentLoading] = React.useState(false)
  const [fullName, setFullname] = React.useState('')

  const {
    submitPaymentInfo,
    shouldSubmit,
    pfToken,
    initPaymentController,
    errors,
    loading,
    setLoading,
    cardType
  } = usePayment()

  React.useEffect(() => {
    if (skip({ wizardState })) handleGoNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  React.useEffect(() => {
    errors && setPaymentError(false)
  }, [errors])


  React.useEffect(() => {
    setIsNewCard(!paymentMethods.length)
    if (paymentMethods.length) setLoading(false)
  }, [paymentMethods, setLoading])

  const onGoBack = () => {
    if (isNewCard && paymentMethods.length) {
      setIsNewCard(false)
      setLoading(false)
      return
    }
    handleGoBack()
  }

  const handleCreateBooking = async ({ wizardState, pfToken }) => {
    try {
      setPaymentError(false)
      const { selectedDate, selectedTime } = wizardState
      const [hours, minutes] = selectedTime.time.split(':');

      const bookingDate = new Date(selectedDate)
      bookingDate.setHours(hours)
      bookingDate.setMinutes(minutes)
      bookingDate.valueOf()

      const scheduledTimestamp = bookingDate.valueOf();
      const currentTimestamp = new Date().valueOf();

      const [firstName, lastName] = fullName.split(' ')
      const paymentMethod = isNewCard ?
        {
          pfToken,
          storeCard: shouldSave,
          cardHolderInfo: {
            firstName,
            lastName,
            email: user.email
          }
        }
        :
        {
          creditCard: wizardState.selectedCard
        }

      const data = await bookingService.createBooking({
        serviceId: selectedService.id,
        serviceName: selectedService.name,
        scheduledAt: scheduledTimestamp,
        duration: selectedService.duration,
        userId: user.id || user.uid,
        ownerId: owner.id,
        created_at: currentTimestamp,
        updated_at: currentTimestamp,
        status: 'true',
        paymentMethod,
      })
      setCreatedBookingId(data.id)
      let scheduledItem = {
        userId: user.id || user.uid, userName: user.name, userEmail: user.email,
        userType: 'client', serviceType: selectedService.type, servicePrice: selectedService.price,
        serviceId: selectedService.id
      }

      let invoiceItem = {
        userId: user.id || user.uid, userName: user.name, userEmail: user.email,
        servicePrice: selectedService.price, serviceId: selectedService.id, serviceType: selectedService.type,
      }

      mixpanelApp.track('scheduled_meetings', scheduledItem)
      mixpanelApp.track('created_invoices', invoiceItem)

      createAnalyticsEvent('scheduled_meetings', scheduledItem)
      createAnalyticsEvent('created_invoices', invoiceItem)

      return data
    } catch (err) {
      setPaymentError(err.response.data)
      throw err
    }
  }

  const handleSubmit = async () => {
    try {
      setPaymentLoading(true)
      if (isNewCard) {
        const data = await submitPaymentInfo()
        if (!(data?.statusCode === '1')) return
      }
      await handleCreateBooking({ wizardState, pfToken })
      setPaymentLoading(false)
      handleGoNext()
      await updateCustomFieldsValue()
    } catch (error) {
      setPaymentLoading(false)
    }
  }

  const handleAddCard = () => {
    setIsNewCard(true)
  }

  const shouldAllowSubmit = React.useMemo(() => {
    if (isNewCard) return shouldSubmit
    if (wizardState.selectedCard) return true
    return false
  }, [isNewCard, shouldSubmit, wizardState.selectedCard])


  return (
    <div className={
      `widget-payment-container ${loading && 'widget-payment-container-loading'}`
    }>
      {loading && (
        <div className="payment-loader">
          <CircularProgress />
        </div>
      )}
      {isNewCard && (
        <WidgetNewCard
          shouldSave={shouldSave}
          setShouldSave={setShouldSave}
          errors={errors}
          paymentError={paymentError}
          fullName={fullName}
          setFullname={setFullname}
          cardType={cardType}
          initPaymentController={initPaymentController}
        />
      )}
      {!isNewCard && (
        <WidgetChooseCards
          handleChangeState={handleChangeState}
          handleAddCard={handleAddCard}
          active={active}
          setActive={setActive}
          paymentMethods={paymentMethods}
        />
      )}
      <div className='cancel-next-message'>
        <ProfilerWizardBackButton
          onClick={onGoBack}
        />
        <ProfilerWizardNextButton
          onClick={handleSubmit}
          loading={paymentLoading}
          disabled={!shouldAllowSubmit}
          text='Confirm'
        />
      </div>
    </div>
  )
}
export default WidgetPayment
